import  { useContext, useState, useEffect } from 'react';
import { ImageWithCTA } from '../../components/chelsea/CTA/ImageWithCTA';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

export const ACFImageWithCTA = ({ anchor='', className='', data, meta }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <ImageWithCTA
             className={className}
             anchor={anchor}
                cta={data?.cta}
                desktop_cta_image={meta?.images?.[data?.cta_desktop_image]}
                mobile_cta_image={meta?.images?.[data?.cta_mobile_image]}
                desktop_image={meta?.images?.[data?.desktop_image]}
                mobile_image={meta?.images?.[data?.mobile_image]}
            />
        }
    </>);
};
