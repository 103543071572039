import { useRouter } from 'next/router';
import React, { useContext, useEffect } from 'react';
import Script from 'next/script';
import { LineCTA } from '../CTA/LineCTA';
import { LocationContext } from '../../../util/LocationProvider'

export default function ZohoEmbed(props) {
    const haveWindow = (typeof window !== 'undefined');
    let LocationManager = useContext(LocationContext);

    const router = useRouter();
    
    useEffect(() => {
        LocationManager.zohoReload(); // Repeat preserved URL params.
    });

    const extractScript = (embed) => {
        if (!document || !embed) {
            return;
        }
        let script = '';
        // Add Zoho form embed to a Div object to parse the script tags.
        let container = document.createElement('div');
        container.innerHTML = embed; 
        // Loop through the <script> tags and add the script to the 
        let scriptTags = container.getElementsByTagName('script');
        for (let tag of scriptTags) {
            script = script + "\n" + tag.innerHTML;
        }
        return script;
    };

    
    const returnToPreviousPage = (e) => {
        e.preventDefault();
        router.back();
    }

    return (<>
        <div 
            component="ZohoEmbed"
            className={`wrapper relative grid lg:grid-cols-2 bg-gray-dark text-white lg:py-24 py-16 pt-0 lg:gap-0 gap-12 ${props?.className}`}>
            <div className="-mx-2 py-2 lg:hidden flex items-center px-4 lg:px-0 relative lg:static lg:z-0 z-[3] lg:border-none border-b border-solid border-grid bg-gray-dark">
                <div className="w-full">
                    {process?.env?.NEXT_PUBLIC_SUBSITE=='fitness' &&
                        <img alt="Chelsea Piers Fitness Logo" src="/logos/CP-Fitness_Horiz_Orig.svg" />
                    }
                    {process?.env?.NEXT_PUBLIC_SUBSITE!='fitness' && 
                        <img alt="Chelsea Piers Logo" src={LocationManager.getLogo(process?.env?.NEXT_PUBLIC_SUBSITE, false)} />
                    }
                </div>
                <div className="py-2 pl-6 -my-2 border-l border-grid"></div>
            </div>
            <div className="items-center justify-center hidden mb-24 col-span-full lg:flex">
                {process?.env?.NEXT_PUBLIC_SUBSITE=='fitness' && 
                    <img alt="Chelsea Piers Fitness Logo" src="/logos/CP_Fitness_white.svg" />
                }
                {process?.env?.NEXT_PUBLIC_SUBSITE!='fitness' &&
                    <img alt="Chelsea Piers Logo" src={LocationManager.getLogo(process?.env?.NEXT_PUBLIC_SUBSITE, false)} />
                }
            </div>
            <div className="px-6 lg:px-[7.8vw]">
                {props?.data?.eyebrow && 
                    <>
                        <p dangerouslySetInnerHTML={{ __html: props?.data?.eyebrow }} className="lg:label--largeBold label--bold text-sky-100" />
                    </>
                }
                {props?.data?.title && 
                    <>
                        <h3 className="mt-3 lg:mt-6" dangerouslySetInnerHTML={{ __html: props?.data?.title }} />
                    </>
                }
                {props?.data?.blurb && 
                    <>
                        <div className="mt-4 lead--large lg:mt-8 " dangerouslySetInnerHTML={{ __html: props?.data?.blurb }} />
                    </>
                }
                {props?.data?.link && 
                    <>
                        <LineCTA
                            title={props?.data?.link?.title}
                            href={props?.data?.link?.url}
                            target={props?.data?.link?.target}
                            marginClass={'mt-6 lg:mt-[34.5px] '}
                            borderClass={'border--increase'}
                            textClassName={'text-white group-hover:text-sky-100'}
                        />
                    </>
                }
                {props?.data?.notes && 
                    <>
                        <div className="mt-4 text-white lead--small lg:mt-8 text-opacity-70" dangerouslySetInnerHTML={{ __html: props?.data?.notes }} />
                    </>
                }
            </div>
            <div className="px-3 lg:px-[3.33vw]">
                <div className="lg:p-16 p-8 bg-white text-black-100 relative z-[3]">
                    {props?.data?.embed && (haveWindow && window?.location?.search?.includes('zohoReload')) &&
                        <>
                            <div dangerouslySetInnerHTML={{ __html: props?.data?.embed }} />
                            <Script id="zoho-embed-script" dangerouslySetInnerHTML={{ __html: extractScript(props?.data?.embed) }} />
                        </>
                    }
                </div>
            </div>
        </div>
    </>);
};
