import React, { useState } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const MemberBenefit = ({ items, title, className, anchor }) => {
    const [hover, setHover] = useState();

    return (
        <div
            className={` overflow-hidden bg-cover bg-white ${className}`}
            id={anchor}
        >
            <div className="pt-[64px] lg:pt-[128px] pb-[65.5px] lg:pb-[129.5px]">
                <div className="items-end mb-6 lg:mb-8 md:flex">
                    <h2 className="flex-1 px-3 text-center wow fadeInUp text-black-100">
                        {title}
                    </h2>
                </div>
                <div className="block w-[1px] mx-auto mb-8 lg:mb-12  h-[64px] bg-cobalt-100"></div>
                <div className="membership-swiper relative pl-3 lg:px-[3.333%]">
                    <div className="w-full pl-6 lg:px-6">
                        <Swiper
                            spaceBetween={48}
                            slidesPerView={`auto`}
                            navigation={{
                                prevEl: '.insight-prev',
                                nextEl: '.insight-next'
                            }}
                            pagination={false}
                            breakpoints={{
                                992: {
                                    slidesPerView: 4,
                                    allowTouchMove: false
                                }
                            }}
                        >
                            {items &&
                                items?.map((item, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div
                                                className="relative flex flex-col     text-black-100 lg:w-full w-[216px] lg:h-full"
                                                // onMouseEnter={() => setHover(i)}
                                                // onMouseLeave={() => setHover()}
                                            >
                                                <div
                                                    className={`w-full h-full aspect-w-1 aspect-h-1 transition-all  duration-300`}
                                                    style={{
                                                        backgroundImage:
                                                            hover === i
                                                                ? `url(${item?.background_image_desktop?.src})`
                                                                : `url(${item?.background_image_desktop?.src}), linear-gradient(0deg, rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0.4) )`,
                                                        backgroundBlendMode:
                                                            'multiply',
                                                        backgroundSize: 'cover'
                                                    }}
                                                >
                                                    <div className="flex items-center justify-center h-full transition-all duration-300 ">
                                                        <img
                                                            className=""
                                                            alt={
                                                                item?.icon?.alt
                                                            }
                                                            src={
                                                                item?.icon?.src
                                                            }
                                                        />
                                                    </div>
                                                </div>
                                                <h4 className="text-black-100 z-[1] mt-6 group-hover:text-cobalt-100 transition-all duration-300">
                                                    {' '}
                                                    {item?.title}{' '}
                                                </h4>
                                                <div
                                                    className="pt-4 lead--regular lg:pt-5 "
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            item?.description
                                                    }}
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>

                    <div
                        className="cursor-pointer absolute left-0 h-[64px] top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden   items-center justify-center insight-prev"
                        style={{ borderRadius: '50%' }}
                    >
                        <i className="cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>

                    <div
                        className="cursor-pointer absolute right-0 h-[64px]  top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden   items-center justify-center insight-next"
                        style={{ borderRadius: '50%' }}
                    >
                        <i className=" transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberBenefit;
