import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreSpacer(props) {
    let data = props?.data;
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    const { height, className = '', anchor = '' } = props;
    let spaceHeight = String(height).replace(/\D/g, '');

    return (<>
        {show &&
            <div className="bg-white wrapper ">
                <div className="px-6 lg:px-28">
                    <div
                        id={anchor}
                        className={`${className} relative z-[3]`}
                        style={{ height: `${spaceHeight}px` }}
                    ></div>
                </div>
            </div>
        }
    </>);
}

export default CoreSpacer;
