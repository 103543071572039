import  { useContext, useState, useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { Transition } from '@headlessui/react';
import { LocationContext } from '../../util/LocationProvider';

function ContentAccordion({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let lists = extractRepeaterField(data, 'accordion');

    lists = lists.map((repeaterItem) => ({
        ...repeaterItem,
        content_section:
            extractRepeaterField(repeaterItem, 'content_section') || {}
    }));

    const [disclosureState, setDisclosureState] = useState(false);

    const toggle = (index) => {
        if (disclosureState === index) {
            return setDisclosureState(null);
        }
        setDisclosureState(index);
    };
    return (<>
        {show &&
            <section className="py-0 bg-white wrapper">
                <div className="px-6 lg:px-28">
                    <div className="relative z-[3] bg-white">
                        <div className="grid bg-white relative z-[3] ">
                            {lists &&
                                lists.map((item, index) => (
                                    <div
                                        className="border-black-100 last:border-b border-opacity-10 wow fadeInUp text-black-100"
                                        key={index}
                                    >
                                        <div
                                            className={`flex items-center lg:p-6 p-4 border-t cursor-pointer border-black-100 lead--large border-opacity-10  ${
                                                disclosureState ===
                                                'col1' + index
                                                    ? 'bg-black-100 text-white'
                                                    : 'bg-buttonGray text-black-100'
                                            }`}
                                            onClick={() => {
                                                toggle('col1' + index);
                                            }}
                                        >
                                            <h5
                                                className={`flex flex-1   ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? ' '
                                                        : ''
                                                }`}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.cta_text
                                                }}
                                            />
                                            <i
                                                className={`cp-icon-arraySmallLeft inline-block transform text-14 ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? 'rotate-90'
                                                        : '-rotate-90'
                                                }`}
                                            ></i>
                                        </div>
                                        <Transition
                                            show={
                                                disclosureState ===
                                                'col1' + index
                                            }
                                            className="py-6 lg:py-12 lg:px-7"
                                        >
                                            {item?.content_section &&
                                                item?.content_section?.map(
                                                    (subItem, subIndex) => (
                                                        <div
                                                            key={subIndex}
                                                            className="pb-8 mb-8 border-b border-black-100 border-opacity-10 last:border-none last:mb-0 last:pb-0"
                                                        >
                                                            {subItem?.header && (
                                                                <h5
                                                                    className=""
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            subItem?.header
                                                                    }}
                                                                />
                                                            )}
                                                            {subItem?.content && (
                                                                <div
                                                                    className="mb-4 lead--large text-black-100 gn-content lists"
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            subItem?.content
                                                                    }}
                                                                />
                                                            )}
                                                        </div>
                                                    )
                                                )}
                                        </Transition>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        }
    </>);
}

export default ContentAccordion;
