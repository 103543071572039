import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreColumns({ children, className, data=false }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    return (<>
        {show &&
            <section className="py-0 bg-white wrapper">
                <div className="px-6 lg:px-28">
                    <div className="relative z-[3] bg-white">
                        <div
                            className={`${className || ''} grid grid-rows-${children.length} lg:grid-cols-${children.length}`}
                        >
                            {children}
                        </div>
                    </div>
                </div>
            </section>
        }
    </>);
}

export default CoreColumns;
