import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';
import NextNavLink from '../../components/util/NextNavLink';

function CoreButton({
    pageProps,
    url='',
    text,
    anchor='',
    className='',
    linkTarget='_self',
    fontSize,
    data=false,
}) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    return (<>
        {text && show && (
            <NextNavLink target={linkTarget} href={url}>
                <a
                    className={`label--bold btn btn--cobalt ${
                        fontSize === 'large' ? 'text-[20px] py-5 px-10' : ''
                    }  ${className}`}
                    id={anchor}
                    dangerouslySetInnerHTML={{ __html: text }}
                ></a>
            </NextNavLink>
        )}
    </>);
}

export default CoreButton;
