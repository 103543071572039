import { useContext, useState, useEffect } from 'react';
import CardSlider from '../../components/chelsea/CardSlider/CardSlider'
import { LocationContext } from '../../util/LocationProvider';

const ACFCardSlider = ({ data, meta, pageProps,  className = '', anchor = '' }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);
    // console.dir(data);
    // console.dir(meta);
    return (<>
        {show &&
            <CardSlider
                className={className}
                anchor={anchor}
                data={data}
                meta={meta}
                icons={pageProps?.componentQueries?.cardSlider} 
                 />
        }
    </>);
};

export default ACFCardSlider;
