import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreButtons({
    children,
    anchor='',
    className='',
    contentJustification,
    data=false,
}) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    return (<>
        {show &&
            <div className={`  py-2  wrapper  `} id={anchor}>
                <div className={` ${className} relative z-[3]   bg-white `}>
                    <div
                        className={`button-wrap flex flex-col flex-wrap px-6 space-y-4 lg:space-y-0 lg:space-x-5 lg:px-28 lg:flex-row  ${
                            contentJustification === 'center'
                                ? 'justify-center'
                                : ''
                        }`}
                    >
                        {children}
                    </div>
                </div>
            </div>
        }
    </>);
}

export default CoreButtons;
