import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreHTML({ content, className='', data=false }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    return (<>
        {show &&
            <section className="bg-white  wrapper block-table text-black-100 py-8 wow fadeInUp" >
                <div className="px-6 lg:px-28 overflow-x-scroll ">
                    <div
                        className={className}
                        dangerouslySetInnerHTML={{
                        __html: content
                    }}/>
                </div>
            </section>
        }
    </>);
}

export default CoreHTML;
