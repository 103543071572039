import  { useContext, useState, useEffect } from 'react';
import { LocationHero } from '../../components/chelsea/PageHero/LocationHero'
import { PageHero } from '../../components/chelsea/PageHero/PageHero'
import { extractRepeaterField } from '../../util/blocks'
import { LocationContext } from '../../util/LocationProvider'

const ACFLocationHero = ({data,meta,anchor='', className=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let ctas = extractRepeaterField(data,'ctas');
    
    return (<>
        {show &&
            <LocationHero
                location={data?.location}
                phone={data?.phone_number}
                icon={meta?.icons?.[data?.icon?.[0]]}
                content={data?.description}
                className={className} anchor={anchor}
                title={data?.title}
                ctas={ctas}
                subTitle={data?.['sub-title']}
                desktop_image={meta?.images?.[data?.desktop_image]}
                mobile_image={meta?.images?.[data?.mobile_image]}
            />
        }
    </>);
}

export default ACFLocationHero
