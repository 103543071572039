import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';

const MemberQuote = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    return (
        <div
            className={`wrapper bg-white ${props?.className}`}
            id={props?.anchor}
        >
            <div className="lg:grid flex  lg:grid-cols-2 lg:pt-[128px] flex-col-reverse wow fadeInUp">
                <div className="px-6 mt-12 lg:mt-0 lg:px-[9.4444vw] flex items-center">
                    <div className="flex flex-col w-full ">
                        <div className="relative wow !visible overflow-hidden">
                            <div className="rev-img-wrap relative z-[1]">
                                <div className="rev-img">
                                    <img
                                        src={
                                            actualWidth > 768
                                                ? props?.desktop_image?.src
                                                : props?.mobile_image?.src
                                        }
                                        alt={
                                            actualWidth > 768
                                                ? props?.desktop_image?.alt
                                                : props?.mobile_image?.alt
                                        }
                                        className="w-full"
                                    />
                                </div>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full rev-bg bg-black-20"></div>{' '}
                        </div>
                        <h4 className="mt-6 text-black-100 lg:mt-8 wow fadeInUp">
                            {' '}
                            {props?.name}
                        </h4>
                        <p
                            datat-wow-delay="0.3s"
                            className="mt-1 lead--regular lg:mt-2 text-cobalt-100 wow fadeInUp"
                        >
                            {' '}
                            {props?.subTitle}{' '}
                        </p>
                    </div>
                </div>
                <div className="flex px-6 mb-[40px] lg:px-12 4xl:items-center wow fadeInUp">
                    <div className="flex flex-col mt-12 xl:mt-16 4xl:mt-0 4xl:justify-center">
                        <div className="w-[60px] lg:w-[128px]  h-[1px] bg-cobalt-100"></div>
                        <div
                            className="mt-8 mb-8 h4 text-black-100 lg:mt-12 lg:mb-14"
                            dangerouslySetInnerHTML={{ __html: props?.quote }}
                        />
                        <div className="w-[60px] lg:w-[128px] h-[1px] bg-cobalt-100"></div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MemberQuote;
