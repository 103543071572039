import  { useContext, useState, useEffect } from 'react';
import { HeroBanner } from '../../components/chelsea/HeroBanner';
import { LocationContext } from '../../util/LocationProvider';

function ACFHeroBanner({ anchor='', className='', data, pageProps, meta }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const sub_heading = data?.sub_heading;
    const top_heading = data?.top_heading;
    const left_cta = data?.left_cta;
    const right_cta = data?.right_cta;
    const heading = data?.heading;

    let video = {};
    let video_url = '';
    if (data?.video_url == '1') {
        video.video_desktop = data?.desktop_video_url;
        video.video_mobile  = data?.mobile_video_url ? data?.mobile_video_url : data?.desktop_video_url;
    } else {
        video = meta?.video;
        video_url = meta?.video?.video_desktop;
    }

    return (<>
        {show &&
            <HeroBanner
                className={className}
                anchor={anchor}
                image={Object.values(meta?.images)}
                video={video}
                video_url={meta?.desktop_video_url}
                subHeading={sub_heading}
                topHeading={top_heading}
                heading={heading}
                left_cta={left_cta}
                right_cta={right_cta} />
        }
    </>);
}

export default ACFHeroBanner;