import { useContext, useState, useEffect } from 'react';
import BackToTop from '../../components/chelsea/Misc/BackToTop'
import { LocationContext } from '../../util/LocationProvider';

const ACFBackToTop = ({data,meta,className,anchor}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <BackToTop 
                actualAnchor={data?.anchor} 
                arrow={data?.show_arrow === "1"} 
                text={data?.text} 
                className={className} 
                anchor={anchor} />
        }
    </>)
}

export default ACFBackToTop
