import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { LocationContext } from '../../../util/LocationProvider';
import moment from 'moment/moment';

const ClassesDOW = (props) => {
    let LocationManager = useContext(LocationContext);
    let locale = LocationManager.getLocation();

    const router = useRouter();

    let seasons = props?.seasons ? props.seasons : [];
    let sports  = props?.sports  ? props.sports  : [];

    const [noResultsMessage, setNoResultsMessage] = useState(
        (props?.data?.no_results_message ? props?.data?.no_results_message : 'No Results')
    );

    const [loading, setLoading] = useState(true);

    const [hiddenSport, setHiddenSport] = useState(true);
    const [hiddenAge, setHiddenAge] = useState(true);
    const [hiddenDay, setHiddenDay] = useState(true);
    const [hiddenSem, setHiddenSem] = useState(true);

    const [openDescription, setOpenDescription] = useState('');
    const toggleOpenDescription = (key) => {
        setOpenDescription((openDescription==key ? '' : key));
    }

    // Class Data
    const [classes, setClasses] = useState([]);
    const [filteredClasses, setFilteredClasses] = useState([]);

    // Filter States
    const [filterLocation, setFilterLocation] = useState(locale);
    const [filterSport, setFilterSport] = useState([]);
    const [filterAge, setFilterAge] = useState([]);
    const [filterDay, setFilterDay] = useState([]);
    const [filterSemester, setFilterSemester] = useState('');

    // Filter Values
    const [optionsLocation, setOptionsLocation] = useState({
        'stamford': 'Stamford, CT',
        'chelsea': 'Chelsea, NY',
        'brooklyn': 'Brooklyn, NY',
    });
    const [optionsSport, setOptionsSport] = useState([]);
    const [optionsAge, setOptionsAge] = useState([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,'18+']);
    const [optionsDay, setOptionsDay] = useState([
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
        'Sunday',
    ]);
    const [optionsSemester, setOptionsSemester] = useState([]);


    useEffect(() => {
        try {
            const d = new Date();
            let ts = d.getTime();
            let ms = d.getMilliseconds();

            let endpoint = 'chelsea-piers-data-api.chelseapiers.com';
            if (process?.env?.NEXT_PUBLIC_STAGE == '1') {
                endpoint = 'chelsea-piers-stage-api.50k.de';
            }

            fetch(`https://${endpoint}/wp-content/plugins/chelsea-api-connector/schedules/${filterLocation}.json?cache=${ts}.${ms}`)
            .then(response => response.json())
            .then(classes => {
                let programs = [];
                let dayIds = {
                    monday: [],
                    tuesday: [],
                    wednesday: [],
                    thursday: [],
                    friday: [],
                    saturday: [],
                    sunday: [],
                }

                classes.forEach((item) => { 
                    if (item?.class?.semester == 'N/A') return; // Require Semester/Season
                    if (item?.class?.activity?.name == 'N/A') return; // Require Sport
                    if (item?.class?.type != 'Program') return // Programs only.

                    optionsDay.forEach((day) => {
                        // Is class held on today, and is it a duplicate.
                        if (item?.class?.dow[day.toLowerCase()] && !dayIds[day.toLowerCase()].includes(item?.class?.id)) {
                            programs.push(item);
                            dayIds[day.toLowerCase()].push(item?.class?.id);
                        }   
                    });
                });

                setClasses(programs);
                setDefaults(programs);
                setFilters(programs);
                setLoading(false);
            })
            .catch(e => console.log(e));


        } catch (e) {}
    }, []);

    // Filter Updates ..
    useEffect(() => {
        let filteredClasses = [];
        const type = props?.data?.schedule_type;

        // Loop through classes..
        for (let i=0; i<classes.length; i++) {
            // Filter by type.
            if (type != classes[i]?.class?.type) continue;

            // Filter by season.
            if (filterSemester != '') {
                if (filterSemester != classes[i]?.class?.semester) {
                    continue;
                }
            }
            
            // Filter by DOW.
            if (filterDay.length) {
                let matchingDay = false;
                filterDay.forEach((day) => {
                    if (classes[i]?.class?.dow[day.toLowerCase()] == true) {
                        matchingDay = true;
                    }
                });
                if (!matchingDay) continue;
            }

            // Filter by Age
            let matchingAge = true;
            if (filterAge.length) { 
                let youthClasses = true;
                matchingAge = false;
                let min = classes[i]?.class?.age?.min;
                let max = classes[i]?.class?.age?.max;

                if (! filterAge.includes('18+')) {
                    // Youth Table filter. 
                    if (min==0 && max==0) { matchingAge = false; }
                    if (min>18) { matchingAge = false; }
                } else {
                    youthClasses = false;
                }

                for (let j=0; j<filterAge.length; j++) {
                    let age = filterAge[j];
                    if (youthClasses) {
                        if (age>=min && age<=max) {
                            matchingAge = true;
                        }
                        // Youth Table filter: 
                        if (min==0 && max==0) continue;
                        if (min>18) continue;

                    } else {
                        if (age=='18+' && (min>=18 && max>=18 || max==0)) { // 18+ filter, inclusive max=0.
                            matching = true;
                        }
                        if (age>=min && (age<=max || max==0)) { // Age filter, inclusive of max=0.
                            matchingAge = true;
                        }
                    }
                }
            }
            if (!matchingAge) continue;

            // Filter Sport
            if (filterSport.length && !filterSport.includes(classes[i]?.class?.activity?.name)) {
                continue;
            }

            filteredClasses.push(classes[i]);
        }

        setFilteredClasses(filteredClasses);
    }, [
        classes, 
        filterSport, 
        filterAge, 
        filterDay, 
        filterSemester,
        props
    ]);

    const formatCurrency = (cost) => {
        let price = cost ? parseFloat(cost) : 0.00;
        let final = (Math.round(price * 100) / 100).toFixed(2);
        if (final == 0.00) {
            return '-';
        } else return final;
    };

    const csvArray = (str) => {
        if (str.includes(',')) {
            return str.split(',');
        } else {
            return [str];
        }
    }

    useEffect(() => { 
        // Sport is set, and the value exists as a filter option.
        if (router?.query?.sport) {
            let sports = csvArray(router?.query?.sport);
            let sportsFilters = [...filterSport];
            sports.forEach((sport) => { 
                // Is this a valid option? Not already set?
                if (optionsSport.includes(sport) && !filterSport.includes(sport)) { 
                    sportsFilters.push(sport);
                }
            });
            setFilterSport(sportsFilters);
        }

        // Age is set, and the value exists as a filter option.
        if (router?.query?.age) {
            let ages = csvArray(router?.query?.age);
            let agesFilters = [...filterAge];
            ages.forEach((age) => { 
                if (age != '18+') {
                    age = parseInt(age);
                }
                // Is this a valid option? Not already set?
                if (optionsAge.includes(age) && !filterAge.includes(age)) { 
                    agesFilters.push(age); 
                }
            });
            setFilterAge(agesFilters);
        }

        // Day is set, and the value exists as a filter option.
        if (router?.query?.day) {
            let days = csvArray(router?.query?.day);
            let daysFilters = [...filterDay];
            days.forEach((day) => { 
                // Is this a valid option? Not already set?
                if (optionsDay.includes(day) && !filterDay.includes(day.toLowerCase())) { 
                    daysFilters.push(day.toLowerCase()); 
                }
            });
            setFilterDay(daysFilters);
        }

        // Season is set, and the value exists as a filter option.
        if (router?.query?.season && optionsSemester.includes(router?.query?.season)) {
            setFilterSemester(router.query.season);
        }

        // Semester. (class.originalSemester).
    }, [
        router.query,
        optionsSport, // Watch the sports options since they're dynamically added.
        optionsSemester, // Watch the semester options since they're dynamically added.
    ]);

// Filter by the dataset and if active in the CMS.
    const setFilters = (programs) => {
        let activeSeasons = [];
        let dataSeasons = []; 
        seasons.forEach((season) => {
            let fields = season?.node?.acf_season_fields; 
            if (fields?.active == '1') { // List only active.
                activeSeasons.push(fields?.label);

                if (fields?.current) { // Set current to default filter.
                    setFilterSemester(fields?.label);
                }
            }
        });


        programs.forEach((program) => { 
            let season = program?.class?.season;
            if (!dataSeasons.includes(season) && season){
                if (activeSeasons.includes(season)) {
                    dataSeasons.push(season);
                }
            }
        });

        setOptionsSemester(activeSeasons);
    };

    const setDefaults = (classes) => { 
        let sportsFilters = [];
        let sportsOptions = [];

        if (props?.data?.hide_selected_sports_in_filtering == 1) { // Sports Options are preset.
            props?.data?.limit_sports?.forEach((sportId) => {
                sports?.forEach((sport) => {
                    if (parseInt(sport?.node?.databaseId) == sportId) {
                        sportsOptions.push(sport?.node?.title);
                    }
                });
            });
        } else { // Limit to what's present in the dataset.
            classes.forEach((item) => {
                let sport = item?.class?.activity?.name;
                if (!sportsOptions.includes(sport)) {
                    sportsOptions.push(sport);
                }
            });
        }
        setOptionsSport(sportsOptions.sort());

        if (props?.data?.set_default_sport == '1') {
            props?.data?.default_sports?.forEach((sportId) => {
                sports?.forEach((sport) => {
                    if (parseInt(sport?.node?.databaseId) == sportId) {
                        sportsFilters.push(sport?.node?.title);
                    }
                })
            });
            setFilterSport(sportsFilters.sort());
        }

        if (props?.data?.set_default_age == '1') {
            let ages = [];
            props?.data?.default_age?.forEach((age) => {
                ages.push(parseInt(age));
            });
            setFilterAge(ages);
        }
    };

    const toggleDow = (day, e) => {
        let lday = day.toLowerCase();
        let dowFilter = [...filterDay];
        if (!dowFilter.includes(lday)) {
            dowFilter.push(lday);
            e.currentTarget.classList.add('active');
        } else {
            dowFilter = dowFilter.filter(d => d !== lday);
            e.currentTarget.classList.remove('active');
        }
        setFilterDay(dowFilter);
    };


    const toggleSport = (sport, e) => {
        let sportFilter = [...filterSport];
        if (!sportFilter.includes(sport)) {
            sportFilter.push(sport);
            e.currentTarget.classList.add('active');
        } else {
            sportFilter = sportFilter.filter(d => d !== sport);
            e.currentTarget.classList.remove('active');
        }
        setFilterSport(sportFilter);
    };


    const toggleAge = (age, e) => {
        let ages = [...filterAge];
        if (!ages.includes(age)) {
            ages.push(age);
            e.currentTarget.classList.add('active');
        } else {
            ages = ages.filter(a => a !== age);
            e.currentTarget.classList.remove('active');
        }
        setFilterAge(ages);
    };


    const getClassesByDay = (day) => {
        let dayClasses  = [];
        let dayClassIds = [];
        filteredClasses.forEach((item) => {
            // Is class held on today, and is it a duplicate.
            if (item?.class?.dow[day.toLowerCase()] && !dayClassIds.includes(item?.class?.id)) {
                dayClasses.push(item);
                dayClassIds.push(item?.class?.id);

            }
        });
        return dayClasses;
    };


    const isFilterActive = (filter, value) => {
        let filterCheck = false;

        if (Array.isArray(filter)) {
            filterCheck = (filter.includes(value) ? 'active' : '');
        } else {
            filterCheck = (filter==value ? 'active': '');
        }
        return filterCheck;
    };


    const locationLabel = (location) => {
        let primary = LocationManager.getLabel(location?.primary);
        return (location?.name ? `${primary} (${location?.name})` : primary);
    };


    const register = (item) => {
        if (item?.api == 'dash') {
            let seasonId = item?.dash?.registration?.seasonID;
            let facilityId = item?.dash?.registration?.facilityID;
            let leagueId = item?.dash?.registration?.leagueId;
            let programId = item?.dash?.registration?.programID;
            return `https://member.daysmartrecreation.com/#/online/chelsea/activity-finder/programs/${programId}/levels/${leagueId}`;
        } else {
            let centerId = item?.exerp?.centerId;
            let semesterId = item?.exerp?.semesterId;
            let courseType = item?.exerp?.courseType;
            let activityId = item?.class?.activity?.id;
            let classAgeMin = item?.class?.age?.min; // GL 161
            let classAgeMax = item?.class?.age?.max; // GL 161
            let today = moment().format('YYYY-MM-DD');
            let range = moment().add(1, 'year').format('YYYY-MM-DD');
            let url = `https://myaccount.chelseapiers.com/courses?semester=${semesterId}&center=${centerId}&dateFrom=${today}&dateTo=${range}`;
            if (courseType) {
                url += `&courseType=${courseType}`;
            }
            // GL 161
            if (classAgeMin){
                url += `&ageMin=${classAgeMin}`;
            }
            if (classAgeMax){
                url += `&ageMax=${classAgeMax}`;
            }
            // GL 161
            return url;
        }
    };

    return (<>
        <div component="ClassesDOW" className="xl:px-[3.333%] wow fadeInUp bg-white  to-animate" id="" animated="true" >
            {/* FILTERS SELECTS */}
            {props?.data?.title &&
                <h3 className="px-6 pt-12 text-center lg:px-0 lg:pt-16 text-black-100">{props?.data?.title}</h3>
            }
            <div className="flex-col pt-16 lg:flex wow fadeInUp  to-animate"  animated="true">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">
                    {/* Sport Dropdown */}
                    <div className="flex flex-col min-w-[180px] lg:w-full xl:px-[1.3393%] px-3  relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenSport(false)}>
                            <span className="transition-all duration-100 label--bold ">Sport</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16  -rotate-90 transform   font-700"></i>
                        </div>
                        {!hiddenSport ? 
                            <div 
                                onMouseLeave={() => setHiddenSport(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i onClick={() => setHiddenSport(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {optionsSport.map((sport, index) => {
                                        if (sport == 'N/A') return (<></>); // Skip N/A

                                        return (<>
                                            <div 
                                                key={`classes-dow-sport-${index}`}
                                                onClick={(e) => toggleSport(sport, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterSport, sport)}>
                                                <div className="check w-4 mb-[2px]  h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{sport}</p>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                    {/* AGE Dropdown */}
                    <div className="flex flex-col min-w-[180px] lg:w-full xl:px-[1.3393%] px-3 relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenAge(false)}>
                            <span className="transition-all duration-100 label--bold ">Age</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenAge ? 
                            <div 
                                onMouseLeave={() => setHiddenAge(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenAge(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsAge.map((age, index) => {
                                        return (
                                            <div 
                                                key={`classes-dow-age-${age}-${index}`}
                                                onClick={(e) => toggleAge(age, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterAge, age)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{age}</p>
                                    </div>
                                        );
                                    })}

                                </div>
                            </div>
                        : null}
                    </div>
                    {/* Day Dropwdown */}
                    <div className="flex flex-col min-w-[180px]  lg:w-full xl:px-[1.3393%] px-3 relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenDay(false)}>
                            <span className="transition-all duration-100 label--bold ">Day</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenDay ? 
                            <div 
                                onMouseLeave={() => setHiddenDay(true)} 
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                    <i onClick={() => setHiddenDay(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {optionsDay.map((day, index) => {
                                        let lday = day.toLowerCase();
                                        return (
                                            <div 
                                                key={`classes-dow-day-${index}`}
                                                onClick={(e) => toggleDow(lday, e)} 
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterDay, lday)}
                                             >
                                                <div className='check w-4 mb-[2px]  h-4  border-2 border-white'> </div>
                                                <p className="label--bold">{day}</p>
                                    </div>
                                        );
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                    {/* Semester Dropwdown */}
                    <div className="flex flex-col min-w-[180px]  lg:w-full xl:px-[1.3393%] px-3 relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between "onClick={() => setHiddenSem(false)}>
                            <span className="transition-all duration-100 label--bold ">Season</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenSem ? 
                            <div 
                                onMouseLeave={() => setHiddenSem(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6">

                                <i onClick={() => setHiddenSem(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsSemester.map((semester, index) => {
                                        return (<>
                                            <div 
                                                key={`classes-dow-semester-${index}`}
                                                onClick={(e) => setFilterSemester(semester)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterSemester, semester)}
                                            >
                                                <div className="check w-4 mb-[2px]  h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{semester}</p>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>

            {/* No Results Message */}
            {!loading && !filteredClasses?.length && 
                <div className="flex-col hidden pt-16 lg:flex wow fadeInUp content-center to-animate mb-90px"  animated="true">
                    <h3 className="self-center" style={{ color: 'black' }}>{noResultsMessage}</h3><br></br><br></br>
                </div>
            }


            {loading && 
                <>
                    <div className="spinner flex justify-center items-center"><br /><br />
                        <div className="spinner-border border-slate-300 animate-spin  inline-block w-16 h-16 border-4 rounded-full" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </>
            }

            {/* Day Table */}
            {optionsDay.map((day) => {
                // Hide Empty Days.
                let classesDay = getClassesByDay(day);
                if (!classesDay?.length) return (<></>);

                if (filterDay.length) {
                    let lday = day.toLowerCase();
                    if (!filterDay.includes(lday)) {
                        return (<></>);
                    }
                }
                return (<>
            <div className="flex flex-col pt-8 pb-16 lg:pt-16 lg:pb-24 " id="classNamees">
                {/* Table Header */}
                <div data-wow-delay="0s" className="flex flex-col px-[36px] lg:px-28 cursor-pointer">
                    <div className="flex items-center  justify-between px-6 py-[10px] lg:p-6 border-t border-black-10 relative z-10  bg-black-100 text-white ">
                                <h5 className="">{day}</h5>
                    </div>
                </div>
                <div className="mx-[36px] lg:mx-28 relative z-10 bg-[#f7f7f7]  border-b border-black-10 ">
                    <div className="flex flex-col  lg:py-0 py-8">
                        <div className="text-black-100 flex space-y-3 lg:space-y-0 flex-col lg:flex-row lg:items-center lg:justify-between min-h-[90px] cursor-pointer">
                            <div className="item lead--extraLarge w-full items-center px-6 ">Class Name</div>
                            <div className="item lead--extraLarge w-full items-center px-6 mt-2 lead--regular"><p>Time/Length</p></div>
                            <div className="item lead--extraLarge w-full items-center px-6 mt-2 lead--regular"><p>Location</p></div>
                            <div className="item lead--extraLarge w-full items-center px-6 flex items-center lead--regular ">
                                <div className="lead--extraLarge mt-2 capitalize"><div>Sessions</div></div>
                            </div>
                            <div className="item w-full items-center justify-between px-6">
                                <div className=" lead--extraLarge mt-2 lead--regular">Price</div>
                            </div>
                            <div className="item w-full items-center justify-between px-6">
                                <div className=" lead--extraLarge mt-2 lead--regular">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>

                        {getClassesByDay(day)?.map((session, id) => {
                            let lday = day.toLowerCase();
                            let key  = `class-${session?.class?.id}-${id}-${lday}`;
                            return (
                                <div key={key} className="">
                                    <div 
                                        data-label="Class Info"
                                        className="hidden"
                                        data-class={JSON.stringify(session?.class)}
                                        data-api={session?.exerp ? JSON.stringify(session.exerp) : JSON.stringify(session?.dash)}
                                    ></div>
                                    {/* Class */}
                                    <div className="wrap mx-[36px] lg:mx-28 relative z-10 bg-white  border-b border-black-10 " onClick={() => toggleOpenDescription(key)}>
                                            <div className="flex flex-col  lg:py-0 py-8">
                                                <div className="text-black-100 flex space-y-3 lg:space-y-0 flex-col lg:flex-row lg:items-center lg:justify-between min-h-[144px] cursor-pointer">
                                                    <div className="item w-full items-center lead--extraLarge mt-2 px-6 ">{session?.class?.name}</div>
                                                    <div className="item w-full px-6 mt-2 lead--regular"><p>{session?.class?.time?.[lday]}  / {session?.class?.length?.[lday]}m</p></div>
                                                    <div className="item w-full px-6 mt-2 lead--regular">{locationLabel(session?.location)}</div>
                                                    <div className="item w-full items-center  px-6 lead--regular ">{session?.class?.sessions}</div>
                                                    <div className="item w-full flex items-center justify-between px-6">
                                                        ${formatCurrency(session?.price)}
                                                    </div>
                                                    <div className="item w-full flex items-center justify-between px-6">
                                                        <a target="_blank" href={register(session)} className="register relative z-50 label--bold btn btn--cobalt pt-[15px] sm:mr-3 pb-[13px] w-full h-full mr-[30px]">Register</a>
                                                        <i className={`cp-icon-arraySmallLeft text-9 leading-16 transform font-700 ` + (openDescription==key ? 'rotate-90' : '-rotate-90')}></i>
                                                    </div>
                                                </div>
                                        </div>
                                        {openDescription==key ? 
                                            <div className="bg-[#F7F7F7] mt-8 lg:mt-0 p-6 grid lg:grid-cols-2" >
                                                <div className="pb-3 border-b lg:border-r lead--extraLarge border-black-10 lg:border-b-0 text-black-100 text-opacity-70">What to Expect</div>
                                                <div className="mt-3 lead--small lg:mt-0 lg:px-6 text-black-100 text-opacity-70">{session?.class?.description.replace(/(<([^>]+)>)/gi, "")}</div>
                                            </div>
                                        : null}
                                    </div>

                                </div>
                            );
                        })}
                        
                                </div>
                </>);
            })}
            {/* End Day Table */}
        </div>
    </>);
};

export default ClassesDOW;