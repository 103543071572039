import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';
import { LineCTA } from '../CTA/LineCTA';

const AppPromo = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    return (
        <div
            className={`wrapper bg-sky-100 ${props?.className} z-[101]`}
            id={props?.anchor}
        >
            <div className="xl:w-[1px]  absolute  h-full z-[3]   left-[3.333%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full   z-[3] left-[18.88866666666667%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full   z-[3] left-[34.44433333333667%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full  z-[3]  left-[50%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full  z-[3]  right-[34.44433333333667%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full   z-[3] right-[18.88866666666667%] bg-[rgba(0,0,0,0.06)]"></div>
            <div className="xl:w-[1px]  absolute  h-full   z-[3] right-[3.333%] bg-[rgba(0,0,0,0.06)]"></div>

            <div className="w-[1px] xl:hidden  z-[3]  absolute h-full bg-[rgba(0,0,0,0.06)] left-3"></div>
            <div className="w-[1px] xl:hidden  z-[3] absolute h-full bg-[rgba(0,0,0,0.06)] left-1/2"></div>
            <div className="w-[1px] xl:hidden  z-[3] absolute h-full bg-[rgba(0,0,0,0.06)] right-3"></div>
            <div className="grid lg:grid-cols-2 pb-[64px] lg:pb-[0] wow fadeInUp">
                <div className="flex items-center pt-[64px]  px-6 pb-[49.5px] lg:py-0 lg:px-28">
                    <div className="flex flex-col ">
                        <img src={props?.logo} width={52} height={32} />
                        <p className="mt-4 label--bold text-black-100 lg:mt-6 wow fadeInUp">
                            {' '}
                            {props?.subTitle}
                        </p>
                        <h3 className="text-black-100 mt-[14px] lg:mt-3   wow fadeInUp">
                            {' '}
                            {props?.title}{' '}
                        </h3>
                        <div
                            className="mt-4 lead--regular text-black-100 wow fadeInUp"
                            dangerouslySetInnerHTML={{ __html: props?.content }}
                        />
                        <LineCTA
                            title={props?.contentLink?.title}
                            href={props?.contentLink?.url}
                            target={props?.contentLink?.target}
                            marginClass={'mt-[22.5px] lg:mt-[26.5px]'}
                            borderClass={'border--increase--black'}
                            textClassName={'text-black-100 '}
                        />
                    </div>
                </div>
                <div className="flex flex-col sm:max-w-[66.666%] max-w-[80%] w-full lg:pt-[96px] lg:pb-[95px] ">
                    <div className="flex flex-col md:px-14 ">
                        <img
                            className="wow fadeInUp"
                            src={
                                actualWidth > 768
                                    ? props?.desktop_image
                                    : props?.mobile_image
                            }
                        />
                    </div>
                    <div className="flex sm:flex-row w-full flex-col items-center  justify-center sm:space-x-6 px-6 mt-12 lg:mt-[64px] sm:px-3 space-y-4 sm:space-y-0 wow fadeInUp">
                        {props?.default
                            ? props?.ctas?.map((cta, index) => {
                                  return (
                                      <div className="w-full">
                                          <NextNavLink
                                              href={cta?.appLink?.url}
                                              target={cta?.appLink?.target}
                                          >
                                              <a className="flex items-center justify-center w-full h-full space-x-3 text-white btn btn--black label--bold">
                                                  <i
                                                      className={`${cta?.icon?.[0]?.acf_post_type_theme_assets?.class}   text-white text-20 leading-24 tracking-[0.6px]`}
                                                  ></i>{' '}
                                                  <span className="mt-[2px]">
                                                      {' '}
                                                      {cta?.appLink?.title}{' '}
                                                  </span>
                                              </a>
                                          </NextNavLink>
                                      </div>
                                  );
                              })
                            : props?.ctas?.map((cta, index) => {
                                  return (
                                      <div className="w-full">
                                          <NextNavLink
                                              href={cta?.app_link?.url}
                                              target={cta?.app_link?.target}
                                          >
                                              <a className="flex items-center justify-center w-full h-full space-x-3 text-white btn btn--black label--bold">
                                                  <i
                                                      className={`${cta?.link_icon} text-white text-20 leading-24 tracking-[0.6px]`}
                                                  ></i>{' '}
                                                  <span className="mt-[2px]">
                                                      {' '}
                                                      {
                                                          cta?.app_link?.title
                                                      }{' '}
                                                  </span>
                                              </a>
                                          </NextNavLink>
                                      </div>
                                  );
                              })}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AppPromo;
