import React from 'react';

export default function WaterHazardReservation(props) {
    return (<>
        <div className="bg-[#f7f7f7]">
        <div className="pt-12 sm:pt-16 lg:pt-24">
            <div className="mx-auto max-w-7xl px-4 text-center sm:px-6 lg:px-8">
            <div className="mx-auto max-w-3xl space-y-2 lg:max-w-none">
                <h2 className="text-xl font-semibold leading-6 text-black-100">Make a Reservation</h2>
            </div>
            </div>
        </div>

        <div className="mt-8 bg-gray-50 pb-12 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
            <div className="relative">

            <div className="absolute inset-0 h-3/4 bg-gray-900" />

            <div className="relative z-10 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                <div className="flex flex-col mx-auto max-w-md space-y-4 lg:grid lg:max-w-5xl lg:grid-cols-2 lg:gap-5 lg:space-y-0">

                    <div className="bg-white px-6 py-8 sm:p-10 sm:pb-6">
                        <div>
                            <h5 className="text-center font-semibold text-black-100">
                                Book With Opentable
                            </h5>
                        </div>
                        <div class="w-full h-[1px] mt-[30px] bg-[#dcdcdc]"></div>
                        <div className="my-7 text-center">
                            <a href="https://www.opentable.com/r/the-water-hazard-at-chelsea-piers-new-york?corrid=0c8aaad0-ba26-4944-8b30-61e4429a43ec&avt=eyJ2IjoyLCJtIjowLCJwIjowLCJzIjowLCJuIjowfQ&p=2&sd=2022-10-10T19%3A00%3A00" target="_blank">
                                <img className="inline" src="/openTableBtn.png" />
                            </a>
                        </div>
                        <div class="w-full h-[1px] mb-[30px] bg-[#dcdcdc]"></div>
                    </div>

                    <div className="bg-white px-6 py-8 sm:p-10 sm:pb-6">
                        <div>
                            <h5 className="text-center font-semibold text-black-100">
                                Book With Resy
                            </h5>
                        </div>
                        <div class="w-full h-[1px] mt-[30px] bg-[#dcdcdc]"></div>
                        <div className="my-12 text-center">
                            <a href="https://resy.com/cities/ny/the-water-hazard-bar-and-lounge?gclid=Cj0KCQjwhY-aBhCUARIsALNIC06RgesZEGCXDyafr639RM1QoQa25tAgjfHEfumaRy26BXLb52AonYAaAhNIEALw_wcB&gclsrc=aw.ds&date=2022-10-10&seats=2" target="_blank">
                                <img className="inline" src="/resyBtn.png" />
                            </a>
                        </div>
                        <div class="w-full h-[1px] mb-[30px] bg-[#dcdcdc]"></div>
                    </div>
                </div>
            </div>

            </div>
        </div>
        
        </div>
    </>);
}