import React, { useContext, useState, useEffect } from 'react';
import ACFAppPromo from '../blocks/acf/AppPromo';
import ACFOfferBanner from '../blocks/acf/OfferBanner';
import ACFZohoEmbed from '../blocks/acf/ZohoEmbed';
import ACFOfferNoticeBar from '../blocks/acf/OfferNoticeBar';
import ACFCardSlider from '../blocks/acf/CardSlider';
import ACFScheduleClassesYouth from '../blocks/acf/ScheduleClassesYouth';
import ACFWaterHazardReservation from '../blocks/acf/WaterHazardReservation';
import ACFCoachesSportsListing from '../blocks/acf/CoachesSportsListing';
import ACFPageSearch from '../blocks/acf/PageSearch';
import ACFBackToTop from '../blocks/acf/BackToTop';
import ACFClassCards from '../blocks/acf/ClassCards';
import ACFThreeColGrid from '../blocks/acf/ThreeColGrid';
import ACFCenterPageCTA from '../blocks/acf/CenterPageCTA';
import ACFClassSchedule from '../blocks/acf/ClassSchedule';
import ACFCoachesListing from '../blocks/acf/CoachesListing';
import ACFCommunityPartners from '../blocks/acf/CommunityPartners';
import ACFCtaTwoColGrid from '../blocks/acf/CTATwoColGrid';
import Separator from '../blocks/acf/separator';
import ACFDevices from '../blocks/acf/Devices';
import { ACFEventsListing } from '../blocks/acf/EventsListing';
import ACFFeaturedClass from '../blocks/acf/FeaturedClass';
import ACFFeaturingBlock from '../blocks/acf/FeaturingBlock';
import ACFFeaturingPost from '../blocks/acf/FeaturingPost';
import { ACFFooterCTA } from '../blocks/acf/FooterCTA';
import ACFHeroBanner from '../blocks/acf/HeroBanner';
import { ACFImageWithCTA } from '../blocks/acf/ImageWithCTA';
import ACFInfoBar from '../blocks/acf/InfoBar';
import ACFLocationHero from '../blocks/acf/LocationHero';
import { ACFLocationHighlight } from '../blocks/acf/LocationHighlight';
import ACFLocations from '../blocks/acf/Locations';
import { ACFMemberBenefit } from '../blocks/acf/MemberBenefit';
import { ACFMemberQuote } from '../blocks/acf/MemberQuote';
import ACFPageHero from '../blocks/acf/PageHero';
import ACFPartnerCarousel from '../blocks/acf/PartnerCarousel';
import ACFPostCarousel from '../blocks/acf/PostCarousel';
import ACFSchedule from '../blocks/acf/Schedules';
import { ACFTitleCTA } from '../blocks/acf/TitleCTA';
import ACFTitleTextGrid from '../blocks/acf/TitleTextGrid';
import ACFTwoColGrid from '../blocks/acf/TwoColGrid';
import ACFContactCard from '../blocks/acf/ContactCard';
import ACFTwoColContactForm from '../blocks/acf/TwoColContactForm';
import ContactHero from '../blocks/acf/ContactHero';
import TwoColParagraph from '../blocks/acf/TwoColParagraph';
import FaqAccordion from '../blocks/acf/FaqAccordion';
import ContentAccordion from '../blocks/acf/ContentAccordion';
import ImageWysiwygRow from '../blocks/acf/ImageWysiwygRow';
import ACFBirthdayPartiesSearch from '../blocks/acf/BirthdayPartiesSearch';
import ACFSummerCampsSearch from '../blocks/acf/SummerCampsSearch';

/* WordPress Core Gutenberg blocks */
import CoreColumn from '../blocks/core/column';
import CoreColumns from '../blocks/core/columns';
import CoreEmbed from '../blocks/core/embed';
import CoreGroup from '../blocks/core/group';
import CoreImage from '../blocks/core/image';
import CoreParagraph from '../blocks/core/paragraph';
import CoreSpacer from '../blocks/core/spacer';
import CoreLists from '../blocks/core/list';
import CoreButtons from '../blocks/core/buttons';
import CoreButton from '../blocks/core/button';
import CoreHeading from '../blocks/core/heading';
import CoreTable from '../blocks/core/table';
import CoreHTML from '../blocks/core/html';
/* ACF Blocks */

export const blockNameToComponent = {
    'acf/offer-banner': ACFOfferBanner,
    'acf/zoho-embed': ACFZohoEmbed,
    'acf/offer-notice-bar': ACFOfferNoticeBar,
    'acf/summer-camps': ACFSummerCampsSearch,
    'acf/birthday-parties': ACFBirthdayPartiesSearch,
    'acf/class-cards': ACFClassCards,
    'acf/schedule-classes-youth': ACFScheduleClassesYouth,
    'acf/water-hazard-reservation': ACFWaterHazardReservation, 
    'acf/coaches-sports-listing': ACFCoachesSportsListing,
    'acf/page-search': ACFPageSearch, 
    'acf/card-slider': ACFCardSlider,
    'acf/video-hero': ACFHeroBanner,
    'acf/info-bar-post': ACFInfoBar,
    'acf/featuring-post': ACFFeaturingPost,
    'acf/two-col-grid': ACFTwoColGrid,
    'acf/three-col-grid': ACFThreeColGrid,
    'acf/two-col-text-image-cta': ACFCtaTwoColGrid,
    'acf/posts-carousel': ACFPostCarousel,
    'acf/featured-class': ACFFeaturedClass,
    'acf/partner-carousel': ACFPartnerCarousel,
    'acf/footer-cta': ACFFooterCTA,
    'acf/page-hero': ACFPageHero,
    'acf/member-quote': ACFMemberQuote,
    'acf/member-benefits': ACFMemberBenefit,
    'acf/title-and-cta': ACFTitleCTA,
    'acf/app-promo': ACFAppPromo,
    'acf/title-text-row': ACFTitleTextGrid,
    'acf/image-with-cta': ACFImageWithCTA,
    'acf/devices': ACFDevices,
    'acf/locations-row': ACFLocations,
    'acf/location-highlight': ACFLocationHighlight,
    'acf/schedules': ACFSchedule,
    'acf/community-partners-listing': ACFCommunityPartners,
    'acf/back-to-top': ACFBackToTop,
    'acf/center-page-cta': ACFCenterPageCTA,
    'acf/location-page-hero': ACFLocationHero,
    'acf/events-listing': ACFEventsListing,
    'acf/coaches-listing': ACFCoachesListing,
    'acf/classes-schedule': ACFClassSchedule,
    'acf/featuring-block': ACFFeaturingBlock,
    'acf/contact-card': ACFContactCard,
    'acf/two-col-contact-form': ACFTwoColContactForm,
    'acf/contact-hero': ContactHero,
    'acf/two-col-paragraphs': TwoColParagraph,
    'acf/faq-accordion': FaqAccordion,
    'acf/content-accordion': ContentAccordion,
    'acf/separator': Separator,
    'acf/image-wysiwyg-row': ImageWysiwygRow,

    'core/column': CoreColumn,
    'core/columns': CoreColumns,
    'core/group': CoreGroup,
    'core/image': CoreImage,
    'core/paragraph': CoreParagraph,
    'core/embed': CoreEmbed,
    'core/spacer': CoreSpacer,
    'core/list': CoreLists,
    'core/button': CoreButton,
    'core/buttons': CoreButtons,
    'core/heading': CoreHeading,
    'core/table': CoreTable,
    'core/html': CoreHTML
};

export function renderGutenbergBlocks(blocks = [], pageProps = {}) {
    return blocks.map((block, index) => {
        let blockString = block.attributesJSON;
        blockString = blockString.replace('chelsea-piers-data-api.chelseapiers.com', 'dirk30xtfel0k.cloudfront.net');
        const blockAttributes = JSON.parse(blockString);
        const BlockComponent = blockNameToComponent[block.name];
        // console.dir(blockAttributes,{depth:null});

        // if (process.env.NODE_ENV === 'development') console.log(block.name)
        if (!BlockComponent) {
            /* Tried to render a block without a local equivalent */
            return null;
        }

        return (<>
            <BlockComponent
                key={`${block?.name}-${block?.order}-${block?.attributesJSON?.length}`}
                {...blockAttributes}
                pageProps={pageProps} 
            >
                {renderGutenbergBlocks(block.innerBlocks || [], pageProps)}
            </BlockComponent>
        </>);
    });
}

/* Utility function to reconstruct repeater fields' array client-side */
export function extractRepeaterField(data, fieldName) {
    if (!data) return null;
    
    if (!fieldName in data) {
        return null;
    }

    const topItem = data[fieldName];
    const dataKeys = Object.keys(data);
    const results = [];

    for (let i = 0; i < topItem; i++) {
        const re = new RegExp(`^${fieldName}_${i}_(.*)$`);
        const fields = {};

        for (const dataKey of dataKeys) {
            const matches = re.exec(dataKey);

            if (matches) {
                const [unmodifiedKey, fieldKey] = matches;

                fields[fieldKey] = data[unmodifiedKey];
            }
        }

        results.push(fields);
    }

    return results;
}
