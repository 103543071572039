import  { useContext, useState, useEffect } from 'react'
import { TitleTextGrid } from '../../components/chelsea/Grid/TitleTextGrid';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

const ACFTitleTextGrid = ({ data, className, anchor }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
    }, [locale, setShow, LocationManager, data]);

    let ctas = extractRepeaterField(filtered, 'ctas');
    
    return (<>
        {show &&
            <TitleTextGrid
                className={className}
                anchor={anchor}
                style={filtered?.style_options_header_size || 'h3'}
                title={filtered?.title}
                content={filtered?.content}
                ctas={ctas}
            />
        }
    </>);
};

export default ACFTitleTextGrid;
