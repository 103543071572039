import React, { useState } from 'react';

import  SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LineCTA } from '../CTA/LineCTA';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const CardSlider = (props) => {
    const getSlides = (fields, meta) => {
        let slides = [];
        for (let i=0; i<fields?.slides; i++) {
            let imageId = fields[`slides_${i}_image`];
            let iconId = fields[`slides_${i}_icon`];

            let iconUrl = props?.icons[iconId]?.data?.mediaItem?.mediaDetails?.file;

            let slide = {
                image:       meta?.images?.[imageId],
                title:       fields[`slides_${i}_title`],
                blurb:       fields[`slides_${i}_blurb`],
                link:        fields[`slides_${i}_link`],
            };
            if (iconUrl) {
                slide.icon = iconUrl;
            }
            slides.push(slide);
        }
        return slides;
    };

    return (<>
        <div
            className={` overflow-hidden bg-cover bg-white ${props?.className}`}
            // id={anchor}
        >

            <div>
                <div className="mx-auto max-w-4xl py-16 px-4 sm:py-24 sm:px-6 lg:px-8">
                    <div className="text-center">
                    {(props?.data?.title || props?.data?.blurb) &&
                        <div className="wow fadeInUp">
                            {props?.data?.title && 
                                <div className="headline-title w-full">
                                    <h2 className="flex-1 text-center mb-[30px] text-black-100">{props.data.title}</h2>
                                </div>
                            }
                            {props?.data?.blurb && 
                                <div className="headline-blurb w-full">
                                    <p className="text-black-100">{props.data.blurb}</p>
                                </div>
                            }
                        </div>
                    }
                    </div>
                </div>
            </div>
            <div className=" pb-[65.5px] ">
                <div className="px-3 hidden md:hidden lg:block xl:block desktop relative wrapperCarousal">
                    <div className="full-screen-right">
                        <Swiper
                            slidesPerView={5}
                            spaceBetween={30}
                            effect="fade"
                            navigation={{
                                prevEl: '.partner-prev',
                                nextEl: '.partner-next'
                            }}

                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination'
                            }}
                        >
                            {getSlides(props?.data, props?.meta)?.map((slide, index) => {
                                const [hover, setHover] = useState();
                                return (
                                    <SwiperSlide key={index}>
                                        <a 
                                            className="relative flex group mainCardWrapper  cursor-pointer  flex-col text-black-100"
                                            href={slide?.link?.url}
                                            onMouseEnter={() => setHover(index)}
                                            onMouseLeave={() => setHover()}
                                        >                                            
                                            <div
                                                className={` aspect-w-1	aspect-h-1 transition-all  duration-300`}
                                                style={{
                                                    backgroundImage:
                                                        hover === index
                                                            ? `url(${slide?.image?.src})`
                                                            : `url(${slide?.image?.src}), linear-gradient(0deg, rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0.4) )`,
                                                    backgroundBlendMode:
                                                        'multiply',
                                                    backgroundSize: 'cover'
                                                }}
                                            >
                                            {slide?.icon && 
                                                <div className="flex items-center justify-center h-full transition-all duration-300 group-hover:opacity-0">
                                                    {/* <img className="max-w-[80px]" src="https://chelsea-piers-data-api.chelseapiers.com/wp-content/uploads/2021/10/Gift.png" /> */}
                                                    {/* <img className="max-w-[80px]" src={'https://dirk30xtfel0k.cloudfront.net/wp-content/uploads/' + slide?.icon } /> */}
                                                    <img className="max-w-[80px]" src={`https://${process?.env?.NEXT_PUBLIC_API_DOMAIN}/wp-content/uploads/${slide.icon}`} />
                                                </div>
                                            }
                                            </div>
                                            <div className="slide-content w-full">
                                                    {slide.title && 
                                                        <h4 className="text-black-100 z-[1] mt-6 group-hover:text-cobalt-100 transition-all duration-300 wow fadeInUp  to-animate">
                                                            {slide.title}
                                                        </h4>
                                                    }
                                                    {slide?.blurb && 
                                                        <div className="slide-blurb pt-3 lead--regular lg:pt-3 wow fadeInUp  to-animate">
                                                            {slide.blurb}
                                                        </div>
                                                    }
                                                </div>
                                        </a>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>

                    <div
                        className="cursor-pointer absolute left-0 h-[64px] transform -translate-y-1/2 w-[64px] mx-[2%] z-[22] bg-buttonGray hidden lg:flex items-center justify-center partner-prev"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                    <div className="insight-custom-pagination"></div>
                    <div
                        className="cursor-pointer absolute right-0 h-[64px]   transform -translate-y-1/2 w-[64px] mx-[2%] z-[22] bg-buttonGray hidden lg:flex  items-center justify-center partner-next"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                </div>

                <div className="swiper-auto-width relative md:block lg:hidden xl:hidden wrapperCarousal mobile ">
                    <div className="wow fadeInUp full-screen-right">
                        <Swiper
                            slidesPerView="auto"
                            effect="fade"
                            navigation={{
                                prevEl: '.partner-prev',
                                nextEl: '.partner-next'
                            }}
                            spaceBetween={30}
                            loop={true}
                            loopFillGroupWithBlank={true}
                            //centeredSlides={true}
                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination-mobile'
                            }}
                        >

                            {getSlides(props?.data, props?.meta)?.map((slide, index) => {
                                return (
                                    <SwiperSlide key={index}>
                                        <div className="relative cursor-pointer group text-black-100 flex group mainCardWrapper flex-col w-[230px] md:w-[280px] lg:w-[280px]">
                                        <img className="" src={slide?.image?.src} srcset={slide?.image?.srcSet} />
                                            <div className="slide-content w-full">
                                                {slide.title && 
                                                    <h4 className="text-black-100 z-[1] mt-6 group-hover:text-cobalt-100 transition-all duration-300 wow fadeInUp  to-animate">
                                                        {slide.title}
                                                    </h4>
                                                }
                                                {slide?.blurb && 
                                                    <div className="slide-blurb pt-3 lead--regular lg:pt-4 wow fadeInUp  to-animate">
                                                        {slide.blurb}
                                                    </div>
                                                }
                                                <LineCTA
                                                    target={slide?.link?.url}
                                                    title={slide?.link?.title}
                                                    href={slide?.link?.url}
                                                    marginClass={'mt-[22.5px]'}
                                                    borderClass={'border--increase--cobalt'}
                                                    textClassName={
                                                        'text-black-100 group-hover:text-cobalt-100  '
                                                    }
                                                />
                                            </div>
                                        </div>
                                    </SwiperSlide>
                                );
                            })}
                        </Swiper>
                    </div>

                    <div
                        className="cursor-pointer absolute left-0 h-[64px] top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden lg:flex  items-center justify-center partner-prev"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                    <div className="insight-custom-pagination-mobile"></div>
                    <div
                        className="cursor-pointer absolute right-0 h-[64px]  top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden lg:flex  items-center justify-center partner-next"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className="transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default CardSlider;
