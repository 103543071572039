import React from 'react';
import { LineCTA } from '../CTA/LineCTA';

export const TitleTextGrid = (props) => {
    const renderH = () => {
        switch (props?.style) {
            case 'h2':
                return (
                    <h2 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h2>
                );

            case 'h3':
                return (
                    <h3 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h3>
                );
            case 'h1':
                return (
                    <h1 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h1>
                );

            case 'h4':
                return (
                    <h4 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h4>
                );
            case 'h5':
                return (
                    <h5 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h5>
                );

            case 'h6':
                return (
                    <h6 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h6>
                );
            default:
                return (
                    <h2 className="px-6 wow fadeInUp lg:px-28">
                        {' '}
                        {props?.title}{' '}
                    </h2>
                );
        }
    };

    return (
        <div
            className={`wrapper grid  lg:grid-cols-2 bg-white text-black-100 pt-[48px] lg:pt-[128px] ${props?.className}`}
            id={props?.anchor}
        >
            {renderH()}
            <div>
                <div
                    className="px-6 mt-5 lg:mt-0 lg:px-12 lead--extraLarge wow fadeInUp gn-content"
                    dangerouslySetInnerHTML={{ __html: props?.content }}
                />
                <div className="px-6 wow fadeInUp lg:px-[50px] ">
                    {props?.ctas?.map((item, index) => (
                        <LineCTA
                            title={item?.cta?.title}
                            href={item?.cta?.url}
                            target={item?.cta?.target}
                            marginClass={'mt-[22.5px]'}
                            borderClass={'border--increase--cobalt'}
                            textClassName={
                                'text-black-100 group-hover:text-cobalt-100'
                            }
                        />
                    ))}
                </div>
            </div>
        </div>
    );
};
