import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';
import { list } from 'postcss';

function CoreLists({
    align,
    values='',
    backgroundColor,
    anchor='',
    className,
    textColor,
    ordered=false,
    pageProps,
    style,
    data=false,
}) {    
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    let lists = values;

    if (backgroundColor === 'cyan-bluish-gray') {
        backgroundColor = '#abb8c3';
    } else if (backgroundColor === 'pale-pink') {
        backgroundColor = '#f78da7';
    } else if (backgroundColor === 'vivid-red') {
        backgroundColor = '#cf2e2e';
    } else if (backgroundColor === 'luminous-vivid-orange') {
        backgroundColor = '#ff6900';
    } else if (backgroundColor === 'luminous-vivid-amber') {
        backgroundColor = '#fcb900';
    } else if (backgroundColor === 'light-green-cyan') {
        backgroundColor = '#7bdcb5';
    } else if (backgroundColor === 'vivid-green-cyan') {
        backgroundColor = '#00d084';
    } else if (backgroundColor === 'pale-cyan-blue') {
        backgroundColor = '#8ed1fc';
    } else if (backgroundColor === 'vivid-cyan-blue') {
        backgroundColor = '#0693e3';
    } else if (backgroundColor === 'vivid-purple') {
        backgroundColor = '#9b51e0';
    }

    if (textColor === 'cyan-bluish-gray') {
        textColor = '#abb8c3';
    } else if (textColor === 'pale-pink') {
        textColor = '#f78da7';
    } else if (textColor === 'vivid-red') {
        textColor = '#cf2e2e';
    } else if (textColor === 'luminous-vivid-orange') {
        textColor = '#ff6900';
    } else if (textColor === 'luminous-vivid-amber') {
        textColor = '#fcb900';
    } else if (textColor === 'light-green-cyan') {
        textColor = '#7bdcb5';
    } else if (textColor === 'vivid-green-cyan') {
        textColor = '#00d084';
    } else if (textColor === 'pale-cyan-blue') {
        textColor = '#8ed1fc';
    } else if (textColor === 'vivid-cyan-blue') {
        textColor = '#0693e3';
    } else if (textColor === 'vivid-purple') {
        textColor = '#9b51e0';
    }

    if (style?.color?.text) {
        textColor = style?.color?.text;
    }
    if (style?.color?.background) {
        backgroundColor = style?.color?.background;
    }

    return (<>
        {show &&
            <div
                className={`${className ? className : ''} py-2 bg-white wrapper `}
                id={anchor}
                style={{
                    backgroundColor: backgroundColor ? backgroundColor : ''
                }}
            >
                <div className="px-6 lg:px-28 ">
                    <ul
                        dangerouslySetInnerHTML={{ __html: values }}
                        className={`lead--large text-black-100 lists relative z-[3] bg-white ${
                            ordered ? 'lists--ordered' : ''
                        }`}
                    />
                </div>
            </div>
        }
    </>);
}

export default CoreLists;
