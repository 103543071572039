import { Transition } from '@headlessui/react';
import React, { useState, useEffect, useRef } from 'react';

const MobileFilter = ({
    locationsFilter,
    coachFilter,
    classTypeFilter,
    classTimeFilter,
    getActiveLocationFilters,
    getActiveCoachFilters,
    getActiveClassTimeFilters,
    getActiveClassTypeFilters,
    closeMobileFilter,
    showMobileFilter,
    currentActiveLocation,
    currentActiveCoach,
    currentActiveClassTypes,
    currentActiveClassTimes
}) => {
    const ref = useRef();
    const [activeLocation, setActiveLocation] = useState([]);
    const [activeCoach, setActiveCoach] = useState([]);
    const [activeClassType, setActiveClassType] = useState([]);
    const [activeClassTime, setActiveClassTime] = useState([]);
    const [show, setShow] = useState([]);

    const cleanFilters = () => {
        setActiveLocation([]);
        setActiveCoach([]);
        setActiveClassType([]);
        setActiveClassTime([]);
        getActiveCoachFilters([]);
        getActiveLocationFilters([]);
        getActiveClassTimeFilters([]);
        getActiveClassTypeFilters([]);
        closeMobileFilter();
    };

    useEffect(() => {
        if (currentActiveLocation?.length > 0) {
            setActiveLocation(currentActiveLocation);
        }
        if (currentActiveCoach?.length > 0) {
            setActiveCoach(currentActiveCoach);
        }
        if (currentActiveClassTimes?.length > 0) {
            setActiveClassTime(currentActiveClassTimes);
        }
        if (currentActiveClassTypes?.length > 0) {
            setActiveClassType(currentActiveClassTypes);
        }
    }, [
        currentActiveLocation,
        currentActiveCoach,
        currentActiveClassTimes,
        currentActiveClassTypes
    ]);

    const sendFilters = () => {
        getActiveLocationFilters(activeLocation);
        getActiveCoachFilters(activeCoach);
        getActiveClassTimeFilters(activeClassTime);
        getActiveClassTypeFilters(activeClassType);
        closeMobileFilter();
    };

    useEffect(() => {
        if (showMobileFilter) {
            setShow(['locations', 'coaches', 'classTimes', 'classTypes']);
        }
    }, [showMobileFilter]);

    return (
        <div className="flex flex-col wrapper items-center pb-[44px] overflow-auto classScheduleHeight">
            <div className="w-[1px] xl:hidden  z-[3]  fixed h-full bg-grid left-3"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid left-1/2"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid right-3"></div>
            <div className={`flex flex-col relative z-[3]  mt-6`}>
                <div
                    onClick={() => {
                        if (show?.includes('classTypes')) {
                            setShow(show?.filter((item) => item !== 'classTypes'));
                        } else {
                            setShow([...show, 'classTypes']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('classTypes') ||
                        activeClassType?.some((classType) =>
                            classTypeFilter
                                ?.map?.((item) => item?.slug)
                                ?.includes(classType?.slug)
                        )
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold ">Class Types</span>
                    {activeClassType?.some((classType) =>
                        classTypeFilter
                            ?.map?.((item) => item?.slug)
                            ?.includes(classType?.slug)
                    ) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('classTypes')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('classTypes')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px]  transform bg-gray  px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {classTypeFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (
                                                activeClassType?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                            ) {
                                                setActiveClassType([
                                                    ...activeClassType?.filter(
                                                        (i) =>
                                                            i.slug !== item.slug
                                                    )
                                                ]);
                                            } else {
                                                setActiveClassType([
                                                    ...activeClassType,
                                                    item
                                                ]);
                                            }
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeClassType?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.name}{' '}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>

            <div className={`flex flex-col  mt-12 relative z-[3]`}>
                <div
                    onClick={() => {
                        if (show?.includes('locations')) {
                            setShow(show?.filter((item) => item !== 'locations'));
                        } else {
                            setShow([...show, 'locations']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('locations') ||
                        activeLocation?.some((classType) =>
                            locationsFilter
                                ?.map?.((item) => item?.slug)
                                ?.includes(classType?.slug)
                        )
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold ">Locations</span>
                    {activeLocation?.some((classType) =>
                        locationsFilter
                            ?.map?.((item) => item?.slug)
                            ?.includes(classType?.slug)
                    ) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('locations')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('locations')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px]   bg-gray   px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {locationsFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (
                                                activeLocation?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                            ) {
                                                setActiveLocation([
                                                    ...activeLocation?.filter(
                                                        (i) =>
                                                            i.slug !== item.slug
                                                    )
                                                ]);
                                            } else {
                                                setActiveLocation([
                                                    ...activeLocation,
                                                    item
                                                ]);
                                            }
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeLocation?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.name}{' '}
                                        </p>
                                        {/* <span className="ml-3 opacity-70">
                                            {' '}
                                            {item?.count}
                                        </span> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>

            <div className={`flex flex-col  relative z-[3] mt-12`}>
                <div
                    onClick={() => {
                        if (show?.includes('coaches')) {
                            setShow(show?.filter((item) => item !== 'coaches'));
                        } else {
                            setShow([...show, 'coaches']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('coaches') ||
                        activeCoach?.some((classType) =>
                            coachFilter
                                ?.map?.((item) => item?.slug)
                                ?.includes(classType?.slug)
                        )
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold ">Instructors</span>
                    {activeCoach?.some((classType) =>
                        coachFilter
                            ?.map?.((item) => item?.slug)
                            ?.includes(classType?.slug)
                    ) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('coaches')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('coaches')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px]  transform bg-gray   px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {coachFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (
                                                activeCoach?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                            ) {
                                                setActiveCoach([
                                                    ...activeCoach?.filter(
                                                        (i) =>
                                                            i.slug !== item.slug
                                                    )
                                                ]);
                                            } else {
                                                setActiveCoach([
                                                    ...activeCoach,
                                                    item
                                                ]);
                                            }
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeCoach?.findIndex(
                                                    (elem) =>
                                                        elem?.slug ===
                                                        item?.slug
                                                ) > -1
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.name}{' '}
                                        </p>
                                        {/* <span className="ml-3 opacity-70">
                                            {' '}
                                            {item?.count}
                                        </span> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>

           
            <div className={`flex flex-col relative z-[3]  mt-12 mb-20 `}>
                <div
                    onClick={() => {
                        if (show?.includes('classTimes')) {
                            setShow(show?.filter((item) => item !== 'classTimes'));
                        } else {
                            setShow([...show, 'classTimes']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('classTimes') || activeClassTime?.some((classTime) =>
                        classTimeFilter
                            ?.map?.((item) => item?.slug)
                            ?.includes(classTime?.slug)
                    )
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold  ">Time Of Day</span>
                    {activeClassTime?.some((classTime) =>
                        classTimeFilter
                            ?.map?.((item) => item?.slug)
                            ?.includes(classTime?.slug)
                    ) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('classTimes')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('classTimes')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px]  transform bg-gray   px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {classTimeFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            if (
                                                activeClassTime?.findIndex(
                                                    (elem) =>
                                                        elem?.term?.slug ===
                                                        item?.term?.slug
                                                ) > -1
                                            ) {
                                                setActiveClassTime([
                                                    ...activeClassTime?.filter(
                                                        (i) =>
                                                            i?.term?.slug !== item?.term?.slug
                                                    )
                                                ]);
                                            } else {
                                                setActiveClassTime([
                                                    ...activeClassTime,
                                                    item
                                                ]);
                                            }
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeClassTime?.findIndex(
                                                    (elem) =>
                                                        elem?.term?.slug ===
                                                        item?.term?.slug
                                                ) > -1
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.term?.name} &#x0002F; {item?.start_time}&ndash;{item?.end_time}
                                        </p>
                                        {/* <span className="ml-3 opacity-70">
                                            {' '}
                                            {item?.count}
                                        </span> */}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>
            {activeLocation
                ?.concat?.(activeCoach)
                ?.concat?.(activeClassTime)
                ?.concat?.(activeClassType)?.length > 0 ? (
                <div className="fixed bottom-0 w-full z-[3] h-20 px-4 bg-black-100 wr">
                    <div className="flex items-center justify-center h-full space-x-4">
                        <div className="btn btn--gray bg-[#999999] label--bold pt-[15px] max-h-[48px] flex items-center text-black-100 justify-between px-6 w-full max-w-[156px]">
                            <span
                                className="label--bold"
                                onClick={() => cleanFilters()}
                            >
                                CLear All
                            </span>
                            <span className="opacity-50 ">
                                {
                                    activeLocation
                                        ?.concat?.(activeCoach)
                                        ?.concat?.(activeClassTime)
                                        ?.concat?.(activeClassType)?.length
                                }
                            </span>
                        </div>
                        <div className="btn btn--sky label--bold flex pt-[15px] max-h-[48px] items-center justify-between px-6 w-full max-w-[156px]">
                            <p
                                className="w-full label--bold"
                                onClick={() => sendFilters()}
                            >
                                Apply
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MobileFilter;
