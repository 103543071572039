import { Transition } from '@headlessui/react';
import { useRouter } from 'next/router';
import React, { useEffect, useState, useContext } from 'react';
import Script from 'next/script';
import { useAppContext } from '../../../util/context';
import { LineCTA } from '../CTA/LineCTA';
import { LocationContext } from '../../../util/LocationProvider'

export const TwoColContactForm = ({
                                      className = '',
                                      anchor = '',
                                      top_heading,
                                      main_heading,
                                      description,
                                      description_link,
                                      form_embed,
                                      notes
                                  }) => {

    let LocationManager = useContext(LocationContext);

    const haveWindow = (typeof window !== 'undefined');
    const router = useRouter();

    useEffect(() => {
        LocationManager.zohoReload(); // Repeat preserved URL params.
    });
    
    const context = useAppContext();
    const [showLocation, setShowLocation] = useState(false);
    useEffect(() => {
        context?.setHideNav(true);
        return () => {
            context?.setHideNav(false);
        };
    }, []);

    const [zohoReload, setZohoReload] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [success, setSuccess] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastNameError, setLastNameError] = useState(false);
    const [emailError, setEmailError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [zipError, setZipError] = useState(false);
    const [locationError, setLocationError] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [zip, setZip] = useState('');
    const [location, setLocation] = useState('Select Location');

    const extractScript = (embed) => {
        if (!document || !form_embed) return;

        let script = '';

        // Add Zoho form embed to a Div object to parse the script tags.
        let container = document.createElement('div');
        container.innerHTML = embed; 

        // Loop through the <script> tags and add the script to the 
        let scriptTags = container.getElementsByTagName('script');
        for (let tag of scriptTags) {
            script = script + "\n" + tag.innerHTML;
        }

        return script;
    };

    const handleSubmit = async (e) => {
        setIsLoading(true);

        if(!isFormValid()) {
            setIsLoading(false);
            return;
        }

        e.preventDefault();
        const url = 'https://flow.zoho.com/737603006/flow/webhook/incoming?zapikey=1001.26118f31d7b63d590a95ceff4b82e52f.b1cc6c838f3d5147dc99961a5b843711&isdebug=false';

        let finalLocation = '';
        switch(location) {
            case 'Chelsea': finalLocation = 'Chelsea'; break;
            case 'Downtown Brooklyn': finalLocation = 'Brooklyn'; break;
            case 'Stamford, CT': finalLocation = 'Connecticut'; break;
            default: finalLocation = location; break;
        }

        let currentPage = router.asPath;

        let params = new URLSearchParams();
        params.set('firstName', firstName);
        params.set('lastName', lastName);
        params.set('email', email);
        params.set('phone', phone);
        params.set('zip', zip);
        params.set('CRMSource', 'Web Lead');
        params.set('clubLocation', finalLocation);
        params.set('page', currentPage);


        const res = await fetch(url, {
            mode: 'no-cors',
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: params.toString()
        });
        const obj = await res.text();
        if (res?.status === 200 || res?.status === 0) {
            setIsLoading(false);
            setSuccess(true);
        } else {
            setIsLoading(false);
            setSuccess(false);
            setHasError(true);
            setTimeout(() => {
                setHasError(false);
            }, 3000);
        }
    };

    const validateEmail = (email) => {
        const regEx = /\S+@\S+\.\S+/;
        return regEx.test(String(email).toLowerCase());
    }
    
    const isFormValid = () => {
        // Error in form flag
        let valid = true;

        // Reset the error state
        setFirstNameError(false);
        setLastNameError(false);
        setEmailError(false);
        setPhoneError(false);
        setZipError(false);
        setLocationError(false);

        if (firstName === '') {
            setFirstNameError(true);
            valid = false;
        }

        if (lastName === '') {
            setLastNameError(true);
            valid = false;
        }

        if (email === '' || !validateEmail(email)) {
            setEmailError(true);
            valid = false;
        }

        if (phone === '') {
            setPhoneError(true);
            valid = false;
        }

        if (zip === '') {
            setZipError(true);
            valid = false;
        }

        if (location === 'Select Location') {
            setLocationError(true);
            valid = false;
        }

        return valid;
    }
    
    const returnToPreviousPage = (e) => {
        e.preventDefault();
        if (form_embed) {
            // If the form was embedded, we are on the book a tour form.
            // If this the case we need to return users to the relevant location page, not the book a tour page
            switch (router.asPath) {
                case '/book-a-tour-brooklyn-downtown': router.replace('/locations/downtown-brooklyn'); break;
                case '/book-a-tour-chelsea': router.replace('/locations/chelsea-ny'); break;
                case '/book-a-tour-connecticut': router.replace('/locations/stamford-ct'); break;
                default: router.back(); break;
            }
        } else {
            router.back();
        }
    }

    return (
        <div
            component="TwoColContactForm"
            className={`wrapper relative grid lg:grid-cols-2 bg-gray-dark text-white lg:py-24 py-16 pt-0 lg:gap-0 gap-12 ${className}`}
            id={anchor}
        >
            <div className="-mx-2 py-2 lg:hidden flex items-center px-4 lg:px-0 relative lg:static lg:z-0 z-[3] lg:border-none border-b border-solid border-grid bg-gray-dark">
                <div className="w-full">
                    {process?.env?.NEXT_PUBLIC_SUBSITE=='fitness' &&
                        <img alt="Chelsea Piers Fitness Logo" src="/logos/CP-Fitness_Horiz_Orig.svg" />
                    }
                    {process?.env?.NEXT_PUBLIC_SUBSITE!='fitness' && 
                        <img alt="Chelsea Piers Logo" src={LocationManager.getLogo(process?.env?.NEXT_PUBLIC_SUBSITE, false)} />
                    }
                </div>
                <div className="py-2 pl-6 -my-2 border-l border-grid">
                    <a
                        href=""
                        onClick={(e) => {returnToPreviousPage(e)}}
                        className="inline-flex items-center justify-center w-12 h-12 text-white cursor-pointer top-6 right-6 rounded-30   bg-gray  hover:bg-black-100 text-12 font-700 z-[3] transition-all transform  "
                    >
                        <i className="cp-icon-modalClose"/>
                    </a>
                </div>
            </div>
            <a
                href=""
                onClick={(e) => {returnToPreviousPage(e)}}
                className="absolute hidden lg:inline-flex items-center justify-center w-12 h-12 text-white cursor-pointer top-6 right-6 rounded-30   bg-gray  hover:bg-black-100 text-12 font-700 z-[3] transition-all transform  "
            >
                <i className="cp-icon-modalClose"/>
            </a>
            <div className="items-center justify-center hidden mb-24 col-span-full lg:flex">
                {process?.env?.NEXT_PUBLIC_SUBSITE=='fitness' && 
                    <img alt="Chelsea Piers Fitness Logo" src="/logos/CP_Fitness_white.svg" />
                }
                {process?.env?.NEXT_PUBLIC_SUBSITE!='fitness' &&
                    <img alt="Chelsea Piers Logo" src={LocationManager.getLogo(process?.env?.NEXT_PUBLIC_SUBSITE, false)} />
                }
            </div>
            <div className="px-6 lg:px-[7.8vw]">
                {top_heading && (
                    <p
                        dangerouslySetInnerHTML={{ __html: top_heading }}
                        className="lg:label--largeBold label--bold text-sky-100"
                    />
                )}
                {main_heading && (
                    <h3
                        className="mt-3 lg:mt-6"
                        dangerouslySetInnerHTML={{ __html: main_heading }}
                    />
                )}
                {description && (
                    <div
                        className="mt-4 lead--large lg:mt-8 "
                        dangerouslySetInnerHTML={{ __html: description }}
                    />
                )}
                {description_link && (
                    <LineCTA
                        title={description_link?.title}
                        href={description_link?.url}
                        target={description_link?.target}
                        marginClass={'mt-6 lg:mt-[34.5px] '}
                        borderClass={'border--increase'}
                        textClassName={'text-white group-hover:text-sky-100'}
                    />
                )}
                {notes && (
                    <div
                        className="mt-4 text-white lead--small lg:mt-8 text-opacity-70"
                        dangerouslySetInnerHTML={{ __html: notes }}
                    />
                )}
            </div>
            <div className="px-3 lg:px-[3.33vw]">
                <div className="lg:p-16 p-8 bg-white text-black-100 relative z-[3]">
                    {form_embed && (haveWindow && window?.location?.search?.includes('zohoReload')) && (<>
                        <div dangerouslySetInnerHTML={{ __html: form_embed }} />
                        <Script id="zoho-embed-script" dangerouslySetInnerHTML={{ __html: extractScript(form_embed) }} />
                    </>)}
                    {success && (
                        <div>
                            <i className="cp-icon-CheckCircle text-[72px] font-300 text-cobalt-100"/>
                            <h3 className="mt-9">
                                Thank you for reaching out!{' '}
                            </h3>{' '}
                            <p className="mt-5 lead--large lg:mt-8 ">
                                A member of the Chelsea Piers Fitness team will
                                be in touch.
                            </p>
                            <a
                                className="label--bold btn btn--cobalt pt-[15px] pb-[13px] mt-12 w-full h-full"
                                target=""
                                onClick={(e) => {returnToPreviousPage(e)}}
                            >
                                Back to Previous Page
                            </a>
                        </div>
                    )}
                    {hasError && (
                        <div className="px-6 py-4 mb-12 bg-red bg-opacity-5 lead--large text-red">
                            Sorry, there was an error submitting the form.
                            Please try again.
                        </div>
                    )}
                    {!form_embed && !success && (
                        <>
                            <h5 className="mb-6 lg:mb-10">Your information</h5>
                            <form name="become-a-member">
                                <div className="flex flex-col mb-16 space-y-8 lg:mb-24 lg:space-y-12">
                                    <div>
                                        <p className="label--bold text-opacity-60 text-black-100">
                                            First Name
                                            <span className="text-cobalt-100">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            type="text"
                                            name="fname"
                                            className="form-input lead--large"
                                            onChange={(event) =>
                                                setFirstName(event.target.value)
                                            }
                                            required
                                        />
                                        {firstNameError && (
                                            <p className="mt-2 lead--small text-red">Please enter a first name.</p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="label--bold text-opacity-60 text-black-100">
                                            Last Name
                                            <span className="text-cobalt-100">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            type="text"
                                            name="lname"
                                            className="form-input lead--large"
                                            onChange={(event) =>
                                                setLastName(event.target.value)
                                            }
                                            required
                                        />
                                        {lastNameError && (
                                            <p className="mt-2 lead--small text-red">Please enter a last name.</p>
                                        )}
                                    </div>
                                    <div>
                                        <p className="label--bold text-opacity-60 text-black-100">
                                            Email{' '}
                                            <span className="text-cobalt-100">
                                                *
                                            </span>
                                        </p>
                                        <input
                                            type="email"
                                            name="email"
                                            className="form-input lead--large "
                                            // className="form-input lead--large !border-red"
                                            onChange={(event) =>
                                                setEmail(event.target.value)
                                            }
                                            required
                                        />
                                        {emailError && (
                                            <p className="mt-2 lead--small text-red">Please enter an email address.</p>
                                        )}
                                    </div>
                                    <div className="grid gap-8 sm:gap-12 sm:grid-cols-2 ">
                                        <div>
                                            <p className="label--bold text-opacity-60 text-black-100">
                                                Phone{' '}
                                                <span className="text-cobalt-100">
                                                    *
                                                </span>
                                            </p>

                                            <input
                                                type="tel"
                                                name="phone"
                                                className="form-input lead--large"
                                                onChange={(event) =>
                                                    setPhone(event.target.value)
                                                }
                                                required
                                            />
                                            {phoneError && (
                                                <p className="mt-2 lead--small text-red">Please enter a phone number.</p>
                                            )}
                                        </div>{' '}
                                        <div>
                                            <p className="label--bold text-opacity-60 text-black-100">
                                                ZIP{' '}
                                                <span className="text-cobalt-100">
                                                    *
                                                </span>
                                            </p>
                                            <input
                                                type="text"
                                                name="zip"
                                                onChange={(event) =>
                                                    setZip(event.target.value)
                                                }
                                                className="form-input lead--large"
                                            />
                                            {zipError && (
                                                <p className="mt-2 lead--small text-red">Please enter a zip.</p>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <h5 className="mb-6 lg:mb-10">Choose Club</h5>
                                <div className="relative z-[4]">
                                    <p className="label--bold text-opacity-60 text-black-100">
                                        Club Location
                                        <span className="text-cobalt-100">
                                            *
                                        </span>
                                    </p>
                                    <div className="relative">
                                        <a
                                            href=""
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setShowLocation(!showLocation);
                                            }}
                                            className={`form-input flex justify-between  lead--large text-black-100 text-left ${
                                                location === 'Select Location'
                                                    ? 'text-opacity-30'
                                                    : ''
                                            }`}
                                        >
                                            <span> {location}</span>{' '}
                                            <i className="text-opacity-100 transform -rotate-90 text-black-100 cp-icon-arraySmallLeft text-12 leading-16 font-700"/>
                                        </a>
                                        <Transition
                                            show={showLocation}
                                            enter="transition duration-100 ease-out"
                                            enterFrom="transform scale-95 opacity-0"
                                            enterTo="transform scale-100 opacity-100"
                                            leave="transition duration-75 ease-out"
                                            leaveFrom="transform scale-100 opacity-100"
                                            leaveTo="transform scale-95 opacity-0"
                                            className="absolute left-0 w-full h-auto origin-top top-8 lg:top-11"
                                        >
                                            <div className="flex flex-col py-6 space-y-[18px] px-6 bg-buttonGray border-t border-cobalt-100">
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setLocation('Chelsea');
                                                        setShowLocation(
                                                            !showLocation
                                                        );
                                                    }}
                                                    href=""
                                                    className="label--bold hover:text-cobalt-100 "
                                                >
                                                    Chelsea
                                                </a>
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setLocation(
                                                            'Downtown Brooklyn'
                                                        );
                                                        setShowLocation(
                                                            !showLocation
                                                        );
                                                    }}
                                                    href=""
                                                    className="label--bold hover:text-cobalt-100 "
                                                >
                                                    Downtown Brooklyn
                                                </a>
                                                <a
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setLocation(
                                                            'Stamford, CT'
                                                        );
                                                        setShowLocation(
                                                            !showLocation
                                                        );
                                                    }}
                                                    href=""
                                                    className="label--bold hover:text-cobalt-100 "
                                                >
                                                    Stamford, CT
                                                </a>
                                            </div>
                                        </Transition>
                                        {locationError && (
                                            <p className="mt-2 lead--small text-red">Please select a location.</p>
                                        )}
                                    </div>
                                </div>

                            </form>
                            <button
                                className="label--bold btn btn--cobalt pt-[15px] pb-[13px] mt-12 w-full h-full relative z-0"
                                // type="submit"
                                disabled={isLoading}
                                onClick={handleSubmit}
                            >
                                Submit
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};
