import  { useContext, useState, useEffect } from 'react';
import FullWidthImageCTA from '../../components/chelsea/CTA/FullWidthImageCTA';
import { LocationContext } from '../../util/LocationProvider';

const ACFOfferBanner = ({data,meta,pageProps}) => {    
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let offer = {};
    pageProps?.componentQueries?.banners?.forEach((banner) => {
        if (banner?.data?.offer?.databaseId == data?.offer) {
            offer = banner.data.offer;
        }
    });

    let fields = {
        title: offer?.title,
        eyebrow: offer?.title,
        subTitle: offer?.acf_sub_title?.subTitle,
        blurb: offer?.content,
        link: {
            title: offer?.acf_sub_title?.ctaText,
            target: offer?.acf_sub_title?.target,
        },
        image: {
            src: offer?.featuredImage?.node?.sourceUrl,
            srcSet: offer?.featuredImage?.node?.srcSet,
        }
    };

    return (<>
        {show && 
            <FullWidthImageCTA 
                banners={pageProps?.componentQueries?.banners}
                {...fields} 
            />
        }
    </>)
};

export default ACFOfferBanner;