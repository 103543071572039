import React from 'react';
import { LineCTA } from '../CTA/LineCTA';

const FeaturingPost = (props) => {
    return (
        <div
            className={`wrapper bg-white ${props?.className}`}
            id={props?.anchor}
        >
            <div className="grid grid-cols-1 lg:grid-cols-2 pt-[64px] lg:pt-[128px] ">
                <div className="flex flex-col px-6 lg:px-28">
                    <p
                        className="label--largeBold text-cobalt-100 wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        {props?.subHeading}
                    </p>
                    <h2
                        className="mt-4 text-black-100 lg:mt-6 wow fadeInUp"
                        data-wow-delay="0.3s"
                    >
                        {props?.heading}
                    </h2>
                    <div
                        data-wow-delay="0.6s"
                        className="mt-5 lead--large text-black-100 lg:mt-8 wow fadeInUp"
                        dangerouslySetInnerHTML={{ __html: props?.content }}
                    />
                </div>
                <div className="flex flex-col pt-[64px] lg:pt-0 group mainCardWrapper cursor-pointer">
                    <div className="relative flex flex-col wow !visible overflow-hidden">
                        <div className="rev-img-wrap relative z-[1]">
                            <div className="rev-img">
                                <img src={props?.image} className="w-full" />
                            </div>
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full rev-bg bg-black-20"></div>{' '}
                        <div className="absolute top-0 left-0 w-full h-full transition-all duration-300 group-hover:bg-black-20"></div>
                    </div>
                    <div className="flex flex-col justify-end z-[2] mt-[-14%] lg:mt-[-10%] ">
                        <div className="flex flex-col max-w-[93%] lg:max-w-[83%] lg:self-end bg-white px-6 pt-6">
                            {/* <p className="label--bold text-cobalt-100"> Running </p> */}
                            <h4 className="text-black-100 group-hover:text-cobalt-100 transition-all duration-300 mt-[14px] mt-4 lg:mt-3 wow fadeInUp">
                                {' '}
                                {props?.postTitle}
                            </h4>
                            {/* <p className="mt-4 lead--regular text-black-100"> Join classes on Thursdays and Sundays, 9/10 – 10/25. </p> */}
                            {props?.cta?.url &&
                                <LineCTA
                                    target={props?.cta?.target}
                                    title={props?.cta?.title}
                                    href={props?.cta?.url}
                                    marginClass={'mt-[22.5px]'}
                                    borderClass={'border--increase--cobalt'}
                                    textClassName={
                                        'text-black-100 group-hover:text-cobalt-100'
                                    }
                                />
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default FeaturingPost;
