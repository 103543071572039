import { useContext, useState, useEffect } from 'react';
import BirthdayPartiesSearch from '../../components/chelsea/Search/BirthdayPartiesSearch';
import { LocationContext } from '../../util/LocationProvider';

const ACFBirthdayPartiesSearch = ({ data, meta, pageProps, className='', anchor='' }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <BirthdayPartiesSearch
                className={className}
                anchor={anchor}
                data={data}
                meta={meta}
                parties={pageProps?.componentQueries?.parties?.data?.birthdayParties?.edges} 
            />
        }
    </>);
};

export default ACFBirthdayPartiesSearch;