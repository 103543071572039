import { Transition } from '@headlessui/react';
import React, { useState, useEffect, Fragment, useRef } from 'react';
import { useRouter } from "next/router";
import LevelCoachCards from './coachesComponent/LevelCoachCards';
import MobileFilter from './coachesComponent/MobileFilter';

const Coaches = ({
    locations,
    levels,
    specialities,
    className,
    anchor,
    coaches
}) => {
    const router = useRouter();

    const ref = useRef();
    const ref1 = useRef();
    const mobileRef = useRef();
    const [locationsFilter, setLocationsFilter] = useState();
    const [specialityFilter, setSpecialityFilter] = useState();
    const [activeLocation, setActiveLocation] = useState([]);
    const [activeSpeciality, setActiveSpeciality] = useState([]);
    const [show, setShow] = useState('');
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const [finalCoaches, setFinalCoaches] = useState();
    const [coachesToDisplay, setCoachesToDisplay] = useState();
    const [finalCoachesToDisplay, setFinalCoachesToDisplay] = useState();

    useEffect(() => {
        let tempLocation = [];
        let finalLocationCount = 0;
        const pLocations = Array.isArray(locations) 
            ? locations
            : Object.keys(locations).map(t => locations[t])

        pLocations?.forEach((location, index) => {
            tempLocation.push(location);
            finalLocationCount = finalLocationCount + location?.count;
        });
        tempLocation = [...tempLocation];

        setLocationsFilter(tempLocation); 
    }, [locations]);

    useEffect(() => {
        const mapping = { 
            'Chelsea': 'chelsea',
            'Brooklyn': 'downtown-brooklyn',
            'Stamford': 'stamford-ct',
            'Prospect-Heights': 'prospect-heights',
        };

        if (router?.query?.facility) {
            let slug = mapping[router.query.facility];
            locations.forEach(location => {
                if (slug == location?.slug) {
                    setActiveLocation([location]);
                }
            });
        }
    }, [locationsFilter]); // Watch locations filter for updated options to be set.

    useEffect(() => {
        //locations
        let tempCategory = [];
        let finalCategoryCount = 0;
        const pSpecialities = Array.isArray(specialities) 
            ? specialities 
            : Object.keys(specialities).map(t => specialities[t])
        pSpecialities?.forEach((category, index) => {
            tempCategory.push(category);
            finalCategoryCount = finalCategoryCount + category?.count;
        });
        tempCategory = [...tempCategory];

        setSpecialityFilter(tempCategory);
    }, [specialities]);



    const removeFilter = (slug) => {
        if (activeLocation?.findIndex((elem) => elem?.slug === slug) > -1) {
            setActiveLocation([
                ...activeLocation?.filter((i) => i.slug !== slug)
            ]);
        } else if (
            activeSpeciality?.findIndex((elem) => elem?.slug === slug) > -1
        ) {
            setActiveSpeciality([
                ...activeSpeciality?.filter((i) => i.slug !== slug)
            ]);
        }
    };

    useEffect(() => {
        let items = [];
        coaches?.map((coach, index) => {
            let object = {};
            object = { ...coach };

            object.actualSpecialities = coach?.specialties?.map(
                (speciality) => speciality?.slug
            );
            object.actualLocations = coach?.locations?.map(
                (location) => location?.slug
            );
            object.actualLevels = coach?.levels?.length
                ? coach?.levels?.map((level) => level?.slug)
                : ['none'];

            items.push(object);
        });
        setFinalCoaches(items);
    }, [coaches]);

    useEffect(() => { 
        filterEvents();
    }, [activeLocation, activeSpeciality, finalCoaches]);

    const filterEvents = () => {
        let items = finalCoaches;

        if (activeLocation?.length > 0 && activeSpeciality?.length === 0) {
            let array = items?.filter((item, index) =>
                activeLocation?.some((location) =>
                    item?.actualLocations?.includes(location?.slug)
                )
            );
            setCoachesToDisplay(array);
        } else if (
            activeSpeciality?.length > 0 &&
            activeLocation?.length === 0
        ) {
            let array = items?.filter((item, index) =>
                activeSpeciality?.some((speciality) =>
                    item?.actualSpecialities?.includes(speciality?.slug)
                )
            );

            setCoachesToDisplay(array);
        } else if (activeSpeciality?.length > 0 && activeLocation?.length > 0) {
            let array = items?.filter((item, index) =>
                activeSpeciality?.some((speciality) =>
                    item?.actualSpecialities?.includes(speciality?.slug)
                )
            );
            let anotherArray = array?.filter((item, index) =>
                activeLocation?.some((location) =>
                    item?.actualLocations?.includes(location?.slug)
                )
            );
            setCoachesToDisplay(anotherArray);
        } else {
            setCoachesToDisplay(items);
        }
    };

    useEffect(() => {
        let items = [];
        coachesToDisplay?.map((item, index) => {
            if (item?.levels?.length === 0) {
                let object = {
                    ...item,
                    levels: ['none'],
                    image:
                        coachesToDisplay?.[coachesToDisplay?.length - 1]?.image
                };
                items.push(object);
            } else {
                let object = { ...item };
                items.push(object);
            }
        });
        setFinalCoachesToDisplay(items);
    }, [coachesToDisplay]);

    const cleanAllFilters = () => {
        setActiveSpeciality([]);
        setActiveLocation([]);
    };

    return (
        <div className={`xl:px-[3.333%]   bg-white ${className}`} id={anchor}>
            <div className="flex-col hidden pt-20 lg:flex ">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[33.332%]  space-y-4  lg:flex-row lg:space-y-0">
                    <div
                        className={`flex flex-col lg:w-full xl:px-[2.6786%] px-3  relative`}
                    >
                        <div
                            onClick={() =>
                                setShow(show === 'locations' ? '' : 'locations')
                            }
                            className={`flex items-center  btn ${
                                show === 'locations' ||
                                activeLocation?.some((classType) =>
                                    locationsFilter
                                        ?.map?.((item) => item?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray'
                                    : 'btn--lightGray'
                            } pt-[15px]   justify-between`}
                        >
                            <span className="label--bold ">Locations</span>
                            {activeLocation?.some((classType) =>
                                locationsFilter
                                    ?.map?.((item) => item?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i
                                    className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                                />
                            ) : (
                                <i
                                    className={` cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16 ${
                                        show === 'locations'
                                            ? ' rotate-90 '
                                            : ' -rotate-90'
                                    } transform  font-700`}
                                />
                            )}
                        </div>

                        <Transition
                            show={show === 'locations'}
                            ref={ref}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 w-[324px] z-[4] absolute transform bg-gray  left-1/2 -translate-x-1/2 px-6 z-50">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16 group-hover:bg-hoverButtonDarkGray`}
                                />
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {locationsFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (
                                                        activeLocation?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveLocation([
                                                            ...activeLocation?.filter(
                                                                (i) =>
                                                                    i.slug !==
                                                                    item.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveLocation([
                                                            ...activeLocation,
                                                            item
                                                        ]);
                                                    }
                                                }}
                                                className={`flex items-center space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                        activeLocation?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                            ? 'bg-white'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <p className="label--bold">
                                                    {item?.name}{' '}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div
                        className={`flex flex-col lg:w-full xl:px-[2.6786%] px-3  relative`}
                        onClick={() =>
                            setShow(
                                show === 'specialities' ? '' : 'specialities'
                            )
                        }
                    >
                        <div
                            className={`flex items-center  btn ${
                                show === 'specialities' ||
                                activeSpeciality?.some((classType) =>
                                    specialityFilter
                                        ?.map?.((item) => item?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray'
                                    : 'btn--lightGray'
                            } pt-[15px]   justify-between `}
                        >
                            <span className="transition-all duration-300 label--bold ">
                                Specialities
                            </span>
                            {activeSpeciality?.some((classType) =>
                                specialityFilter
                                    ?.map?.((item) => item?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i
                                    className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                                ></i>
                            ) : (
                                <i
                                    className={`  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16${
                                        show === 'specialities'
                                            ? ' rotate-90 '
                                            : ' -rotate-90'
                                    } transform  font-700`}
                                ></i>
                            )}
                        </div>

                        <Transition
                            show={show === 'specialities'}
                            ref={ref1}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 w-[324px] z-[4] absolute transform bg-gray  left-1/2 -translate-x-1/2 px-6 z-50">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16`}
                                />
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {specialityFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        activeSpeciality?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveSpeciality([
                                                            ...activeSpeciality?.filter(
                                                                (i) =>
                                                                    i.slug !==
                                                                    item.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveSpeciality([
                                                            ...activeSpeciality,
                                                            item
                                                        ]);
                                                    }
                                                }}
                                                className={`flex items-center space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                        activeSpeciality?.findIndex(
                                                            (elem) =>
                                                                elem.slug ===
                                                                item?.slug
                                                        ) > -1
                                                            ? 'bg-white'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <p className="label--bold">
                                                    {item?.name}{' '}
                                                </p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
                {activeLocation?.concat?.(activeSpeciality)?.length > 0 ? (
                    <div className=" whitespace-normal px-[18.66666666666667%] mt-10 flex flex-wrap items-center ">
                        {activeLocation
                            ?.concat?.(activeSpeciality)
                            ?.map((item, index) => {
                                return (
                                    <div
                                        className={`btn btn--lightGray ml-2 mt-2 label--smallBold px-4  pr-[11.5px] pt-[7.5px] pb-[6.5px] flex items-center group cursor-default`}
                                        key={index}
                                    >
                                        <span className="">{item?.name} </span>
                                        {/* <span className="ml-3 opacity-70">
                                            {' '}
                                            {item?.count}
                                        </span> */}
                                        <i
                                            className={` cursor-pointer ml-3 cp-icon-modalClose text-10  leading-14 group-hover:bg-hoverButtonDarkGray`}
                                            onClick={() =>
                                                removeFilter(item.slug)
                                            }
                                        />
                                    </div>
                                );
                            })}
                        <div
                            className={`ml-6 text-black-100 text-opacity-70 lead--regular mt-3 cursor-pointer`}
                            key={'clear'}
                            onClick={() => cleanAllFilters()}
                        >
                            <span className="">Clear All </span>
                        </div>
                    </div>
                ) : null}
            </div>
            <div className="pt-12 lg:hidden ">
                <div
                    className={`flex mx-auto  items-center justify-center btn ${
                        activeLocation?.concat?.(activeSpeciality)?.length > 0
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    }    pt-[15px] w-min `}
                    onClick={() => {
                        setShowMobileFilter(true);
                    }}
                >
                    <i
                        className={`  cp-icon-Filter text-20 mb-1 mr-3 leading-16 transform  font-700`}
                    />
                    <span className="transition-all duration-300 label--bold ">
                        Filter
                    </span>
                    {activeLocation?.concat?.(activeSpeciality)?.length > 0 ? (
                        <span className="ml-3 transition-all duration-300 label--bold text-opacity-60 ">
                            {activeLocation?.concat?.(activeSpeciality)?.length}
                        </span>
                    ) : null}
                </div>
                <Transition
                    show={showMobileFilter}
                    ref={mobileRef}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="bg-[#1A1A1A] fixed top-0 h-screen z-100 w-[100vw]"
                        style={{ zIndex: 100 }}
                    >
                        <div className="flex flex-col">
                            <div className=" xl:hidden flex items-center justify-between w-full  h-[64px] max-h-[64px] border-b border-grid   ">
                                <div className="flex items-center justify-items-start w-full border-r border-grid   h-full min-w-[264px]">
                                    <h4 className="px-6">Filter</h4>
                                </div>
                                <div className="flex w-full items-center justify-center h-full min-w-[96px] max-w-[96px]">
                                    <div
                                        className="flex items-center justify-center w-12 h-12 my-2 bg-gray "
                                        style={{ borderRadius: '50%' }}
                                        onClick={() =>
                                            setShowMobileFilter(false)
                                        }
                                    >
                                        {showMobileFilter ? (
                                            <i className="text-white cp-icon-modalClose text-14"></i>
                                        ) : (
                                            <i className="text-white cp-icon-Menu text-16"></i>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <MobileFilter
                                closeMobileFilter={() =>
                                    setShowMobileFilter(false)
                                }
                                getActiveLocationFilters={(items) =>
                                    setActiveLocation(items)
                                }
                                getActiveSpecialityFilters={(items) =>
                                    setActiveSpeciality(items)
                                }
                                currentActiveLocation={activeLocation}
                                currentActiveSpeciality={activeSpeciality}
                                showMobileFilter={showMobileFilter}
                                locationsFilter={locationsFilter}
                                specialityFilter={specialityFilter}
                            />
                        </div>
                    </div>
                </Transition>
            </div>

            <div className="flex flex-col pt-8 lg:pt-16">
                <LevelCoachCards
                    levels={levels}
                    items={finalCoachesToDisplay}
                    type={'level-4'}
                />
                <LevelCoachCards
                    levels={levels}
                    items={finalCoachesToDisplay}
                    type={'level-3'}
                />
                <LevelCoachCards
                    levels={levels}
                    items={finalCoachesToDisplay}
                    type={'level-2'}
                />
                <LevelCoachCards
                    levels={levels}
                    items={finalCoachesToDisplay}
                    type={'level-1'}
                />
                {/* <LevelCoachCards
                    levels={[...levels,{slug:"none",name:"No Level"}]}
                    items={finalCoachesToDisplay}
                    type={'none'}
                /> */}
            </div>
        </div>
    );
};

export default Coaches;
