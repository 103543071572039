import  { useContext, useState, useEffect } from 'react';
import { ClassSchedule } from '../../components/chelsea/List/ClassSchedule'
import { LocationContext } from '../../util/LocationProvider'

const ACFClassSchedule = ({className='',anchor='',meta,data}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <ClassSchedule
                title={data?.title}
                className={className}
                anchor={anchor}
                manualClassTypes={meta?.ManualFilter}
                classTypes={meta?.classActivityTypes?.concat?.(meta?.classTypes)}
                classTimes={meta?.classTimeOfDays}
                coaches={meta?.coaches}
                locations={meta?.locations}
                classes={meta?.classes}
            />
        }
    </>);
}

export default ACFClassSchedule
