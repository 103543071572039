import  { useContext, useState, useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

function TwoColParagraph({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const { title, left_paragraph, right_paragraph } = data;

    return (<>
        {show &&
            <section className="bg-white  wrapper">
                <div className="px-6 lg:px-28">
                    <div className="relative z-[3] bg-white py-2">
                        {title && (
                            <h4
                                className="mb-6 text-black-100"
                                dangerouslySetInnerHTML={{ __html: title }}
                            ></h4>
                        )}
                        <div className="grid md:grid-cols-2 md:gap-12 gn-content">
                            {left_paragraph && (
                                <div
                                    className=" lead--large text-black-100"
                                    dangerouslySetInnerHTML={{
                                        __html: left_paragraph
                                    }}
                                />
                            )}
                            {right_paragraph && (
                                <div
                                    className=" lead--large text-black-100"
                                    dangerouslySetInnerHTML={{
                                        __html: right_paragraph
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            </section>
        }
    </>);
}

export default TwoColParagraph;
