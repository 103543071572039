import  { useContext, useState, useEffect } from 'react';
import { ContactCard } from '../../components/chelsea/ContactCard';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

function ACFContactCard({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let cards = extractRepeaterField(data, 'cards');

    cards = cards.map((repeaterItem) => ({
        ...repeaterItem,
        contact_cards: extractRepeaterField(repeaterItem, 'contact_cards').map(
            (item) => ({
                ...item,
                contact_image: meta?.images[item.contact_image] || {},
                phone_numbers: extractRepeaterField(item, 'phone_numbers') || {}
            })
        )
    }));

    return (<>
        {show &&
            <ContactCard 
                className={className} 
                anchor={anchor} 
                cards={cards} />
        }
    </>);
}

export default ACFContactCard;
