import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreColumn({ children, className, data=false }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }
    
    return (<>
        {show &&
            <div className={className}>
                {children}
            </div>
        }
    </>);
}

export default CoreColumn;
