import React from 'react';

const InfoCards = (props) => {
    return (<>
        <div className="ClassCards bg-white">
            <div className="xl:px-[3.333%] pl-3 ">
                <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
                    <div role="list" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 pr-9 pl-6 lg:px-6 gap-x-12 mt-12 lg:mt-[64px]">
                        {props?.cards?.map((card) => {
                            return (<>
                                <div key={card?.id} className="card flex flex-col pb-[66px] lg:pb-24">
                                        {card?.image &&
                                        <div className="rev-img-wrap relative z-[1]">
                                            <div className="rev-img">
                                                <div
                                                    className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                    style={{
                                                        // Adam image set
                                                        backgroundImage: `url(${card?.image?.mediaItemUrl})`
                                                    }}
                                                /> 
                                            </div>
                                        </div>
                                        }
                                        {/* <div className="aspect-w-3 aspect-h-2">
                                            {card?.image &&
                                                <img className="rounded-lg object-cover shadow-lg" src={card?.image?.mediaItemUrl} srcset={card?.image?.srcSet} alt={card?.image?.altText} /> 
                                            }
                                        </div> */}
                                        <div className="flex flex-col justify-end  z-[2]  mt-[-14%] lg:mt-[-13%]">
                                            <div className="flex flex-col max-w-[93%] lg:max-w-[83%] lg:self-start bg-white px-6 lg:pr-6 lg:pl-0 pt-6">
                                                <div className="text-black-100">
                                                {card?.minAge && 
                                                    <div className="eyebrow">
                                                        <div className="label--bold text-cobalt-100 mb-[14px]">AGES &nbsp;{card?.minAge}&nbsp;-&nbsp;{card?.maxAge}</div>
                                                    </div>
                                                }
                                                    {card?.title && <h3 className="title mb-[16px] text-[32px] leading-[40px]">{card?.title}</h3>}
                                                    {card?.description && <div className="blurb mb-[16px] text-[16px] leading-[24px]">{card?.description}</div>}
                                                    {card?.descriptionHtml && <div className="blurb mb-[16px] text-[16px] leading-[24px]"dangerouslySetInnerHTML={{ __html: card?.descriptionHtml }}></div>}
                                                    {card?.link && 
                                                        <div data-wow-offset="0" className="flex wow fadeInUp items-center space-x-4 relative border--main group cursor-pointer xl:pr-[50px] mt-[22.5px]  to-animate" animated="true">
                                                            <div className="border--increase--cobalt "></div>
                                                                <a className="pl-12  transition-all duration-300 label--bold text-black-100 group-hover:text-cobalt-100" target="" href={card?.link?.url}>
                                                                {card?.link?.title ? card?.link?.title : 'Schedules, Pricing + Enroll'}
                                                                </a>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                </div>                         
                            </>);
                        })}
                    </div>
                </div>
            </div>
        </div>
    </>);
};
export default InfoCards;