import { Transition } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../util/context';

const Schedule = (props) => {
    const [show, setShow] = useState(false);

    useEffect(() => {
        uniqueDaysCalculator();
    }, []);

    const context = useAppContext();

    useEffect(() => {
        if (context?.expandHours && props?.regularHours) {
            setShow(true);
        }
    }, [context?.expandHours]);
    
    function formatDayLabel(first, second){
        if (first==second){
            return capitalizeFirstLetter(first);
        }
        return capitalizeFirstLetter(first) + " – " + capitalizeFirstLetter(second);
    }
    
    let uniqueDaysCalculator = () => {
        let finalArray = [];
        props?.schedules?.map((item, index) => {
            let days = [
                'monday',
                'tuesday',
                'wednesday',
                'thursday',
                'friday',
                'saturday',
                'sunday'
            ];

            let array = [];
            var to, from, index;
            var count = 0;

            days?.map((day, subIndex) => {
                let object = {};
                if (item?.[day]?.to === to && item?.[day]?.from === from) {
                    array[index] = { ...array[index], lastDay: day };
                } else {
                    to = item?.[day]?.to;
                    from = item?.[day]?.from;
                    object.firstDay = day;
                    object.lastDay = day;
                    object.to = item?.[day]?.to;
                    object.from = item?.[day]?.from;
                    array.push(object);
                    index = count;
                    count++;
                }
            });
            let subFinalObject = {};
            subFinalObject.title = item?.title_field;
            subFinalObject.hours = array;
            finalArray.push(subFinalObject);
        });
        return finalArray;
    };

    function capitalizeFirstLetter(string) {
        return string?.charAt(0).toUpperCase() + string?.slice(1);
    }

    const formatDate = (d) => {
        var date = d.split('/');
        var f = new Date(date[2], date[1] - 1, date[0]);

        var options = { month: 'short', day: 'numeric', year: 'numeric' };
        return new Intl.DateTimeFormat('en-US', options).format(f);
    };

    return (
        <div
            className={`px-[6%] ${
                !props?.regularHours ? 'pb-16 lg:pb-[128px]' : ''
            } bg-white   ${props?.className}`}
            id={props?.anchor}
        >
            <Transition
                show={show}
                enter="transition duration-1000 ease-out"
                enterFrom=" scale-95 opacity-0"
                enterTo=" scale-100 opacity-100"
                leave="transition duration-75 ease-out"
                leaveFrom=" scale-100 opacity-1000"
                leaveTo=" scale-95 opacity-0"
            >
                <div
                    className={`${
                        props?.regularHours ? '' : 'pt-16 lg:pt-[129.5px]'
                    } pb-12 lg:pb-[128px] text-black-100`}
                >
                    <div className="flex flex-col space-y-12 lg:space-y-24">
                        <h3 className="text-center"> {props?.title} </h3>
                        <div className="grid lg:grid-cols-2 gap-6 lg:gap-24 z-[3]">
                            {props?.regularHours &&
                                uniqueDaysCalculator()?.map((item, index) => {
                                    return (
                                        <div
                                            className="bg-[#f7f7f7] w-full flex flex-col px-8 lg:px-14 py-4 pb-8 lg:pt-10 lg:pb-14 "
                                            key={index}
                                        >
                                            <div className="flex flex-col">
                                                <h5 className="py-4 border-b border-black-10 lg:py-6 wow fadeInUp">
                                                    {' '}
                                                    {item?.title}
                                                </h5>
                                                <div className="flex flex-col lead-regular">
                                                    {item?.hours?.map(
                                                        (hour, subIndex) => {
                                                            return (
                                                                <div
                                                                    className="flex flex-col justify-between py-4 space-y-2 border-b wow fadeInUp lg:space-y-0 lg:flex-row lg:items-center lg:py-6 border-black-10"
                                                                    key={
                                                                        subIndex
                                                                    }
                                                                >
                                                                    {hour?.firstDay ===
                                                                        'saturday' &&
                                                                    hour?.lastDay ===
                                                                        'sunday' ? (
                                                                        <p className="font-500">
                                                                            Weekends
                                                                        </p>
                                                                    ) : (
                                                                        <p className="font-500">
                                                                            {formatDayLabel(hour?.firstDay, hour?.lastDay)}
                                                                        </p>
                                                                    )}
                                                                    <p className="lowercase">
                                                                        {' '}
                                                                        {
                                                                            hour?.from
                                                                        }{' '}
                                                                        &ndash;
                                                                        {' ' +
                                                                            hour?.to}
                                                                    </p>
                                                                </div>
                                                            );
                                                        }
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    );
                                })}
                            {!props?.regularHours &&
                                props?.schedules?.map((item, index) => {
                                    if (
                                        item['special days'] &&
                                        item?.['special days']?.length > 0
                                    ) {
                                        return (
                                            <div
                                                className="bg-[#f7f7f7] w-full  wow fadeInUp flex flex-col px-8 lg:px-14 py-4 pb-8 lg:pt-10 lg:pb-14 "
                                                key={index}
                                            >
                                                <div className="flex flex-col">
                                                    <h5 className="py-4 border-b border-black-10 lg:py-6 wow fadeInUp">
                                                        {' '}
                                                        {
                                                            item?.[
                                                                `holiday title`
                                                            ]
                                                        }
                                                    </h5>
                                                    <div className="flex flex-col lead-regular">
                                                        {item['special days'] &&
                                                            item?.[
                                                                'special days'
                                                            ]?.map(
                                                                (
                                                                    hour,
                                                                    subIndex
                                                                ) => {
                                                                    return (
                                                                        <div
                                                                            className="flex flex-col justify-between py-4 space-y-2 border-b lg:space-y-0 lg:flex-row lg:items-center lg:py-6 border-black-10"
                                                                            key={
                                                                                subIndex
                                                                            }
                                                                        >
                                                                            <div className="font-500">
                                                                                {
                                                                                    hour?.additional_date_string
                                                                                }
                                                                                {hour?.date_trigger ? (
                                                                                    <span>
                                                                                        {' '}
                                                                                        &ndash;{' '}
                                                                                        {formatDate(
                                                                                            hour?.date_trigger
                                                                                        )}
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                            <div className="">
                                                                                {' '}
                                                                                {hour?.from ===
                                                                                'Closed'
                                                                                    ? capitalizeFirstLetter(
                                                                                          hour?.from
                                                                                      )
                                                                                    : hour?.from?.toLowerCase()}{' '}
                                                                                {hour?.to ? (
                                                                                    <span>
                                                                                        {' '}
                                                                                        &ndash;{' '}
                                                                                        {hour?.to?.toLowerCase()}
                                                                                    </span>
                                                                                ) : null}
                                                                            </div>
                                                                        </div>
                                                                    );
                                                                }
                                                            )}
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    }
                                })}
                        </div>
                    </div>
                </div>
            </Transition>
            <div className={`flex  items-center  justify-center `}>
                <div
                    className={`label--bold btn btn--black flex ${
                        !props?.regularHours && !show ? 'mt-6 lg:mt-12' : ''
                    }  items-center justify-between max-w-[224px]  pt-[15px] pb-[13px]  w-full`}
                    onClick={() => {
                        if (props?.regularHours) {
                            setShow(!show);
                            context?.toggleExpand(false);
                        } else {
                            setShow(!show);
                        }
                    }}
                >
                    <span className="">{props?.openButtonText}</span>
                    <i
                        className={` cp-icon-arraySmallLeft text-9 leading-12 lg:mb-1 ${
                            show ? ' rotate-90 ' : ' -rotate-90'
                        } transform  font-700`}
                    ></i>
                </div>
            </div>
        </div>
    );
};

export default Schedule;
