import React, { useState } from 'react';
import { useAppContext } from '../../../util/context';

const OfferNoticeBar = (props) => {

    const offerId = props?.data?.offer;
    const [offer, setOffer] = useState(() => {
        let _offer = {};
        props?.offers?.forEach((o) => {
            if (o?.data?.notice?.databaseId == offerId) {
                _offer = o?.data?.notice?.acf_offer;
            }
        });
        return _offer;
    });
    const context = useAppContext();
    return (<>
        <div 
            component="OfferNoticeBar"
            class="flex md:flex-row flex-col md:items-center items-start px-8 justify-center w-full border-b border-grid space-y-[18.5px] md:space-y-0 bg-black-100 md:px-3 h-[132px] md:h-[48px] md:space-x-4 wow fadeInUp  to-animate" 
            data-wow-offset="0" 
            style={{
                visibility: 'visible',
                animationName: 'fadeInUp',
            }}
            animated="true"
        >
            {offer?.offerText &&
                <div class="lead--regular text-white">{offer?.offerText}</div>
            }
            {offer?.link?.url &&
                <div 
                    data-wow-offset="0" 
                    class="flex wow fadeInUp items-center space-x-4 relative border--main group cursor-pointer xl:pr-[50px] undefined  to-animate" 
                    style={{
                        visibility: 'visible',
                        animationName: 'fadeInUp',
                    }}
                    animated="true"
                >
                    <div class="border--increase"></div>
                        {offer?.link?.url && 
                            <a href={offer?.link?.url} 
                                onClick={() => context?.toggleExpand(true)} 
                                class="pl-12  transition-all duration-300 label--bold text-white" target={offer?.link?.target}>
                                {offer?.link?.title}
                            </a>
                        } 

                        {/* {offer?.jumpLink?.url && 
                            <a href={offer?.jumpLink?.url} 
                                onClick={() => context?.toggleExpand(true)} 
                                class="pl-12  transition-all duration-300 label--bold text-white" target="">
                                {offer?.jumpLink?.title}
                            </a>
                        }  */}
                </div>
            }
        </div>
    </>);
};
export default OfferNoticeBar;