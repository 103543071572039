import React, { useState } from 'react';

const ClassCards = (props) => {
    const [opened, setOpened] = useState(-1);

    const getCards = (fields, meta) => {
        let cards = [];
        for (let i=0; i<fields?.cards; i++) {
            let imageId = fields[`cards_${i}_image`];

            cards.push({
                blurb:       fields[`cards_${i}_blurb`],
                description: fields[`cards_${i}_description`],
                html:        fields[`cards_${i}_html`],
                eyebrow:     fields[`cards_${i}_eyebrow`],
                link:        fields[`cards_${i}_link`],
                overlay:     fields[`cards_${i}_overlay`],
                title:       fields[`cards_${i}_title`],
                text:        fields[`cards_${i}_text`],
                image:       meta?.images?.[imageId],
            });
        }
        return cards;
    };

    const toggleMoreInfo = (index) => {
        if (opened == index) {
            setOpened(-1);
        } else setOpened(index);
    };


    return (<>
        <div className="ClassCards bg-white">
            <div className="xl:px-[3.333%] pl-3 ">
                <div className="mx-auto py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
                    <div role="list" className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pr-9 pl-6 lg:px-6 gap-x-12 mt-12 lg:mt-[64px]">

                        {getCards(props?.data, props?.meta).map((card, index) => {
                            return (<>
                                <div key={`class-card-${index}`} className="card flex flex-col pb-[66px] lg:pb-24">
                                    <div className="space-y-4">
                                        <div className="aspect-w-3 aspect-h-2">
                                            {card?.image &&
                                                <img className="rounded-lg object-cover shadow-lg" src={card?.image?.src} srcset={card?.image?.srcset} alt={card?.title} /> 
                                            }
                                            {card?.overlay && 
                                                <div className="info-overlay">
                                                    <span className="overlay absolute label--bold">{card.overlay}</span>
                                                </div>
                                            }
                                        </div>

                                        <div className="space-y-2">
                                            <div className="text-[black]">
                                                {card?.eyebrow &&
                                                    <div className="eyebrow mb-[12px] label--bold text-cobalt-100">{card.eyebrow}</div>
                                                }

                                                {card?.title &&
                                                    <h3 className="title mb-[16px] text-[32px] leading-[40px]">{card.title}</h3>
                                                }

                                                {card?.blurb &&
                                                    <div className="blurb mb-[16px] text-[16px] leading-[24px]">{card.blurb}</div>
                                                }

                                                {(opened==index && card?.description) ? 
                                                    <div className="hidden-text text-[16px] leading-[24px] mb-[32px]">{card.description}</div> 
                                                    
                                                : null}
                                                {(opened==index && card?.html) ? 
                                                    <div className="hidden-text text-[16px] leading-[24px] mb-[32px]"
                                                    dangerouslySetInnerHTML={{__html: ` ${card.html} `}}
                                                    >  
                                                    </div> 
                                                : null}
                                                {card?.link && 
                                                    <a href={card.link} className="inline-block max-w-[100px] mr-[-6px] w-full h-full items-center label--bold btn btn--cobalt max-h-12"
                                                    dangerouslySetInnerHTML={{__html: ` ${card.text} `}}>
                                                    </a>
                                                }

                                                {card?.description &&
                                                    <span className="inline-block ml-[40px]">
                                                        <button onClick={() => toggleMoreInfo(index)}>
                                                            {(opened!=index) && "More"}{(opened==index) && "Less"} Info
                                                            <i className="transform ml-[15px] inline-block rotate-270 cp-icon-arraySmallLeft text-9 leading-16 font-700"></i>
                                                        </button>
                                                    </span>
                                                }
                                                {card?.html &&
                                                    <span className="inline-block ml-[40px]">
                                                        <button onClick={() => toggleMoreInfo(index)}>
                                                            {(opened!=index) && "More"}{(opened==index) && "Less"} Info
                                                            <i className="transform ml-[15px] inline-block rotate-270 cp-icon-arraySmallLeft text-9 leading-16 font-700"></i>
                                                        </button>
                                                    </span>
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                </div>                            
                            </>);
                        })}

            
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default ClassCards;