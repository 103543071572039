import  { useContext, useState, useEffect } from 'react';
import CoachesSports from '../../components/chelsea/List/CoachesSports'
import { LocationContext } from '../../util/LocationProvider'

const ACFCoachesSportsListing = ({data,meta,pageProps,className,anchor}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <CoachesSports 
                data={data} 
                meta={meta}
                coaches={pageProps?.componentQueries?.coaches?.data?.sportscoaches?.edges}
            />
        }
    </>);
}

export default ACFCoachesSportsListing
