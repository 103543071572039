import  { useContext, useState, useEffect } from 'react';
import Locations from '../../components/chelsea/Locations/Locations'
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

const ACFLocations = ({anchor='', className='',data,meta }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let locations = extractRepeaterField(data,'locations');

    locations = locations?.map((repeaterItem) => ({
        ctas:extractRepeaterField(repeaterItem,'ctas'),
        post: meta?.locations[repeaterItem?.location] || {},
    }));

    return (<>
        {show &&
            <Locations  
                className={className}
                anchor={anchor}
                locations={locations}/>
        }
    </>);
}

export default ACFLocations
