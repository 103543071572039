import  { useContext, useState, useEffect } from 'react';
import PartnerCarousel from '../../components/chelsea/Carousel/PartnerCarousel';
import { LocationContext } from '../../util/LocationProvider';

const ACFPartnerCarousel = ({data, meta, anchor='', className=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <PartnerCarousel  
                className={className}
                anchor={anchor}
                items={meta?.partners} 
                top_heading={data?.title} />
        }
    </>)
}

export default ACFPartnerCarousel
