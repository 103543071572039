import React from 'react'

export const Devices = ({devices,className,anchor}) => {
    return (
        <div className={`wrapper pt-[52px] xl:pt-[106px] grid grid-cols-2 gap-y-8 xl:grid-cols-6 place-items-center bg-white ${className}`} id={anchor}>
            {
                devices?.map((item,index) => {
                    return(
                        <img src={item?.image?.src} alt={item?.image?.alt}/>
                    )
                })
            }
        </div>
    )
}
