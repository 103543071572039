import React, { useContext, useEffect, useState } from 'react';
import InfoCards from '../ClassCards/InfoCards';
import { useRouter } from 'next/router';

import { LocationContext } from '../../../util/LocationProvider';

const SummerCampsSearch = (props) => {
    let LocationManager = useContext(LocationContext);
    let locale = LocationManager.getLocation();

    const router = useRouter();
    
    let camps = props?.camps; 

    const [hiddenSport, setHiddenSport] = useState(true);
    const [optionsSport, setOptionsSport] = useState([]);
    const [filterSport, setFilterSport] = useState([]);

    const [hiddenAge, setHiddenAge] = useState(true);
    const [filterAge, setFilterAge] = useState([]);
    const [optionsAge, setOptionsAge] = useState([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]);

    const [hiddenWeek, setHiddenWeek] = useState(true);
    const [filterWeek, setFilterWeek] = useState([]);
    const [optionsWeek, setOptionsWeek] = useState([]);

    const [filteredCards, setFilteredCards] = useState([]);
    
    const csvArray = (str) => {
        if (str.includes(',')) {
            return str.split(',');
        } else {
            return [str];
        }
    }

    useEffect(() => { 
        filterCards(); 
    }, [
        filterSport,
        filterAge,
        filterWeek,
    ]);

    const filterCards = () => {
        let cards  = [];
        let sports = [];
        let ages = [];
        let show = false;

        for (let i=0; i<camps?.length; i++) {
            let camp = camps[i]?.node?.acf_summer_camps;

            // Filter out cards not associated with the current set locale.
            show = false;
            camp?.locale?.forEach((cardLocale) => {
                if (locale == cardLocale) {
                    show = true;
                }
            });
            if (!show) continue;

            camp?.sports?.forEach((sport) => { // Collect sports in dataset to set default filter options before other filters are applied.
                if (!sports.includes(sport?.title)) {
                    sports.push(sport?.title);
                }
            });

            // Collect ages in dataset to set default filter options before filters are applied.
            for (let j=parseInt(camp?.minAge); j<=parseInt(camp?.maxAge); j++) {
                if (!ages.includes(j)) {
                    ages.push(j);
                }
            }

            if (filterSport?.length) { // If Sports filter is active, match camps against filters.
                show = false;
                camp?.sports?.forEach((sport) => { 
                    if (filterSport.includes(sport?.title)) {
                        show = true;
                    }
                });
                if (!show) continue;
            }

            if (filterWeek?.length) { // If weeks filter is active, match camps against filters.
                show = false;
                camp?.weeks?.forEach((weekNumber) => { 
                    if (filterWeek?.includes(parseInt(weekNumber))) {
                        show = true;
                    }
                });
                if (!show) continue;
            }

            if (filterAge?.length) { // If ages filter is active, match age against filters.
                show = false;
                for (let j=parseInt(camp?.minAge); j<=parseInt(camp?.maxAge); j++) {
                    if (filterAge.includes(j)) {
                        show = true;
                    }
                }
                if (!show) continue;
            }

            cards.push(camp);
        }

        setFilteredCards(cards);
        setOptionsAge(ages.sort((a,b) => {
            if (a<b) return -1;
            if (a>b) return  1;
            return 0;
        }));

        // Initialize Sports Options once.
        if (!optionsSport?.length) {
            setOptionsSport(sports.sort());
        }

        // Initialize Week Options once.
        if (!optionsWeek?.length) {
            let weeks = [];
            cards[0]?.season?.acf_season_fields?.campDates?.forEach((week) => {
                // Is this date set for the current location?
                if (!week?.locale?.includes(locale)) return;
                week?.weeks?.forEach((weekRange) => {
                    weeks.push(`Week ${weekRange?.week}: ${weekRange?.startDay} - ${weekRange?.endDay}`);
                });
            });
            setOptionsWeek(weeks);
        }

    };
    
    useEffect(() => { 
        // Age is set, and the value exists as a filter option.
        if (router?.query?.age) {
            let ages = csvArray(router?.query?.age);
            let agesFilters = [...filterAge];
            ages.forEach((age) => { 
                if (age != '18+') {
                    age = parseInt(age);
                }
                // Is this a valid option? Not already set?
                if (optionsAge.includes(age) && !filterAge.includes(age)) { 
                    agesFilters.push(age); 
                }
            });
            setFilterAge(agesFilters);
        }
    }, [router.query, csvArray]);
    
    const toggleAge = (age, e) => {
        let ages = [...filterAge];
        if (!ages.includes(age)) {
            ages.push(age);
            e.currentTarget.classList.add('active');
        } else {
            ages = ages.filter(a => a !== age);
            e.currentTarget.classList.remove('active');
        }
        setFilterAge(ages);
    };


    const toggleWeek = (week, e) => {
        let weeks = [...filterWeek];
        if (!weeks.includes(week)) {
            weeks.push(week);
            e.currentTarget.classList.add('active');
        } else {
            weeks = weeks.filter(w => w !== week);
            e.currentTarget.classList.remove('active');
        }
        setFilterWeek(weeks);
    };


    const toggleSport = (sport, e) => {
        let sportFilter = [...filterSport];
        if (!sportFilter.includes(sport)) {
            sportFilter.push(sport);
            e.currentTarget.classList.add('active');
        } else {
            sportFilter = sportFilter.filter(d => d !== sport);
            e.currentTarget.classList.remove('active');
        }
        setFilterSport(sportFilter);
    };


    const isFilterActive = (filter, value) => {
        let filterCheck = false;

        if (Array.isArray(filter)) {
            filterCheck = (filter.includes(value) ? 'active' : '');
        } else {
            filterCheck = (filter==value ? 'active': '');
        }
        return filterCheck;
    };
    return (<>
        <div component="ClassesDate" className="xl:px-[3.333%] wow fadeInUp bg-white  to-animate" id="" animated="true" >
            <div className="flex-col pt-16 lg:flex wow fadeInUp  to-animate"  animated="true">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">

                    {/* SPORTS Dropdown */}
                    <div className="flex flex-col lg:w-full xl:px-[1.3393%] px-3  relative w-[150px]">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenSport(false)}>
                            <span className="transition-all duration-100 label--bold ">Sport</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16  -rotate-90 transform   font-700"></i>
                        </div>
                        {!hiddenSport ? 
                            <div 
                                onMouseLeave={() => setHiddenSport(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i onClick={() => setHiddenSport(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {optionsSport.map((sport) => { 
                                        if (sport == 'N/A') return (<></>); // Skip N/A

                                        return (<>
                                            <div 
                                                key={sport}
                                                onClick={(e) => toggleSport(sport, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterSport, sport)}>
                                                <div className="check w-4 mb-[2px]  h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{sport}</p>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>

                    {/* AGE Dropdown */}
                    <div className="flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative w-[150px]">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenAge(false)}>
                            <span className="transition-all duration-100 label--bold ">Age</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenAge ? 
                            <div 
                                onMouseLeave={() => setHiddenAge(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenAge(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsAge.map((age) => {
                                        return (
                                            <div 
                                                key={age}
                                                onClick={(e) => toggleAge(age, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterAge, age)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{age}</p>
                                    </div>
                                        );
                                    })}

                                </div>
                            </div>
                        : null}
                    </div>


                    {/* WEEKS Dropdown */}
                    <div className="flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative w-[150px]">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenWeek(false)}>
                            <span className="transition-all duration-100 label--bold ">Week</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenWeek ? 
                            <div 
                                onMouseLeave={() => setHiddenWeek(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenWeek(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsWeek.map((week, index) => {
                                        let weekNumber = index + 1;
                                        return (
                                            <div 
                                                key={`week-${index}`}
                                                onClick={(e) => toggleWeek(weekNumber, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterWeek, weekNumber)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{week}</p>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        : null}
                    </div>

                </div>
            </div>

            <InfoCards cards={filteredCards} />

        </div>
    </>);

};

export default SummerCampsSearch;