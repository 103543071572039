import { useContext, useState, useEffect } from 'react';
import CenterPageCTA from '../../components/chelsea/Misc/CenterPageCTA'
import { LocationContext } from '../../util/LocationProvider'

const ACFCenterPageCTA = ({data,className,anchor}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <CenterPageCTA 
                color={data?.style_options_color} 
                cta={data?.cta}   
                className={className} 
                anchor={anchor} />
        }
    </>);
}

export default ACFCenterPageCTA
