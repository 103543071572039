import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';
import { LineCTA } from '../CTA/LineCTA';

const FeaturingBlock = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    // props?.flipColumns = 0 or 1

    return (
        <div
            className={`wrapper bg-white ${props?.className}`}
            id={props?.anchor}
        >
            <div className="grid grid-cols-1 lg:grid-cols-2 pt-[64px] lg:pt-[128px] ">
                <div className="flex flex-col px-6 lg:px-28">
                    {/* This needs to be removed from template and WP */}
                    {/* <img
                        className="w-[100px] h-[100px]"
                            src={props?.icon?.src}
                    /> */}
                    <p
                        className="label--largeBold text-cobalt-100 wow fadeInUp"
                        data-wow-delay="0.1s"
                    >
                        {' '}
                        {props?.subHeading}
                    </p>
                    <h2
                        className="mt-4 text-black-100 lg:mt-6 wow fadeInUp"
                        data-wow-delay="0.3s"
                    >
                        {' '}
                        {props?.heading}
                    </h2>
                    <div
                        data-wow-delay="0.6s"
                        className="mt-5 lead--large text-black-100 lg:mt-8 wow fadeInUp"
                        dangerouslySetInnerHTML={{ __html: props?.content }}
                    />
                    {/* This will probably need to be removed from here and WP */}
                    {/* This will need to be hooked into data Button WP */}
                    {/* <a 
                        className="btn btn--cobalt label--bold justify-self-center self-center lg:self-start lg:label--largeBold w-[200px] mt-[20px] pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px]"
                        href="#" target="">
                            HC Tryout
                    </a> */}
                    {/* Data might not be working correctly */}
                    {props?.cta?.url &&
                        <NextNavLink
                            href={props?.cta?.url}
                            target={props?.cta?.target}
                        >
                            <a className="btn btn--cobalt label--bold w-[200px] my-[40px] justify-self-center self-center lg:self-start lg:label--largeBold my-[15px] pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px] ">
                                {props?.cta?.title}
                            </a>
                        </NextNavLink>
                    }
                </div>
                {/* Image Left */}
                { props?.flipColumns && (
                    <div className="flex wow flex-col pt-[64px] overflow-hidden lg:pt-0 group mainCardWrapper cursor-pointer !visible">
                        <div className="relative flex flex-col ">
                            {/* <img
                                src={
                                    actualWidth > 1024
                                        ? props?.desktopImage?.src
                                        : props?.mobileImage?.src
                                }
                            />
                        */}
                            <div className="rev-img-wrap relative z-[1]">
                                <div className="rev-img">
                                    <div
                                        style={{
                                            backgroundImage: `url(${
                                                actualWidth > 1024
                                                    ? props?.desktopImage?.src
                                                    : props?.mobileImage?.src
                                            })`
                                        }}
                                        className="w-full  aspect-w-3 aspect-h-2 bg-cover bg-no-repeat bg-center"
                                    />
                                </div>
                            </div>
                            <div className="absolute top-0 left-0 w-full h-full rev-bg bg-black-20"></div>{' '}
                            <div className="absolute top-0 left-0 w-full h-full transition-all duration-300 group-hover:bg-black-20 z-[1]"></div>
                        </div>
                        <div className="flex flex-col justify-end z-[2] mt-[-14%] lg:mt-[-10%] ">
                            <div className="flex flex-col max-w-[93%] lg:max-w-[83%] lg:self-end bg-white px-6 pt-6">
                                <p
                                    className="label--bold text-cobalt-100 wow fadeInUp"
                                    data-wow-delay="0.1s"
                                >
                                    {' '}
                                    {props?.cardSubTitle}{' '}
                                </p>
                                <h4
                                    data-wow-delay="0.3s"
                                    className="text-black-100 group-hover:text-cobalt-100 transition-all duration-300 mt-[14px] mt-4 lg:mt-3  wow fadeInUp"
                                >
                                    {' '}
                                    {props?.postTitle}
                                </h4>
                                <div data-wow-delay="0.5s" className="wow fadeInUp">
                                    <div
                                        className="mt-4 lead--regular text-black-100 "
                                        dangerouslySetInnerHTML={{
                                            __html: props?.cardContent
                                        }}
                                    />
                                    {props?.cardCta?.url &&
                                        <LineCTA
                                            target={props?.cardCta?.target}
                                            title={props?.cardCta?.title}
                                            href={props?.cardCta?.url}
                                            marginClass={'mt-[22.5px]'}
                                            borderClass={'border--increase--cobalt'}
                                            textClassName={
                                                'text-black-100 group-hover:text-cobalt-100  '
                                            }
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {/* END Image Left */}
                {/* Image Right */}
                { !props?.flipColumns && (
                    <div className="flex wow flex-col pt-[64px] overflow-hidden lg:pt-0 group mainCardWrapper cursor-pointer !visible">

                        <div className="flex flex-col justify-end z-[2] mt-[-14%] lg:mt-[-10%] ">
                            <div className="flex flex-col max-w-[93%] lg:max-w-[83%] lg:self-end bg-white px-6 pt-6">
                                <p
                                    className="label--bold text-cobalt-100 wow fadeInUp"
                                    data-wow-delay="0.1s"
                                >
                                    {' '}
                                    {props?.cardSubTitle}{' '}
                                </p>
                                <h4
                                    data-wow-delay="0.3s"
                                    className="text-black-100 group-hover:text-cobalt-100 transition-all duration-300 mt-[14px] mt-4 lg:mt-3  wow fadeInUp"
                                >
                                    {' '}
                                    {props?.postTitle}
                                </h4>
                                <div data-wow-delay="0.5s" className="wow fadeInUp">
                                    <div
                                        className="mt-4 lead--regular text-black-100 "
                                        dangerouslySetInnerHTML={{
                                            __html: props?.cardContent
                                        }}
                                    />
                                    {props?.cta?.url &&
                                        <LineCTA
                                            target={props?.cta?.target}
                                            title={props?.cta?.title}
                                            href={props?.cta?.url}
                                            marginClass={'mt-[22.5px]'}
                                            borderClass={'border--increase--cobalt'}
                                            textClassName={
                                                'text-black-100 group-hover:text-cobalt-100  '
                                            }
                                        />
                                    }
                                </div>
                            </div>
                            <div className="relative flex flex-col ">
                                {/* <img
                                    src={
                                        actualWidth > 1024
                                            ? props?.desktopImage?.src
                                            : props?.mobileImage?.src
                                    }
                                    />
                                */}
                                <div className="rev-img-wrap relative z-[1]">
                                    <div className="rev-img">
                                        <div
                                            style={{
                                                backgroundImage: `url(${
                                                    actualWidth > 1024
                                                        ? props?.desktopImage?.src
                                                        : props?.mobileImage?.src
                                                })`
                                            }}
                                            className="w-full  aspect-w-3 aspect-h-2 bg-cover bg-no-repeat bg-center"
                                        />
                                    </div>
                                </div>
                                <div className="absolute top-0 left-0 w-full h-full rev-bg bg-black-20"></div>{' '}
                                <div className="absolute top-0 left-0 w-full h-full transition-all duration-300 group-hover:bg-black-20 z-[1]"></div>
                            </div>
                        </div>
                    </div>
                )}
                {/* END Image Right */}
            </div>
        </div>
    );
};

export default FeaturingBlock;