import  { useContext, useState, useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { Transition } from '@headlessui/react';
import { LocationContext } from '../../util/LocationProvider';

function FaqAccordion({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const { title } = data;
    let lists = extractRepeaterField(data, 'accordion');
    const [disclosureState, setDisclosureState] = useState(false);

    const toggle = (index) => {
        if (disclosureState === index) {
            return setDisclosureState(null);
        }
        setDisclosureState(index);
    };
    
    return (<>
        {show &&
            <section className="py-0 bg-white wrapper">
                <div className="px-6 lg:px-28">
                    <div className="relative z-[3] bg-white">
                        {title && (
                            <p
                                className="pt-6 pb-4 label--bold lg:pt-0 text-cobalt-100"
                                dangerouslySetInnerHTML={{ __html: title }}
                            />
                        )}
                        <div className="grid bg-white relative z-[3] ">
                            {lists &&
                                lists.map((item, index) => (
                                    <div
                                        className="border-black-100 last:border-b border-opacity-10 wow fadeInUp text-black-100"
                                        key={index}
                                    >
                                        <div
                                            className="flex items-center py-4 border-t cursor-pointer border-black-100 lead--large text-black-100 border-opacity-10 "
                                            onClick={() => {
                                                toggle('col1' + index);
                                            }}
                                        >
                                            <span
                                                className={`flex flex-1 lead lead--large ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? 'font-700'
                                                        : ''
                                                }`}
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.question
                                                }}
                                            />
                                            <i
                                                className={`cp-icon-arraySmallLeft   inline-block transform text-14 ${
                                                    disclosureState ===
                                                    'col1' + index
                                                        ? 'rotate-90'
                                                        : '-rotate-90'
                                                }`}
                                            ></i>
                                        </div>
                                        <Transition
                                            show={
                                                disclosureState ===
                                                'col1' + index
                                            }
                                            className=""
                                        >
                                            <div
                                                className="mb-4 lead--regular text-black-100"
                                                dangerouslySetInnerHTML={{
                                                    __html: item?.answer
                                                }}
                                            />
                                        </Transition>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>
            </section>
        }
    </>);
}

export default FaqAccordion;
