import React from 'react';
import NextNavLink from '../../util/NextNavLink';

const BackToTop = (props) => {
    return (
        <div
        id={props?.anchor}
            className={`wrapper bg-white pb-12  lg:pb-24 flex items-center justify-center ${props?.className}`}
        >
            <NextNavLink href={`#${props?.actualAnchor}`}>
                <a className="flex items-center justify-center p-3 relative z-[3] sm:p-5 bg-[#f3f3f3] rounded-[50%]">
                    <span
                        className={`  cp-icon-arraySmallLeft text-12  text-black-100 rotate-90 transform `}
                    >
                        {props?.text}
                    </span>
                </a>
            </NextNavLink>
        </div>
    );
};

export default BackToTop;
