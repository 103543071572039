import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import NextNavLink from '../../util/NextNavLink';

const CommunityPartners = (props) => {
    const [show, setShow] = useState('');

    const getFirstParagraph = (props) => {
        const paragraphs = props?.about?.split('</p>');
        const firstParagraph = paragraphs?.[0] + '</p>';
        let cleanText = firstParagraph?.replace(/<\/?[^>]+(>|$)/g, '');
        let finalText = cleanText.split('.');
        return (
            finalText?.[0] + finalText?.[1] + finalText?.[2] + finalText?.[3]
        );
    };

    return (
        <div
            className={`wrapper pt-16 lg:pt-[128px] bg-white ${props?.className}`}
            id={props?.anchor}
        >
            <div className="grid grid-cols-1 px-6 md:grid-cols-2 lg:grid-cols-3 gap-x-12">
                {props?.items?.map((item, index) => {
                    return (
                        <div
                            className="flex flex-col pb-12 lg:pb-24 wow fadeInUp"
                            key={index}
                        >
                            <div className=" flex items-center  justify-center relative py-[45px]  w-full  wow fadeInUp">
                                <img className="" src={item?.thumbnail} />
                                <div className="absolute left-0 top-0 w-full h-full bg-black-100 opacity-[0.04] z-[9]"></div>
                            </div>
                            <h4 className="mt-4 lg:mt-6 text-black-100 wow fadeInUp">
                                {' '}
                                {item?.title}{' '}
                            </h4>
                            {show !== index ? (
                                <p className="mt-3 lg:mt-5 label--bold text-cobalt-100 wow fadeInUp">
                                    {' '}
                                    {item?.sub_title}
                                </p>
                            ) : item?.sub_title?.toLowerCase()?.trim() ===
                              'member offer' ? (
                                <p className="mt-3 lg:mt-5 label--bold text-cobalt-100 wow fadeInUp">
                                    {' '}
                                    {item?.sub_title}
                                </p>
                            ) : (
                                ''
                            )}

                            {item?.offer ? (
                                <div
                                    className="mt-2 lead--regular text-black-100 wow fadeInUp"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.offer
                                    }}
                                />
                            ) : show !== index ? (
                                <p className="mt-2 text-black-100 text-opacity-70 wow fadeInUp">
                                    {' '}
                                    {getFirstParagraph(item)}{' '}
                                </p>
                            ) : null}

                            <Transition
                                show={show === index}
                                enter="transition duration-1000 ease-out"
                                enterFrom=" scale-95 opacity-0"
                                enterTo=" scale-100 opacity-100"
                                leave="transition duration-75 ease-out"
                                leaveFrom=" scale-100 opacity-1000"
                                leaveTo=" scale-95 opacity-0"
                            >
                                <p className="mt-5 text-black-100 text-opacity-70 label--bold">
                                    {' '}
                                    About Partner{' '}
                                </p>
                                <div
                                    className="flex flex-col mt-2 lead--regular space-y-2 text-black-100 text-opacity-70"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.about
                                    }}
                                />
                                {item?.note ? (
                                            <hr className="max-w-[48px] text-black-30 my-5" />
                                        ) : null}
                                <div
                                    className="flex lead--small flex-col mt-2 space-y-2 text-black-100 text-opacity-70"
                                    dangerouslySetInnerHTML={{
                                        __html: item?.note
                                    }}
                                />
                            </Transition>

                            <div className="flex mt-3 space-x-3 lg:mt-7 sm:space-x-6 wow fadeInUp">
                                <NextNavLink
                                    href={item?.cta?.url}
                                    target={item?.cta?.target}
                                >
                                    <a className="label--smallBold sm:label--bold btn btn--cobalt text-white max-w-[150px] pt-[15px] pb-[13px] lg:max-w-[150px] w-full h-full">
                                        {item?.cta?.title}
                                    </a>
                                </NextNavLink>
                                <div
                                    className={`label--smallBold sm:label--bold btn btn--white flex items-center justify-between max-w-[147px]  pt-[15px] pb-[13px]  w-full`}
                                    onClick={() =>
                                        setShow(show === index ? '' : index)
                                    }
                                >
                                    <span className="">More info</span>
                                    <i
                                        className={`  cp-icon-arraySmallLeft text-9 leading-16 ${
                                            show === index
                                                ? ' rotate-90 '
                                                : ' -rotate-90 mb-1'
                                        } transform  font-700`}
                                    ></i>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default CommunityPartners;
