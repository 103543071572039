import  { useContext, useState, useEffect } from 'react';
import TitleCTA from '../../components/chelsea/CTA/TitleCTA'
import { extractRepeaterField } from '../../util/blocks'
import { LocationContext } from '../../util/LocationProvider'

export const ACFTitleCTA = ({data,meta, className='', anchor=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
    }, [locale, setShow, LocationManager, data]);

    const ctas = extractRepeaterField(filtered,'ctas')

    return (<>
        {show && 
            <TitleCTA 
                className={className} 
                anchor={anchor} 
                title={filtered?.bit_title} 
                rightTitle={filtered?.title} 
                content={filtered?.content} 
                subTitle={filtered?.sub_title} 
                ctas={ctas} 
                mobile_image={meta?.images?.[filtered?.mobile_image]} 
                desktop_image={meta?.images?.[filtered?.desktop_image]} />
        }   
    </>);
}
