import  { useContext, useState, useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

function ContactHero({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const { title, description, email, phone_number } = data;

    return (<>
        {show &&
            <section className="py-2 bg-white wrapper ">
                <div className="flex flex-col px-6 lg:px-28">
                    {title && (
                        <h2
                            className="mb-7 text-black-100 lg:mt-6 wow fadeInUp"
                            dangerouslySetInnerHTML={{ __html: title }}
                        />
                    )}
                    <div className="items-center space-y-3 md:space-x-10 md:flex md:space-y-0">
                        {email && (
                            <div className="flex items-center wow fadeInUp">
                                <i className="mr-4 font-700 cp-icon-Email text-cobalt-100"></i>
                                <p
                                    className="lead--regular text-black-100"
                                    dangerouslySetInnerHTML={{
                                        __html: email
                                    }}
                                ></p>
                            </div>
                        )}
                        {phone_number && (
                            <div className="flex items-center wow fadeInUp">
                                <i className="mr-4 font-700 cp-icon-Phone text-cobalt-100"></i>
                                <p
                                    className="flex items-center lead--regular text-black-100"
                                    dangerouslySetInnerHTML={{
                                        __html: phone_number
                                    }}
                                ></p>
                            </div>
                        )}
                    </div>
                </div>
            </section>
        }
    </>);
}

export default ContactHero;
