import  { useContext, useState, useEffect } from 'react';
import MemberBenefit from '../../components/chelsea/Member/MemberBenefit'
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

export const ACFMemberBenefit = ({anchor='', className='',data,meta}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let grids = extractRepeaterField(data, 'benefits' );

    grids = grids?.map((repeaterItem,index) => ({
        ...repeaterItem,
        background_image_desktop: meta?.images[repeaterItem.background_image_desktop] || {},
        background_image_mobile: meta?.images[repeaterItem.background_image_mobile] || {},
        icon:meta?.images[repeaterItem.icon] || {}
    }));


    return (<>
        {show &&
            <MemberBenefit  
                className={className}
                anchor={anchor}
                items={grids} 
                title={data?.title} />
        }
    </>);
}
