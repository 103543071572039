import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';
import { LineCTA } from './LineCTA';

const TwoColImageCTA = (props) => {
    const { height, width } = useWindowDimensions();
    const [imageUrl, setImageUrl] = useState();

    useEffect(() => {
        if (width > 768) {
            setImageUrl(props?.image?.[0]?.src);
        } else {
            // setImageUrl(props?.image?.[1]?.src); this does not work
            setImageUrl(props?.image?.[0]?.src);
        }
    }, [width]);
    // console.log(props);
    return (<>
        <div
            component="TwoColImageCTA"
            className={`TwoColImageCTA normal ${props?.colorize}`}
            id={props?.anchor}
        >
            <div className="grid col-span-1 lg:grid-cols-2 img-bg wrapper bg-cover w-full  bg-no-repeat" style={{ backgroundImage: `url(${imageUrl})` }}></div>
            <div className="content grid col-span-1 lg:grid-cols-2">
                <div className="flex justify-center px-6 lg:px-28">
                    <div className="flex flex-col pb-[41.5px] lg:pb-[129.5px]">
                        <p className="label--largeBold pt-16 lg:pt-[128px] wow fadeInUp">
                            {props?.subHeading}
                        </p>
                        <h2 className="mt-4 lg:mt-6 wow fadeInUp">
                            {' '}
                            {props?.heading}
                        </h2>
                        <div
                            className="lead--large mt-5 wow fadeInUp lg:mt-8 max-w-[448px]"
                            dangerouslySetInnerHTML={{ __html: props?.content }}
                        />
                        <LineCTA
                            title={props?.content_link?.title}
                            href={props?.content_link?.url}
                            target={props?.content_link?.target}
                            marginClass={'mt-[30.5px] lg:mt-[34.5px] '}
                            borderClass={'border--increase'}
                            textClassName={
                                'text-white group-hover:text-sky-100'
                            }
                        />
                    </div>
                </div>

                <div className="flex flex-col px-6 lg:px-[19.642%] xl:max-w-[66.666%] items-center justify-center space-y-4 lg:space-y-6 pb-16 lg:pb-0 wow fadeInUp">
                    {props?.bigTitle &&
                        <h2>{props?.bigTitle}</h2>
                    }
                    {props?.cta1?.url && 
                        <div className=" w-full max-w-[288px] lg:min-w-[272px] ">
                        <NextNavLink
                            href={props?.cta1?.url}
                            target={props?.cta1?.target}
                        >
                            <a className="btn btn--cobalt label--bold w-full justify-self-center self-center lg:self-start lg:label--largeBold pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px] ">
                                {props?.cta1?.title}
                            </a>
                        </NextNavLink>
                        </div>
                    }
                    {props?.cta2?.url && 
                        <div className=" w-full max-w-[288px] lg:min-w-[272px] ">
                        <NextNavLink
                            href={props?.cta2?.url}
                            target={props?.cta2?.target}
                        >
                            <a className="btn btn--sky label--bold w-full  lg:label--largeBold justify-self-center self-center lg:self-start pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px]">
                                {props?.cta2?.title}
                            </a>
                        </NextNavLink>
                        </div>
                    }
                </div>
            </div>
        </div>
    </>);
};

export default TwoColImageCTA;
