import  { useContext, useState, useEffect } from 'react';
import CommunityPartners from '../../components/chelsea/List/CommunityPartners'
import { LocationContext } from '../../util/LocationProvider'

const ACFCommunityPartners = ({meta,data,className,anchor}) => {
  let LocationManager = useContext(LocationContext);
  const [show, setShow] = useState(false);
  const [locale, setLocale] = useState(LocationManager?.getLocation());

  useEffect(() => {
      setShow(!LocationManager?.hideBlock(data));
  }, [locale, setShow, LocationManager, data]);

  return (<>
    {show &&
      <CommunityPartners 
        className={className} 
        anchor={anchor} 
        items={meta?.partners} />
    }
  </>);
}

export default ACFCommunityPartners
