import React, { useContext } from 'react';
import { LocationContext } from '../../../util/LocationProvider';




const PageSearch = (props) => {
    let LocationManager = useContext(LocationContext);

    let location = LocationManager.getLocation();


    return (<>

    </>);
};

export default PageSearch;
