import  { useContext, useState, useEffect } from 'react';
import ClassesDOW from '../../components/chelsea/Schedules/ClassesDOW';
import ClassesDate from '../../components/chelsea/Schedules/ClassesDate';
import { LocationContext } from '../../util/LocationProvider';

const ACFScheduleClassesYouth = ({ data, meta, pageProps, className='', anchor='' }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show && data?.schedule_type=='Program' &&
            <ClassesDOW
                ageFilter={true}
                className={className}
                anchor={anchor}
                data={data}
                meta={meta} 
                seasons={pageProps?.componentQueries?.filters?.data?.seaons?.edges}
                sports={pageProps?.componentQueries?.filters?.data?.sports?.edges}
            />
        }

        {show && data?.schedule_type!='Program' &&
            <ClassesDate
                ageFilter={true}
                className={className}
                anchor={anchor}
                data={data}
                meta={meta} 
                type={data?.schedule_type}
                seasons={pageProps?.componentQueries?.filters?.data?.seaons?.edges}
                sports={pageProps?.componentQueries?.filters?.data?.sports?.edges}
            />
        }
    </>);
};

export default ACFScheduleClassesYouth;
