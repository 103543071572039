import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

function CoreTable({ head, body, className='', data }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }
    const isStripped = className === 'is-style-stripes' 
    
    return (<>
        {show &&
            <section className="bg-white  wrapper block-table py-8 lg:space-y-8  wow fadeInUp" >
                <div className="px-6 lg:px-28 overflow-x-auto">
                    <table className={`text-black-100 border-collapse w-full border-b border-black-10 ${className} `}>
                        {head.map((headRow, index) => {
                            return headRow.cells.map(( row, index )=> {
                                return React.createElement(row.tag, {
                                    style: {
                                        textAlign: row.align
                                    },
                                    className: 'bg-black-100 text-white p-4 lg:px-6 lg:py-4 wow fadeInUp ',
                                    dangerouslySetInnerHTML: { __html:row.content }
                                }, )
                            })
                        })}
                        {body.map((bodyRow, index)=>{
                            return <tr
                                className={` p-6 text-center  ${
                                    index & 1 === 1 && isStripped ? 'bg-buttonGray text-gray ' : ''
                                }`}
                                >
                                {bodyRow.cells.map((row, index)=> {
                                    return React.createElement(row.tag, {
                                        style: {
                                            textAlign: row.align
                                        },
                                        className: 'p-2 lg:px-4 lg:py-4 wow fadeInUp ',
                                        dangerouslySetInnerHTML: { __html:row.content }
                                    }, )
                                })}
                            </tr>
                        })}
                    </table>
                </div>
            </section>
        }
    </>);
}

export default CoreTable;
