import  { useContext, useState, useEffect } from 'react';
import PostCarousel from '../../components/chelsea/Carousel/PostCarousel';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider'; 

const ACFPostCarousel = ({data, meta, anchor='', className=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);
    
    let grids = extractRepeaterField(data, 'posts' );

    grids = grids.map((repeaterItem,index) => ({
        ...repeaterItem,
        desktop_icon: meta?.images[repeaterItem.desktop_icon] || {},
        post:meta?.posts[index] || {}
    }));

    return (<>
        {show &&
            <PostCarousel 
                anchor={anchor} 
                className={className} 
                items={grids} 
                top_heading={data?.main_heading} />
        }
    </>);
}

export default ACFPostCarousel
