import React from 'react';
import NextNavLink from '../../util/NextNavLink';
import { LineCTA } from '../CTA/LineCTA';

export const ContactCard = ({ className = '', cards }) => {
    return (
        <div
            className={`wrapper grid lg:grid-cols-3 items-stretch bg-white text-black-100 pt-24 pb-32 lg:gap-0 gap-12 ${className}`}
        >
            {cards?.map((card, i) => (
                <>
                    {card?.contact_cards.map((item, index) => (
                        <div
                            key={index}
                            className="xl:px-[3.33vw] lg:px-[2.33vw] px-6"
                        >
                            <div className="bg-white relative z-[3]">
                                <div className="py-6 border-t lg:py-8 border-grid wow fadeInUp">
                                    {item?.column_heading && (
                                        <h3
                                            className="ss03 font-500 lg:min-h-[112px]"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.column_heading
                                            }}
                                        />
                                    )}
                                </div>
                                {item?.contact_name && (
                                    <div className="flex items-center pb-6 lg:pb-8 border-grid">
                                        <div
                                            className="bg-center bg-cover md:w-36 md:h-36 w-[120px] min-w-[120px] h-[120px] rounded-[50%] overflow-hidden bg-white bg-opacity-10 wow fadeInUp"
                                            style={{
                                                backgroundImage: `url(${item?.contact_image?.src})`
                                            }}
                                        />

                                        <div className="pl-6">
                                            {item?.contact_name && (
                                                <h5
                                                    className="mb-2 ss03 font-500 wow fadeInUp"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.contact_name
                                                    }}
                                                />
                                            )}
                                            {item?.contact_title && (
                                                <p
                                                    className="lead text-cobalt-100 wow fadeInUp"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.contact_title
                                                    }}
                                                />
                                            )}
                                        </div>
                                    </div>
                                )}
                                {item?.email && (
                                    <div className="flex items-center wow fadeInUp">
                                        <i className="mr-4 font-700 cp-icon-Email text-cobalt-100" />
                                        <p
                                            className="lead--regular text-black-100"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.email
                                            }}
                                        />
                                    </div>
                                )}
                                {item?.phone_numbers &&
                                    item?.phone_numbers.map(
                                        (phone, phoneIndex) => (
                                            <div className="lg:py-8 py-6 space-y-2 border-t border-b border-grid lg:min-h-[90px]">
                                                <div
                                                    key={phoneIndex}
                                                    className="flex items-center wow fadeInUp"
                                                >
                                                    <i className="mr-4 font-700 cp-icon-Phone text-cobalt-100" />
                                                    <p
                                                        className="flex items-center lead--regular text-black-100"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                phone?.phone_number
                                                        }}
                                                    />
                                                    <span
                                                        className="inline-block ml-4 label label--bold text-black-100 text-opacity-60"
                                                        dangerouslySetInnerHTML={{
                                                            __html: phone?.name
                                                        }}
                                                    />
                                                </div>
                                            </div>
                                        )
                                    )
                                }
                                <div className="py-8 space-y-6 border-b border-grid wow fadeInUp">
                                    <div>
                                        <i className="mr-4 font-700 cp-icon-MapPin text-cobalt-100" />
                                        <div
                                            className="mt-5 whitespace-pre-line lead--regular"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.address
                                            }}
                                        />
                                    </div>
                                    <LineCTA
                                        title={item?.cta?.title}
                                        href={item?.cta?.url}
                                        target={item?.cta?.target}
                                        marginClass={'mt-[22.5px]'}
                                        borderClass={'border--increase--cobalt'}
                                        textClassName={
                                            'text-black-100 group-hover:text-cobalt-100'
                                        }
                                    />
                                </div>{' '}
                                {item?.direction_link?.url &&
                                    <div className="py-8 text-center border-b border-grid wow fadeInUp">
                                        <NextNavLink
                                            href={item?.direction_link?.url}
                                            target={item?.direction_link?.target}
                                        >
                                            <a className="btn btn--cobalt label--bold justify-self-center self-center lg:self-start w-3/4 lg:label--bold pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px]">
                                                {item?.direction_link?.title}
                                            </a>
                                        </NextNavLink>
                                    </div>
                                }
                            </div>
                        </div>
                    ))}
                </>
            ))}
        </div>
    );
};
