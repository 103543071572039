import React from 'react';
import { LineCTA } from './LineCTA';

export const InfoBar = (props) => {
    const {
        link,
        text,
        cta_text,
        className = '',
        anchor,
        backGround
    } = props;
    const getBackgroundColor = () => {
        if (backGround === 'light_blue') {
            return 'bg-sky-100';
        } else if (backGround === 'dark_blue') {
            return 'bg-cobalt-100';
        } else {
            return 'bg-black-100';
        }
    };

    const getTextColor = () => {
        if (backGround === 'light_blue') {
            return 'text-black-100';
        } else if (backGround === 'dark_blue') {
            return 'text-white';
        } else {
            return 'text-white';
        }
    };

    const getBorderColor = () => {
        if (backGround === 'light_blue') {
            return 'border--increase--black';
        } else if (backGround === 'dark_blue') {
            return 'border--increase--white';
        } else {
            return 'border--increase';
        }
    };

    return (
        <div
            id={anchor}
            className={`flex md:flex-row flex-col md:items-center items-start px-8 justify-center w-full border-b border-grid space-y-[18.5px] md:space-y-0 ${getBackgroundColor()}  md:px-3 h-[132px] md:h-[48px] md:space-x-4 wow fadeInUp ${className}  `}
            data-wow-offset="0"
            data-name='acf-info-bar'
        >
            <div
                className={`lead--regular ${getTextColor()}`}
                dangerouslySetInnerHTML={{__html:text}}
                data-name="acf-info-bar--lead"
            />
            <LineCTA
                title={cta_text}
                href={link?.url}
                target={link?.target}
                borderClass={`${getBorderColor()}`}
                textClassName={`${getTextColor()}`}
            />
        </div>
    );
};
