import { useRouter } from 'next/router';
import React from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const PartnerCarousel = ({ items, top_heading, anchor, className }) => {
    const router = useRouter()


    return (
        <div
            className={` overflow-hidden bg-cover bg-white ${className}`}
            id={anchor}
        >
            <div className="pt-[30px]  pb-[65.5px] ">
                <div className="items-end mb-4 md:flex">
                    <h3 className="flex-1 text-center wow fadeInUp text-black-100">
                        {top_heading}
                    </h3>
                </div>
                <div className="relative px-6 lg:hidden">
                    <div className="">
                        <Swiper
                            slidesPerView={2}
                            // autoplay={{
                            //     delay: 5000
                            // }}
                            effect="fade"
                            navigation={{
                                prevEl: '.partner-prev',
                                nextEl: '.partner-next'
                            }}
                            centeredSlides={false}
                            // loop={true}
                            // loopFillGroupWithBlank={true}
                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination'
                            }}
                        >
                            {items &&
                                items?.map((item) => {
                                    return (
                                        <SwiperSlide>
                                            <div className="relative flex items-center justify-center cursor-pointer group text-black-100 ">
                                                <a href={item?.link?.url} target="_blank">
                                                <img
                                                    src={item?.thumbnail}
                                                    className="max-w-full"
                                                    alt={item?.alt}
                                                />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    </div>

                    <div
                        className="cursor-pointer absolute left-0 h-[64px] transform -translate-y-1/2 w-[64px] mx-[8.888%] z-[22] bg-buttonGray hidden lg:flex items-center justify-center partner-prev"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                    <div className="insight-custom-pagination"></div>
                    <div
                        className="cursor-pointer absolute right-0 h-[64px]   transform -translate-y-1/2 w-[64px] mx-[8.888%] z-[22] bg-buttonGray hidden lg:flex  items-center justify-center partner-next"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                </div>

                <div className="relative hidden wrapperPartnerCarousal lg:block">
                    <div className="">
                        <Swiper
                            slidesPerView={1}
                            effect="fade"
                            navigation={{
                                prevEl: '.partner-prev',
                                nextEl: '.partner-next'
                            }}
                            // centeredSlides={true}
                            pagination={{
                                clickable: true,
                                el: '.insight-custom-pagination-mobile'
                            }}
                            breakpoints={{
                                640: {
                                  slidesPerView: 2,
                                },
                                768: {
                                  slidesPerView: 3,
                                },
                                1024: {
                                  slidesPerView: 4,
                                },
                              }}
                        >
                            {items &&
                                items?.map((item, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div className="relative flex items-center justify-center cursor-pointer group text-black-100 ">
                                                <a href={item?.link?.url} target="_blank">
                                                <img
                                                    src={item?.thumbnail}
                                                    className="max-w-full"
                                                    alt={item?.alt}
                                                    
                                                />
                                                </a>
                                            </div>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    </div>

                    <div
                        className="cursor-pointer absolute left-0 h-[64px] transform -translate-y-1/2 w-[64px] mx-[8.888%] z-[22] bg-buttonGray hidden lg:flex items-center justify-center partner-prev"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className=" cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                    <div className="insight-custom-pagination-mobile"></div>
                    <div
                        className="cursor-pointer absolute right-0 h-[64px]   transform -translate-y-1/2 w-[64px] mx-[8.888%] z-[22] bg-buttonGray hidden lg:flex  items-center justify-center partner-next"
                        style={{ borderRadius: '50%', top: '30%' }}
                    >
                        <i className="transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PartnerCarousel;
