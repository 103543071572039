import  { useContext, useState, useEffect } from 'react';
import FeaturingBlock from '../../components/chelsea/FeaturingBlock';
import { LocationContext } from '../../util/LocationProvider';

function ACFFeaturingBlock({ data, meta, className = '', anchor = '' }) { 
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <FeaturingBlock
                className={className}
                anchor={anchor}
                desktopImage={meta?.images?.[data?.card_desktop_image]}
                mobileImage={meta?.images?.[data?.card_mobile_image]}
                subHeading={data?.[`sub_title`]}
                content={data?.content}
                cardCta={data?.card_cta}
                cta={data?.cta}
                heading={data?.title}
                postTitle={data?.card_title}
                cardSubTitle={data?.card_sub_title}
                cardContent={data?.card_content}
                flipColumns={data?.style_flip_columns}
            />
        }
    </>);
}

export default ACFFeaturingBlock;
