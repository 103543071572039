import  { useContext, useState, useEffect } from 'react';
import { extractRepeaterField } from '../../util/blocks';
import { Transition } from '@headlessui/react';
import { LocationContext } from '../../util/LocationProvider';

function ImageWysiwygRow({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const { content, desktop_image } = data;
    let image = meta?.images[desktop_image];
    return (<>
        {show &&
            <section className="py-0 bg-white wrapper text-black-100">
                <div className="px-6 lg:px-28">
                    <div className="relative z-[3] bg-white">
                        <div className="items-center md:flex md:flex-row">
                            <img src={image?.src} alt="" />
                            <div
                                className="max-w-sm lead--large"
                                dangerouslySetInnerHTML={{ __html: content }}
                            />
                        </div>
                    </div>
                </div>
            </section>
        }
    </>);
}

export default ImageWysiwygRow;
