import  { useContext, useState, useEffect } from 'react';
import FeaturedClass from '../../components/chelsea/FeaturedClass';
import { LocationContext } from '../../util/LocationProvider';

function ACFFeaturedClass({ data, meta, anchor = '', className = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <FeaturedClass
                className={className}
                anchor={anchor}
                external_source_video={filtered?.external_source_video}
                subTitle={filtered?.sub_title}
                title={filtered?.title}
                video_sub_title={filtered?.video_sub_title}
                video_title={filtered?.video_title}
                content={filtered?.content}
                cta={filtered?.content_link}
                button={filtered?.button || false}
                video_url_desktop={filtered?.video_url_desktop}
                video_url_mobile={filtered?.video_url_mobile}
                images={meta?.images}
                classes={meta?.classes}
                videos={meta?.videos}
            />
        }
    </>);
}

export default ACFFeaturedClass;
