import  { useContext, useState, useEffect } from 'react';
import { InfoBar } from '../../components/chelsea/CTA/InfoBar';
import { LocationContext } from '../../util/LocationProvider';

function ACFInfoBar({ anchor, className, data, pageProps, meta }) { 
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let fields = {};

    const use_default_data = (data?.use_default_data=='1');
    const defaults = pageProps?.defaults?.offerInfoBar?.acf_info_bar_post;
    fields = use_default_data ?
    {
        link: defaults?.linkOverwrite,
        text: defaults?.textOverwrite,
        cta_text: defaults?.ctaText,
        backGround: defaults?.styleOptions?.backgroundColor,
    } : {
        link: data?.link_overwrite,
        text: data?.text_overwrite,
        cta_text: data?.cta_text,
        backGround: data?.style_options_background_color || '',
    };

    return (<>
        {show && 
            <InfoBar
                className={className}
                anchor={anchor}
                {...fields}
            />
        }
    </>);
}

export default ACFInfoBar;
