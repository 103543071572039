import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';
import { LineCTA } from './LineCTA';

const TitleCTA = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    return (
        <div
            className={`wrapper bg-white  ${
                !props?.desktop_image?.src && !props?.mobile_image?.src
                    ? 'text-black-100'
                    : 'text-white'
            } ${props?.className}`}
            id={props?.anchor}
        >
            <div
                className={`bg-cover px-9 lg:px-0 lg:py-0 py-14  w-full grid  ${
                    !props?.desktop_image?.src && !props?.mobile_image?.src
                        ? 'lg:grid-cols-1'
                        : 'lg:grid-cols-2 min-h-[560px] '
                }`}
                style={{
                    backgroundImage: `url(${
                        actualWidth > 768
                            ? props?.desktop_image?.src
                            : props?.mobile_image?.src
                    })`,
                    backgroundPosition: 'center'
                }}
            >
                {props?.title && (
                    <div className="flex items-center  lg:pl-[7.222vw] lg:pr-[3.75vw] wow fadeInUp  ">
                        <h1 className=" text-start"> {props?.title} </h1>
                    </div>
                )}
                <div className="flex items-center justify-center">
                    <div
                        className={`flex flex-col items-start  lg:px-28  ${
                            !props?.desktop_image?.src &&
                            !props?.mobile_image?.src
                                ? ''
                                : 'lg:py-24 '
                        }`}
                    >
                        <p
                            className={`mt-12 label--bold lg:mt-0 wow fadeInUp ${
                                !props?.desktop_image?.src &&
                                !props?.mobile_image?.src
                                    ? 'text-cobalt-100'
                                    : ''
                            } `}
                        >
                            {' '}
                            {props?.subTitle}{' '}
                        </p>
                        <h3 className="mt-3 wow fadeInUp ">
                            {' '}
                            {props?.rightTitle}
                        </h3>
                        <div
                            className="mt-4 lead--regular wow fadeInUp "
                            dangerouslySetInnerHTML={{ __html: props?.content }}
                        />

                        {props?.ctas &&
                            props?.ctas?.map((item, index) => (
                                <>
                                    {item?.style !== 'link' ? (
                                        <NextNavLink
                                            href={item?.cta?.url}
                                            target={item?.cta?.target}
                                        >
                                            <a className="label--bold btn btn--black  pt-[15px] pb-[13px]  w-auto mt-7 lg:mt-8  h-full">
                                                {item?.cta?.title}
                                            </a>
                                        </NextNavLink>
                                    ) : (
                                        <LineCTA
                                            title={item?.cta?.title}
                                            href={item?.cta?.url}
                                            target={item?.cta?.target}
                                            marginClass={' mt-7 lg:mt-8 '}
                                            borderClass={
                                                'border--increase--cobalt'
                                            }
                                            textClassName={
                                                'text-black-100 group-hover:text-cobalt-100'
                                            }
                                        />
                                    )}
                                </>
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TitleCTA;
