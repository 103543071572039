import  { useContext, useState, useEffect } from 'react';
import { PageHero } from '../../components/chelsea/PageHero/PageHero'
import { extractRepeaterField } from '../../util/blocks'
import { LocationContext } from '../../util/LocationProvider'

export default function ACFPageHero({data,meta,anchor='', className=''}) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
    }, [locale, setShow, LocationManager, data]);

    let ctas = extractRepeaterField(filtered,'ctas')

    return (<>
        {show &&
            <PageHero 
                size={filtered?.style_options_size} 
                content={filtered?.description} 
                className={className} 
                anchor={anchor} 
                ctas={ctas} 
                title={filtered?.title} 
                subTitle={filtered?.['sub-title']} 
                desktop_image={meta?.images?.[filtered?.desktop_image]} 
                mobile_image={meta?.images?.[filtered?.mobile_image]} />
        }
    </>);
}