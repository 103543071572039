import { Transition } from '@headlessui/react';
import React, { useState } from 'react';
import { LineCTA } from './LineCTA';
import ReactPlayer from 'react-player';

//Based off the ClassCards component

const ThreeColGrid = (props, grid) => {
    const [modalState, setModalState] = useState(false);
    const [openModal, setOpenModal] = useState("close-all");

    const handleItem = (id) => {
        setOpenModal(id);
    };

    const getCards = (fields, meta) => {
        let cards = [];
        for (let i=0; i<fields?.cards; i++) {
            let imageId = fields[`cards_${i}_image`];
            let videoId = fields[`cards_${i}_video`];
            let video = "";
            if (props?.videos?.[videoId]?.data?.mediaItem?.mediaItemUrl) {
                video = props?.videos?.[videoId]?.data?.mediaItem?.mediaItemUrl;
            } else  {
                video = fields[`cards_${i}_video_url`];
            }

            cards.push({
                blurb:       fields[`cards_${i}_blurb`],
                html:        fields[`cards_${i}_html`],
                eyebrow:     fields[`cards_${i}_eyebrow`],
                cta_1:       fields[`cards_${i}_cta_1`],
                cta_2:       fields[`cards_${i}_cta_2`],
                cta_3:       fields[`cards_${i}_cta_3`],
                title:       fields[`cards_${i}_title`],
                media_column: fields[`cards_${i}_media_column`],
                image:       meta?.images?.[imageId],
                video
            });
        }
        return cards;
    };

    // const toggleMoreInfo = (index) => {
    //     if (opened == index) {
    //         setOpened(-1);
    //     } else setOpened(index);
    // };

    return (<>
        <div className="ClassCards bg-white">
            <div className="xl:px-[3.333%] pl-3 ">
            <div className="grid lg:grid-cols-3 pt-[64px] lg:pt-[97.7px]">
                        {getCards(props?.data, props?.meta).map((card, index) => {

                            return (<>
                            <div key={card.video} class="threeColCard">
                                <div className="flex flex-col lg:mb-[60px] pb-[65.5px] lg:pb-0  lg:px-12 group cursor-pointer mainCardWrapper">
                                    <div role="list" className="relative flex flex-col wow !visible overflow-hidden"></div>
                                    <div key={`class-card-${index}`} className="flex flex-col pb-[66px] lg:pb-24">
                                        <div className="relative flex flex-col wow !visible overflow-hidden" >
                                            {card?.image &&
                                                <div className="rev-img-wrap relative z-[1] ">
                                                    <div className="rev-img">
                                                        <div
                                                            className="img-bg w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                            style={{
                                                                backgroundImage:`url(${card?.image?.src})`}}
                                                        /> 
                                                    </div>
                                                    {card?.media_column !== 'image' ? (
                                                <>
                                                <div className="absolute top-0 left-0 w-full h-full bg-gray-18"></div>
                                                <div
                                                    className="absolute top-0 left-0 w-full  h-full z-[1] transition-all  cursor-pointer"
                                                    onClick={() =>
                                                        card.media_column !== 'image' &&
                                                        handleItem(card.video)
                                                    }
                                                ></div>
                                                <img
                                                    className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                    id="playVideoButton"
                                                    onClick={() =>
                                                        handleItem(card.video)
                                                    }
                                                    src="/images/playIcon.png"
                                                />
                                                </>
                                            ) : null}
                                                </div>
                                            }
                                            
                                            <div className="flex flex-col justify-end  z-[2]  mt-[-14%] lg:mt-[-13%] bg-[#ffffff] max-w-[80%]">
                                                <div className="text-[black]">
                                                    {card?.eyebrow &&
                                                        <div className="eyebrow mb-[12px] pt-[12px] label--bold text-cobalt-100">{card.eyebrow}</div>
                                                    }
                                                    {card?.title &&
                                                        <h3 className="title pt-[12px] mb-[16px] text-[32px] leading-[40px] group-hover:text-cobalt-100 transition-colors">{card?.title}</h3>
                                                    }
                                                    {card?.blurb &&
                                                    
                                                        <div className="blurb mb-[16px] text-[16px] leading-[24px]" dangerouslySetInnerHTML={{__html: ` ${card.blurb} `}}></div>
                                                    }
                                                    {card?.cta_1?.title && 
                                                        <LineCTA
                                                            title={card?.cta_1?.title}
                                                            href={card?.cta_1?.url}
                                                            target={card?.cta_1?.target}
                                                            marginClass={'mt-[15.5px]  mb-5'}
                                                            borderClass={'border--increase--cobalt'}
                                                            textClassName={'text-black-100 group-hover:text-cobalt-100'}
                                                        />
                                                    }
                                                    {card?.cta_2?.title && (
                                                        <LineCTA
                                                            title={card?.cta_2?.title}
                                                            href={card?.cta_2?.url}
                                                            target={card?.cta_2?.target}
                                                            marginClass={'mt-[15.5px]  mb-5'}
                                                            borderClass={'border--increase--cobalt'}
                                                            textClassName={'text-black-100 group-hover:text-cobalt-100'}
                                                        />
                                                    )}
                                                    {card?.cta_3?.title && (
                                                        <LineCTA
                                                            title={card?.cta_3?.title}
                                                            href={card?.cta_3?.url}
                                                            target={card?.cta_2?.target}
                                                            marginClass={'mt-[15.5px]  mb-5'}
                                                            borderClass={'border--increase--cobalt'}
                                                            textClassName={'text-black-100 group-hover:text-cobalt-100'}
                                                        />
                                                    )}
                                                    {/* {card?.link && 
                                                        <a href={card.link} className="inline-block max-w-[100px] mr-[-6px] w-full h-full items-center label--bold btn btn--cobalt max-h-12">Enroll</a>
                                                    } */}
                                                    {/* {card?.description &&
                                                        <span className="inline-block ml-[40px]">
                                                            <button onClick={() => toggleMoreInfo(index)}>
                                                                {(opened!=index) && "More"}{(opened==index) && "Less"} Info
                                                                <i className="transform ml-[15px] inline-block rotate-270 cp-icon-arraySmallLeft text-9 leading-16 font-700"></i>
                                                            </button>
                                                        </span>
                                                    } */}
                                                    {card?.html &&
                                                        <span className="inline-block ml-[40px]">
                                                            <button onClick={() => toggleMoreInfo(index)}>
                                                                {(opened!=index) && "More"}{(opened==index) && "Less"} Info
                                                                <i className="transform ml-[15px] inline-block rotate-270 cp-icon-arraySmallLeft text-9 leading-16 font-700"></i>
                                                            </button>
                                                        </span>
                                                    }
                                                    
                                                </div>
                                            </div>
                                        </div>
                                    </div>  
                                </div>
                                <Transition
                                key={card.video}
                                show={(card.video == openModal)}
                                enter="duration-200 ease-out"
                                enterFrom="opacity-0"
                                enterTo="opacity-100 scale-100"
                                leave="duration-100 ease-in"
                                leaveFrom="opacity-100 scale-100"
                                leaveTo="opacity-0 scale-95"
                            >
                                <div className="fixed items-center flex top-0 left-0 z-[999] w-full h-full min-h-screen bg-gray-dark">
                                    <a
                                        href=""
                                        onClick={(e) => {
                                            e.preventDefault();
                                            setOpenModal("close-all");
                                        }}
                                        className="absolute  inline-flex items-center justify-center w-12 h-12 text-white cursor-pointer top-6 right-6 rounded-30   bg-gray  hover:bg-black-100 text-12 font-700 z-[3] transition-all transform  "
                                    >
                                        <i className="cp-icon-modalClose"></i>
                                    </a>
                                    <div className="flex-auto px-6 lg:px-40 here">
                                        {/* {card.video} */}
                                        <ReactPlayer
                                            width="100%"
                                            height="100%"
                                            className="aspect-w-16 aspect-h-9"
                                            url={card.video}
                                            controls={true}
                                        />
                                        
                                    </div>
                                </div>
                            </Transition> 
                            </div>
                     
                            </>);
                        })}

                    </div>
            </div>
        </div>
    </>);
};

export default ThreeColGrid;