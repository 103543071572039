import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';

export const PageHero = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);
    
    return (
        <div
            className={`relative flex items-center ${
                props?.ctas?.length > 0
                    ? 'py-16 lg:py-[120px]'
                    : props?.content 
                    ? 'py-14 lg:py-[120px] min-h-[576px] max-h-[576px]'
                    : props?.size === "large"? 'py-14 lg:pt-[175px] lg:pb-[151px] min-h-[576px] max-h-[576px]':'py-12 lg:py-[52px]'
            } bg-cover bg-center ${props?.className}`}
            id={props?.anchor}
            style={{
                backgroundImage: `url(${
                    actualWidth && actualWidth < 768
                        ? props?.mobile_image?.src
                        : props?.desktop_image?.src
                })`
            }}
        >
            <div className=" z-[2] flex justify-center h-full w-full  text-white px-10 lg:px-10 wow fadeInUp ">
                <div className="flex items-center justify-center h-full">
                    <div className="relative flex flex-col items-center justify-center ">
                        <p className="text-white label--largeBold wow fadeInUp ">
                            {' '}
                            {props?.subTitle}
                        </p>
                        <h1
                            className={`text-white mt-4 ${ props?.size === "large"? 'lg:mt-[28px]' : "lg:mt-[38px] " } text-center wow fadeInUp  ${
                                props?.ctas?.length > 0
                                    ? ''
                                    : props?.content
                                    ? 'mb-2 lg:mb-[52px]'
                                    : props?.size === "large"? 'mb-[52px] lg:mb-[115px]' : 'mb-[44px] lg:mb-[82px]'
                            }`}
                        >
                            {props?.title}
                        </h1>
                        {props?.ctas?.length > 0 ? (
                            <div
                                className="hidden sm:block w-[1px] ml-[1px] h-[56px] my-3 bg-white"
                                wow
                                fadeInUp
                            ></div>
                        ) : null}
                        {props?.content ? (
                            <div className="flex flex-col mb-[7px] mt-[17px] lg:mt-0 items-center  justify-center wow fadeInUp ">
                                <div
                                    className="lead--large flex flex-col items-center gap-y-[4px] "
                                    dangerouslySetInnerHTML={{
                                        __html: props?.content
                                    }}
                                />
                            </div>
                        ) : null}
                        {props?.ctas?.length > 0 ? (
                            <div className="flex sm:flex-row w-full flex-col items-center  justify-center lg:max-w-[50vw] sm:px-3 xl:max-w-[31.111vw] mt-[36px] lg:mt-10 space-y-4 sm:space-y-0 wow fadeInUp ">
                                {props?.ctas?.map((cta, index) => {
                                    return cta?.cta_style === 'Dark Blue' ? (
                                        <NextNavLink
                                            href={cta?.cta?.url}
                                            target={cta?.cta?.target}
                                        >
                                            <a className="label--bold btn btn--cobalt sm:mr-3 pt-[15px] pb-[13px]  w-full h-full">
                                                {cta?.cta?.title}
                                            </a>
                                        </NextNavLink>
                                    ) : (
                                        <NextNavLink
                                            href={cta?.cta?.url}
                                            target={cta?.cta?.target}
                                        >
                                            <a className="label--bold btn btn--sky sm:ml-3  pt-[15px] pb-[13px] w-full h-full">
                                                {cta?.cta?.title}
                                            </a>
                                        </NextNavLink>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
            <div
                className="absolute top-0 left-0 w-full h-full"
                style={{ background: 'rgba(0,0,0,0.5)' }}
            ></div>
        </div>
    );
};
