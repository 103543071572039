import React, { useEffect, useState, useContext } from 'react';
import { useRouter } from 'next/router';
import { LocationContext } from '../../../util/LocationProvider';
import moment from 'moment/moment';

const ClassesDate = (props) => {
    let LocationManager = useContext(LocationContext);
    let locale = LocationManager.getLocation();

    const router = useRouter();

    let sports  = props?.sports  ? props.sports  : [];

    const [noResultsMessage, setNoResultsMessage] = useState(
        (props?.data?.no_results_message ? props?.data?.no_results_message : 'No Results')
    );

    const [loading, setLoading] = useState(true);

    // Hide state for filter dropdowns.
    const [hiddenSport, setHiddenSport] = useState(true);
    const [hiddenAge, setHiddenAge] = useState(true);
    const [hiddenDay, setHiddenDay] = useState(true);

    const [openDescription, setOpenDescription] = useState('');
    const toggleOpenDescription = (key) => {
        setOpenDescription((openDescription==key ? '' : key));
    }

    // Class Data. 
    const [classes, setClasses] = useState([]); // All
    const [filteredClasses, setFilteredClasses] = useState([]); // Filtered subset.

    // Filter States
    const [filterLocation, setFilterLocation] = useState(locale);
    const [filterSport, setFilterSport] = useState([]);
    const [filterAge, setFilterAge] = useState([]);
    const [filterDay, setFilterDay] = useState([]);
    // Fiter Name.
    const [filterName, setFilterName] = useState('');

    // Filter Values
    const [optionsSport, setOptionsSport] = useState([]);
    const [optionsAge, setOptionsAge] = useState([0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]);
    const [optionsDate, setOptionsDate] = useState([]);
    const [optionsDay, setOptionsDay] = useState([
        'MORNING / 6AM-9AM',
        'LATE MORNING / 9AM-12PM',
        'AFTERNOON / 12PM-5PM',
        'EVENING / 5PM-9PM',
    ]);


    const dayTimes = {
        'MORNING / 6AM-9AM': ['6:00am', '8:59am'],
        'LATE MORNING / 9AM-12PM': ['9:00am', '11:59pm'],
        'AFTERNOON / 12PM-5PM': ['12:00pm', '4:59pm'],
        'EVENING / 5PM-9PM': ['5:00pm', '8:59pm'],
    };

    useEffect(() => {
        try {
            const d = new Date();
            let ts = d.getTime();
            let ms = d.getMilliseconds();

            let endpoint = 'chelsea-piers-data-api.chelseapiers.com';
            if (process?.env?.NEXT_PUBLIC_STAGE == '1') {
                endpoint = 'chelsea-piers-data-api.chelseapiers.com';
            }

            fetch(`https://${endpoint}/wp-content/plugins/chelsea-api-connector/schedules/${filterLocation}.json?cache=${ts}.${ms}`)
            .then(response => response.json())
            .then(classes => { 
                let dates = [];
                let filter = [];
                classes.forEach((item) => { 
                    
                    if (item?.class?.type != props?.type) return;  // Filter by Type (Camps / Drop-ins)
                    if (item?.class?.activity?.name == 'N/A') return; // Require Sport
                    
                    let classDate = item?.class.date.replace(' ', 'T');
                    classDate = classDate.split('T')[0];
                    if (!dates.includes(classDate)) {
                        dates.push(classDate);
                    }

                    filter.push(item);
                });

                setClasses(filter);
                setOptionsDate(dates.sort());
                setFilteredClasses(filter);
                setDefaults(filter);

                setLoading(false);
            });

        } catch (e) { console.log(e); }
    }, [
        filterLocation,
        LocationManager.sportsLocation,
        LocationManager.eventsLocation,
    ]);


    const formatCurrency = (cost) => {
        let price = cost ? parseFloat(cost) : 0.00;
        let final = (Math.round(price * 100) / 100).toFixed(2);
        if (final == 0.00) {
            return '-';
        } else return final;
    };

    const csvArray = (str) => {
        if (str.includes(',')) {
            return str.split(',');
        } else {
            return [str];
        }
    }

    const correctDate = (d) => {
        if (typeof d != 'string') return d;
        let date = d.replace(' ', 'T'); 
        return date.split('T')[0];
    }

    useEffect(() => { 
        // Sport is set, and the value exists as a filter option.
        if (router?.query?.sport) {
            let sports = csvArray(router?.query?.sport);
            let sportsFilters = [...filterSport];
            sports.forEach((sport) => { 
                // Is this a valid option? Not already set?
                if (optionsSport.includes(sport) && !filterSport.includes(sport)) { 
                    sportsFilters.push(sport);
                }
            });
            setFilterSport(sportsFilters);
        }

        // Age is set, and the value exists as a filter option.
        if (router?.query?.age) {
            let ages = csvArray(router?.query?.age);
            let agesFilters = [...filterAge];
            ages.forEach(age => { 
                if (age != '18+') {
                    age = parseInt(age);
                } else if (age == '18+') {
                    age = 18
                }
                // Is this a valid option? Not already set?
                if (optionsAge.includes(age) && !filterAge.includes(age)) { 
                    agesFilters.push(Number(age)); 
                }
            });
            setFilterAge(agesFilters);
        }

        // Season is set, and the value exists as a filter option.
        if (router?.query?.season && optionsSemester.includes(router?.query?.season)) {
            setFilterSemester(router.query.season);
        }

        // Check if name query present.
        if (router?.query?.keyword) {
            setFilterName(router.query.keyword);
        }

        // Semester. (class.originalSemester).
    }, [
        router.query,
        optionsSport, // Watch the sports options since they're dynamically added.
    ]);


    const setFilters = () => {};

    const setDefaults = (classes) => {
        let sportsFilters = [];
        let sportsOptions = [];

        if (props?.data?.hide_selected_sports_in_filtering == 1) { // Sports Options are preset.
            props?.data?.limit_sports?.forEach((sportId) => {
                sports?.forEach((sport) => {
                    if (parseInt(sport?.node?.databaseId) == sportId) {
                        sportsOptions.push(sport?.node?.title);
                    }
                });
            });
        } else { // Limit to what's present in the dataset.
            classes.forEach((item) => {
                let sport = item?.class?.activity?.name;
                if (!sportsOptions.includes(sport)) {
                    sportsOptions.push(sport);
                }
            });
        }
        setOptionsSport(sportsOptions.sort());

        if (props?.data?.set_default_sport) {
            props?.data?.default_sports?.forEach((sportId) => {
                sports?.forEach((sport) => {
                    if (parseInt(sport?.node?.databaseId) == sportId) {
                        if (sportsOptions.includes(sport?.node?.title)) { 
                            // If not an option, the filter can't be disabled.
                            sportsFilters.push(sport?.node?.title);
                        }
                    }
                })
            });
            setFilterSport(sportsFilters.sort());
        }

        if (props?.data?.set_default_age == '1') {
            let ages = [];
            props?.data?.default_age?.forEach((age) => {
                ages.push(parseInt(age));
            });
            setFilterAge(ages);
        }
    };

    // Filter Updates ..
    useEffect(() => {
        let filteredClasses = [];
        let sports = [];
        const type = props?.data?.schedule_type;

        // Loop through classes..
        for (let i=0; i<classes.length; i++) {
            // Filter by type.
            if (type != classes[i]?.class?.type) continue;

            // Filter by Age
            let matchingAge = true;
            let min = classes[i]?.class?.age?.min;
            let max = classes[i]?.class?.age?.max;
            min = min ? min : 0;
            max = max ? max : 199;

            // Youth Table filters: 
            if (min==0 && max==0) { matchingAge = false; }
            if (min>18) { matchingAge = false; }

            if (filterAge.length) { console.log(filterAge);
                matchingAge = false;
                for (let j=0; j<filterAge.length; j++) {
                    let age = filterAge[j];
                    if (age >= min && age <= max) {
                        matchingAge = true;
                    }
                    // Youth Table filter: 
                    if (min==0 && max==0) continue;
                    if (min>18) continue;
                }
            }
            if (!matchingAge) continue;

            // Filter Time 
            if (filterDay.length) {
                let show = false;
                let dayOfWeek = moment(classes[i]?.class?.date, 'YYYY-MM-DD').format('dddd').toLowerCase();
                let classTime = moment(classes[i]?.class?.time[dayOfWeek], 'hh:mma').unix();

                filterDay.forEach((timeLabel) => { 
                    let rangeStart = moment(dayTimes[timeLabel][0], 'hh:mma').unix();
                    let rangeEnd   = moment(dayTimes[timeLabel][1], 'hh:mma').unix();

                    if (classTime>=rangeStart && classTime<=rangeEnd) {
                        show = true;
                    }
                });

                if (!show) continue;
            }

            // Filter Sport
            if (filterSport.length && !filterSport.includes(classes[i]?.class?.activity?.name)) {
                continue;
            }

            // Filter Name 
            if (filterName?.length) {
                let fName = filterName.toLowerCase();
                let cName = classes[i]?.class?.name.toLowerCase();
                if (! cName.includes(fName)) {
                    continue;
                }
            }

            filteredClasses.push(classes[i]);

        }

        setFilteredClasses(filteredClasses);
        //setOptionsSport(sports.sort()); If "View All"

    }, [
        classes, 
        filterSport, 
        filterAge, 
        filterDay,
        props
    ]);

    const toggleSport = (sport, e) => {
        let sportFilter = [...filterSport];
        if (!sportFilter.includes(sport)) {
            sportFilter.push(sport);
            e.currentTarget.classList.add('active');
        } else {
            sportFilter = sportFilter.filter(d => d !== sport);
            e.currentTarget.classList.remove('active');
        }
        setFilterSport(sportFilter);
    };


    const toggleAge = (age, e) => {
        let ages = [...filterAge];
        if (!ages.includes(age)) {
            ages.push(age);
            e.currentTarget.classList.add('active');
        } else {
            ages = ages.filter(a => a !== age);
            e.currentTarget.classList.remove('active');
        }
        setFilterAge(ages);
    };

    const toggleDay = (day, e) => {
        let days = [...filterDay];
        if (!days.includes(day)) {
            days.push(day);
            e.currentTarget.classList.add('active');
        } else {
            days = days.filter(a => a !== day);
            e.currentTarget.classList.remove('active');
        }
        setFilterDay(days);
    };

    const getClassesByDate = (date) => {
        let dateClasses = [];

        filteredClasses.forEach((_class) => {
            if (_class?.class?.date?.includes(date)) {
                dateClasses.push(_class);
            }
        });

        return dateClasses.sort((a,b) => {
            let aDay  = moment(correctDate(a?.class?.date), 'YYYY-MM-DD').format('dddd').toLowerCase();
            let aTime = moment(a?.class?.time[aDay], 'hh:mma').unix();

            let bDay  = moment(correctDate(b?.class?.date), 'YYYY-MM-DD').format('dddd').toLowerCase();
            let bTime = moment(b?.class?.time[bDay], 'hh:mma').unix();

            if (aTime<bTime) return -1;
            if (aTime>bTime) return  1;

            return 0;
        });
    };


    const isFilterActive = (filter, value) => {
        let filterCheck = false;

        if (Array.isArray(filter)) {
            filterCheck = (filter.includes(value) ? 'active' : '');
        } else {
            filterCheck = (filter==value ? 'active': '');
        }
        return filterCheck;
    };

    const locationLabel = (location) => {
        let primary = LocationManager.getLabel(location?.primary);
        return (location?.name ? `${primary} (${location?.name})` : primary);
    }

    const register = (item) => { 
        if (item?.api == 'dash') {
            let seasonId = item?.dash?.registration?.seasonID;
            let facilityId = item?.dash?.registration?.facilityID;
            let leagueId = item?.dash?.registration?.leagueId;
            let programId = item?.dash?.registration?.programID;
            return `https://member.daysmartrecreation.com/#/online/chelsea/activity-finder/programs/${programId}/levels/${leagueId}`;
        } else {
            let centerId = item?.exerp?.centerId;
            let semesterId = item?.exerp?.semesterId;
            let activityId = item?.class?.activity?.id;
            let today = moment().format('YYYY-MM-DD');
            let range = moment().add(1, 'year').format('YYYY-MM-DD');

            if (item?.class?.type == 'Camp') {
                return `https://myaccount.chelseapiers.com/camps?semester=${semesterId}&center=${centerId}&dateFrom=${today}&dateTo=${range}`;
            } else {
                let classDate = moment(item?.class?.date).format('YYYY-MM-DD');
                return `https://myaccount.chelseapiers.com/booking?centers=${centerId}&activities=${activityId}&date=${classDate}`;
            }
        }
    };

    const getTimeAndLength = (session, day) => {
        let time = session?.class?.time[day];
        if (!time) {
            for (let d in session?.class?.time) {
                if (session.class.time[d]) {
                    time = session.class.time[d];
                }
            }
        }

        let length = session?.class?.length[day];
        if (!length) {
            for (let d in session?.class?.length) {
                if (session.class.length[d]) {
                    length = session.class.length[d];
                }
            }
        }

        return `${time} / ${length}m`;
    }

    return (<>
        <div component="ClassesDate" className="xl:px-[3.333%] wow fadeInUp bg-white  to-animate" id="" animated="true" >
            {/* FILTERS SELECTS */}
            {props?.data?.title &&
                <h3 className="px-6 pt-12 text-center lg:px-0 lg:pt-16 text-black-100">{props?.data?.title}</h3>
            }
            <div className="flex-col pt-16 lg:flex wow fadeInUp  to-animate"  animated="true">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">
                    {/* Sport Dropdown */}
                    <div className="flex min-w-[180px] flex-col lg:w-full xl:px-[1.3393%] px-3  relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenSport(false)}>
                            <span className="transition-all duration-100 label--bold ">Sport</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16  -rotate-90 transform   font-700"></i>
                        </div>
                        {!hiddenSport ? 
                            <div 
                                onMouseLeave={() => setHiddenSport(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i onClick={() => setHiddenSport(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {optionsSport.map((sport) => {
                                        if (sport == 'N/A') return (<></>); // Skip N/A

                                        return (<>
                                            <div 
                                                key={sport}
                                                onClick={(e) => toggleSport(sport, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterSport, sport)}>
                                                <div className="check w-4 mb-[2px]  h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{sport}</p>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                    {/* AGE Dropdown */}
                    <div className="flex min-w-[180px] flex-col lg:w-full xl:px-[1.3393%] px-3 relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenAge(false)}>
                            <span className="transition-all duration-100 label--bold ">Age</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenAge ? 
                            <div 
                                onMouseLeave={() => setHiddenAge(true)}
                                className="flex flex-col z-50 mt-2 w-[324px] absolute transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenAge(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsAge.map((age, index) => { console.log(age); console.log(filterAge);
                                        return (
                                            <div 
                                                key={`classes-date-age-${index}`}
                                                onClick={(e) => toggleAge(age, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterAge, age)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{age}</p>
                                    </div>
                                        );
                                    })}

                                </div>
                            </div>
                        : null}
                    </div>

                    {/* DAY Dropdown */}
                    <div className="flex min-w-[180px] flex-col lg:w-full xl:px-[1.3393%] px-3 relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenDay(false)}>
                            <span className="transition-all duration-100 label--bold ">Time of Day</span>
                            <i className={`cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16 -rotate-90 transform font-700 ` + (hiddenDay ? 'rotate-90' : '-rotate-90')}></i>
                        </div>
                        {!hiddenDay ? 
                            <div 
                                onMouseLeave={() => setHiddenDay(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-50 transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenDay(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsDay.map((day, index) => {
                                        return (
                                            <div 
                                                key={`classes-date-day-${index}`}
                                                onClick={(e) => toggleDay(day, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterDay, day)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{day}</p>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>

            {/* No Results Message */}
            {!loading && !filteredClasses?.length && 
                <div className="flex-col hidden pt-16 lg:flex wow fadeInUp content-center to-animate mb-90px"  animated="true">
                    <h3 className="self-center" style={{ color: 'black' }}>{noResultsMessage}</h3><br></br><br></br>
                </div>
            }

            {loading && 
                <>
                    <div className="spinner flex justify-center items-center"><br /><br />
                        <div className="spinner-border border-slate-300 animate-spin  inline-block w-16 h-16 border-4 rounded-full" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>
                    </div>
                </>
            }

            {/* Dates Table */}
            {optionsDate.map((date, index) => { 
                let classesByDate = getClassesByDate(date);
                if (!classesByDate.length) return (<></>);
                return (<>
            <div key={`classes-date-date-${index}`} className="flex flex-col pt-8 pb-16 lg:pt-16 lg:pb-24 " id="classNamees">

                {/* Table Header */}
                <div data-wow-delay="0s" className="flex flex-col px-[36px] lg:px-28 cursor-pointer">
                    <div className="flex items-center  justify-between px-6 py-[10px] lg:p-6 border-t border-black-10 relative z-10  bg-black-100 text-white ">
                                <h5 className="">{moment(date, 'YYYY-MM-DD').format('dddd, MMMM Do')}</h5>
                    </div>
                </div>
                <div className="mx-[36px] lg:mx-28 relative z-10 bg-[#f7f7f7]  border-b border-black-10 ">
                    <div className="flex flex-col  lg:py-0 py-8">
                        <div className="text-black-100 flex space-y-3 lg:space-y-0 flex-col lg:flex-row lg:items-center lg:justify-between min-h-[90px] cursor-pointer">
                            <div className="item lead--extraLarge w-full items-center px-6 ">Class Name</div>
                            <div className="item lead--extraLarge w-full items-center px-6 mt-2 lead--regular"><p>Time/Length</p></div>
                            <div className="item lead--extraLarge w-full items-center px-6 mt-2 lead--regular"><p>Location</p></div>

                            <div className="item w-full items-center justify-between px-6">
                                <div className=" lead--extraLarge mt-2 items-center lead--regular">Price</div>
                            </div>
                            <div className="item w-full items-center justify-between px-6">
                                <div className=" lead--extraLarge mt-2 items-center lead--regular">&nbsp;</div>
                            </div>
                        </div>
                    </div>
                </div>

                {classesByDate?.map((session, id) => {
                            let lday = moment(correctDate(session?.class?.date), 'YYYY-MM-DD').format('dddd').toLowerCase();
                            let key  = `item-${id}-class-${session?.class?.id}-${lday}-${date}`;
                            return (
                                <div key={key} className="">
                                    <div 
                                        data-label="Class Info"
                                        className="hidden"
                                        data-class={JSON.stringify(session?.class)}
                                    ></div>

                                    <div className="wrap mx-[36px] lg:mx-28 relative z-10 bg-white  border-b border-black-10 " onClick={() => toggleOpenDescription(key)}>
                                            <div className="flex flex-col  lg:py-0 py-8">
                                                <div className="text-black-100 flex space-y-3 lg:space-y-0 flex-col lg:flex-row lg:items-center lg:justify-between min-h-[144px] cursor-pointer">
                                                    <div className="item w-full items-center lead--extraLarge mt-2 px-6 ">
                                                        {session?.class?.name}
                                                    </div>
                                                    <div className="item w-full px-6 mt-2 lead--regular"><p>{getTimeAndLength(session, lday)}</p></div>
                                                    <div className="item w-full px-6 mt-2 lead--regular">{locationLabel(session?.location)}</div>
                                                    <div className="item w-full flex items-center justify-between px-6">
                                                        ${formatCurrency(session?.price)}
                                                    </div>
                                                    <div className="item w-full flex items-center justify-between px-6">
                                                        <a target="_blank" href={register(session)} className="register relative z-50 label--bold btn btn--cobalt pt-[15px] sm:mr-3 pb-[13px] w-full h-full mr-[30px]">Register</a>
                                                        <i className={`cp-icon-arraySmallLeft text-9 leading-16 transform font-700 ` + (openDescription==key ? 'rotate-90' : '-rotate-90')}></i>
                                                    </div>
                                                </div>
                                        </div>
                                        {openDescription==key ? 
                                            <div className="bg-[#F7F7F7] mt-8 lg:mt-0 p-6 grid lg:grid-cols-2" >
                                                <div className="pb-3 border-b lg:border-r lead--extraLarge border-black-10 lg:border-b-0 text-black-100 text-opacity-70">What to Expect</div>
                                                <div className="mt-3 lead--small lg:mt-0 lg:px-6 text-black-100 text-opacity-70">{session?.class?.description.replace(/(<([^>]+)>)/gi, "")}</div>
                                            </div>
                                        : null}
                                    </div>
                                </div>
                            );
                        })}
                        
                    </div>
                </>);
            })}
            {/* End Day Table */}
        </div>
    </>);
};

export default ClassesDate;