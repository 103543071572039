import { Transition } from '@headlessui/react';
import React, { useState, useEffect, Fragment, useRef } from 'react';

const MobileFilter = ({
    locationsFilter,
    specialityFilter,
    getActiveLocationFilters,
    getActiveSpecialityFilters,
    closeMobileFilter,
    showMobileFilter,
    currentActiveLocation,
    currentActiveSpeciality
}) => {
    const ref = useRef();
    const [activeLocation, setActiveLocation] = useState();
    const [activeSpeciality, setActiveSpeciality] = useState();
    const [show, setShow] = useState([]);

    const cleanFilters = () => {
        setActiveLocation('all');
        setActiveSpeciality('all');
        getActiveLocationFilters('all');
        getActiveSpecialityFilters('all');
        closeMobileFilter();
    };

    useEffect(() => {
        if (currentActiveLocation?.length > 0) {
            setActiveLocation(currentActiveLocation);
        }
        if (currentActiveSpeciality?.length > 0) {
            setActiveSpeciality(currentActiveSpeciality);
        }
    }, [currentActiveLocation, currentActiveSpeciality]);

    const sendFilters = () => {
        getActiveLocationFilters(activeLocation);
        getActiveSpecialityFilters(activeSpeciality);
        closeMobileFilter();
    };

    useEffect(() => {
        if (showMobileFilter) {
            setShow(['locations', 'specialities']);
        }
    }, [showMobileFilter]);

    return (
        <div className="flex flex-col wrapper items-center relative">
            <div className="w-[1px] xl:hidden  z-[3]  fixed h-full bg-grid left-3"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid left-1/2"></div>
            <div className="w-[1px] xl:hidden  z-[3] fixed h-full bg-grid right-3"></div>
            <div className={`flex flex-col relative z-[3]  mt-6`}>
                <div
                    onClick={() => {
                        
                        if (show?.includes('locations')) {
                            setShow(show?.filter((item) => item !== 'locations'));
                        } else {
                            setShow([...show, 'locations']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('locations') ||
                            locationsFilter?.includes(activeLocation)
                        
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold  ">Locations</span>
                    {locationsFilter?.includes(activeLocation) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('locations')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('locations')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px]   bg-gray   px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {locationsFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setActiveLocation(item?.slug);
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeLocation === item?.slug
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.name}{' '}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>

            <div className={`flex flex-col relative z-[3] mt-12`}>
                <div
                    onClick={() => {
                        if (show?.includes('specialities')) {
                            setShow(show?.filter((item) => item !== 'specialities'));
                        } else {
                            setShow([...show, 'specialities']);
                        }
                    }}
                    className={`flex items-center  btn ${
                        show?.includes('specialities') ||
                        specialityFilter?.includes(activeSpeciality)
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    } pt-[15px] w-[288px] max-w-[288px]  justify-between space-x-16`}
                >
                    <span className="label--bold  ">Category</span>
                    {specialityFilter?.includes(activeSpeciality) ? (
                        <i
                            className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`}
                        ></i>
                    ) : (
                        <i
                            className={`  cp-icon-arraySmallLeft text-9 mr-4 leading-16 ${
                                show?.includes('specialities')
                                    ? ' rotate-90 '
                                    : ' -rotate-90'
                            } transform  font-700`}
                        ></i>
                    )}
                </div>

                <Transition
                    show={show?.includes('specialities')}
                    ref={ref}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="flex flex-col mt-2 w-[288px] absolute transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                        <div className="flex flex-col space-y-[18px] mt-[25px] mb-[23px]">
                            {specialityFilter?.map((item, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            setActiveSpeciality(item?.slug);
                                        }}
                                        className={`flex items-center space-x-3`}
                                        key={index}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                activeSpeciality === item?.slug
                                                    ? 'bg-white'
                                                    : ''
                                            }`}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            {item?.name}{' '}
                                        </p>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                </Transition>
            </div>
            {activeLocation !== 'all' || activeSpeciality !== 'all' ? (
                <div className="fixed px-4 bg-black-100 wr z-[3] w-full h-20 bottom-0">
                    <div className="flex justify-center items-center h-full space-x-4">
                        <div className="btn btn--gray bg-[#999999] label--bold pt-[15px] max-h-[48px] flex items-center text-black-100 justify-between w-full max-w-[156px]">
                            <p
                                className="label--bold w-full"
                                onClick={() => cleanFilters()}
                            >
                                CLear All
                            </p>
                        </div>
                        <div className="btn btn--sky label--bold flex pt-[15px] max-h-[48px] items-center justify-between px-6 w-full max-w-[156px]">
                            <p
                                className="label--bold w-full"
                                onClick={() => sendFilters()}
                            >
                                Apply
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default MobileFilter;
