import  { useContext, useState, useEffect } from 'react';
import MemberQuote from '../../components/chelsea/Member/MemberQuote';
import { LocationContext } from '../../util/LocationProvider';

export const ACFMemberQuote = ({ anchor='', className='',data, meta }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <MemberQuote
                className={className}
                anchor={anchor}
                desktop_image={meta?.images?.[filtered?.member_desktop_image]}
                mobile_image={meta?.images?.[filtered?.member_mobile_image]}
                name={filtered?.first_name + " " + filtered?.last_name}
                quote={filtered?.quote}
                subTitle={filtered?.sub_title}
            />
        }
    </>);
};
