import { Transition } from '@headlessui/react';
import React, { useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import useWindowDimensions from '../../../util/windowDimensions';
import { LineCTA } from './LineCTA';

const TwoColGrid = ({ grids, style, flip, anchor, className, posts }) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();
    const [modalState, setModalState] = useState(false);
    const [activeGrid, setActiveGrid] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    const handleItem = (grid) => {
        setModalState(true);
        setActiveGrid(grid);
    };

    return (
        <>
            <div className={`wrapper bg-white ${className}`} id={anchor}>
                {style === 'style1' ? (
                    <div className="grid lg:grid-cols-2 pt-[64px] lg:pt-[97.7px]">
                        {grids?.map((grid) => {
                            return (
                                <div className="flex flex-col lg:mb-[60px] pb-[65.5px] lg:pb-0  lg:px-12 group cursor-pointer mainCardWrapper">
                                    <div className="relative flex flex-col wow !visible overflow-hidden">
                                        <div className="rev-img-wrap relative z-[1]">
                                            <div className="rev-img">
                                                <div
                                                    className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                    style={{
                                                        // Adam image set
                                                        backgroundImage:
                                                        // Triple nested ternary operator, _wtf_. 
                                                        grid?.media_column==='image' 
                                                        ? `url(${
                                                                actualWidth>768 
                                                                ? (grid?.use_post_content==='1' ? posts[0]?.image?.src : grid?.desktop_image?.src) 
                                                                : (grid?.use_post_content==='1' ? posts[0]?.image?.src : grid?.mobile_image?.src)
                                                            })`
                                                        : `url(${grid?.posterImage?.src})`}}

                                                /> 
                                            </div>
                                        </div>
                                        {grid?.media_column !== 'image' ? (
                                            <>
                                                <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                <div
                                                    className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                    onClick={() =>
                                                        grid?.media_column !==
                                                            'image' &&
                                                        handleItem(grid)
                                                    }
                                                ></div>
                                                <img
                                                    className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                    id="playVideoButton"
                                                    onClick={() =>
                                                        handleItem(grid)
                                                    }
                                                    src="/images/playIcon.png"
                                                />
                                            </>
                                        ) : null}
                                        <div className="absolute top-0 left-0 w-full h-full rev-bg"></div>{' '}
                                        <h2
                                            className="absolute px-12 pt-10 z-[1]"
                                            dangerouslySetInnerHTML={{
                                                __html: grid?.image_text
                                            }}
                                        />
                                        <div
                                            onClick={() =>
                                                grid?.media_column !==
                                                    'image' && handleItem(grid)
                                            }
                                            className="absolute top-0 left-0 w-full h-full transition-all duration-300 group-hover:bg-black-20 z-[1]"
                                        ></div>
                                    </div>
                                    {/* END v1 col1            */}
                                    <div className="flex flex-col justify-end  z-[2]  mt-[-14%] lg:mt-[-13%]">
                                        <div className="flex flex-col max-w-[93%] lg:max-w-[83%] lg:self-start bg-white px-6 lg:pr-6 lg:pl-0 pt-6">
                                            <p
                                                data-wow-delay="0.1s"
                                                className="label--bold text-cobalt-100 wow fadeInUp"
                                                dangerouslySetInnerHTML={{
                                                    __html:
                                                        grid?.use_post_content ===
                                                        '1'
                                                            ? posts[0]?.name
                                                            : grid?.top_header
                                                }}
                                            ></p>
                                            <a href={grid?.cta?.url}>
                                                <h4
                                                    data-wow-delay="0.3s"
                                                    className="text-black-100 group-hover:text-cobalt-100 transition-colors duration-300 mt-[14px] mt-4 lg:mt-3  wow fadeInUp"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            grid?.use_post_content ===
                                                            '1'
                                                                ? posts[0]?.[
                                                                    'sub-title'
                                                                ]
                                                                : grid?.main_header
                                                    }}
                                                ></h4>
                                            </a>
                                            <div
                                                data-wow-delay="0.5s"
                                                className="wow fadeInUp"
                                            >
                                                <div
                                                    className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                    dangerouslySetInnerHTML={{
                                                        __html:
                                                            grid?.use_post_content ===
                                                            '1'
                                                                ? posts[0]
                                                                      ?.content
                                                                : grid?.content
                                                    }}
                                                />
                                                {grid?.cta?.title &&
                                                <LineCTA
                                                    title={
                                                        grid?.use_post_content ===
                                                        '1'
                                                            ? posts[0]?.[
                                                                  'cta-text'
                                                              ]
                                                            : grid?.cta?.title
                                                    }
                                                    href={
                                                        grid?.use_post_content ===
                                                        '1'
                                                            ? grid?.cta?.url
                                                            : grid?.cta?.url
                                                    }
                                                    target={grid?.cta?.target}
                                                    marginClass={'mt-[22.5px]'}
                                                    borderClass={
                                                        'border--increase--cobalt'
                                                    }
                                                    textClassName={
                                                        'text-black-100 group-hover:text-cobalt-100'
                                                    }
                                                />
                                                }
                                                {grid?.cta_2?.title && (
                                                    <LineCTA
                                                        title={
                                                            grid?.cta_2?.title
                                                        }
                                                        href={grid?.cta_2?.url}
                                                        target={
                                                            grid?.cta_2?.target
                                                        }
                                                        marginClass={
                                                            'mt-[22.5px]'
                                                        }
                                                        borderClass={
                                                            'border--increase--cobalt'
                                                        }
                                                        textClassName={
                                                            'text-black-100 group-hover:text-cobalt-100'
                                                        }
                                                    />
                                                )}
                                                {grid?.cta_3?.title && (
                                                    <LineCTA
                                                        title={
                                                            grid?.cta_3?.title
                                                        }
                                                        href={grid?.cta_3?.url}
                                                        target={
                                                            grid?.cta_3?.target
                                                        }
                                                        marginClass={
                                                            'mt-[22.5px]'
                                                        }
                                                        borderClass={
                                                            'border--increase--cobalt'
                                                        }
                                                        textClassName={
                                                            'text-black-100 group-hover:text-cobalt-100'
                                                        }
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    // END v1 flip cols?
                ) : (
                    <div className="flex flex-col bg-white lg:space-y-12 lg:py-12">
                        {grids?.map((grid, index) => {
                            return (
                                <>
                                    <div className="items-center hidden grid-cols-1 lg:grid lg:grid-cols-2">
                                        {!flip ? (
                                            index % 2 === 0 ? (
                                                <>
                                                    <div
                                                        className={`flex flex-col flex-1 w-full`}
                                                    >
                                                        <div className="flex flex-col lg:self-start bg-white px-6 lg:px-[7.777vw] ">
                                                            <p
                                                                className="mt-6 label--bold lg:mt-0 text-cobalt-100 wow fadeInUp"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]
                                                                                  ?.name
                                                                            : grid?.top_header
                                                                }}
                                                            ></p>
                                                            <h3
                                                                data-wow-delay="0.1s"
                                                                className="text-black-100 mt-[14px] lg:mt-3 wow fadeInUp "
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]?.[
                                                                                  'sub-title'
                                                                              ]
                                                                            : grid?.main_header
                                                                }}
                                                            ></h3>
                                                            <div
                                                                className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                                data-wow-delay="0.3s"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]
                                                                                  ?.content
                                                                            : grid?.content
                                                                }}
                                                            />
                                                            <LineCTA
                                                                title={
                                                                    grid?.use_post_content ===
                                                                    '1'
                                                                        ? posts[0]?.[
                                                                              'cta-text'
                                                                          ]
                                                                        : grid
                                                                              ?.cta
                                                                              ?.title
                                                                }
                                                                href={
                                                                    grid?.use_post_content ===
                                                                    '1'
                                                                        ? posts[0]
                                                                              ?.permalink
                                                                        : grid
                                                                              ?.cta
                                                                              ?.url
                                                                }
                                                                target={
                                                                    grid?.cta
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[22.5px]  lg:mb-0 mb-5'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                            {grid?.cta_2
                                                                ?.title && (
                                                                <LineCTA
                                                                    title={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.title
                                                                    }
                                                                    href={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.url
                                                                    }
                                                                    target={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.target
                                                                    }
                                                                    marginClass={
                                                                        'mt-[15.5px]  mb-5'
                                                                    }
                                                                    borderClass={
                                                                        'border--increase--cobalt'
                                                                    }
                                                                    textClassName={
                                                                        'text-black-100 group-hover:text-cobalt-100'
                                                                    }
                                                                />
                                                            )}
                                                            {grid?.cta_3
                                                                ?.title && (
                                                                <LineCTA
                                                                    title={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.title
                                                                    }
                                                                    href={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.url
                                                                    }
                                                                    target={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.target
                                                                    }
                                                                    marginClass={
                                                                        'mt-[15.5px]  mb-5'
                                                                    }
                                                                    borderClass={
                                                                        'border--increase--cobalt'
                                                                    }
                                                                    textClassName={
                                                                        'text-black-100 group-hover:text-cobalt-100'
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="relative !visible wow overflow-hidden">
                                                        <div className="rev-img-wrap relative z-[1]">
                                                            <div className="rev-img">
                                                                <div
                                                                    style={{
                                                                        // Adam image set
                                                                        backgroundImage:
                                                                            grid?.media_column ===
                                                                            'image'
                                                                                ? `url(${
                                                                                      actualWidth >
                                                                                      768
                                                                                          ? grid?.use_post_content ===
                                                                                            '1'
                                                                                              ? posts[0]
                                                                                                    ?.image
                                                                                                    ?.src
                                                                                              : grid
                                                                                                    ?.desktop_image
                                                                                                    ?.src
                                                                                          : grid?.use_post_content ===
                                                                                            '1'
                                                                                          ? posts[0]
                                                                                                ?.image
                                                                                                ?.src
                                                                                          : grid
                                                                                                ?.mobile_image
                                                                                                ?.src
                                                                                  })`
                                                                                : `url(${grid?.posterImage?.src})`
                                                                    }}
                                                                    className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                                />
                                                            </div>
                                                        </div>
                                                        {grid?.media_column !==
                                                        'image' ? (
                                                            <>
                                                                <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                                <div
                                                                    onClick={() =>
                                                                        grid?.media_column !==
                                                                            'image' &&
                                                                        handleItem(
                                                                            grid
                                                                        )
                                                                    }
                                                                    className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                                ></div>
                                                                <img
                                                                    className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                                    id="playVideoButton"
                                                                    onClick={() =>
                                                                        handleItem(
                                                                            grid
                                                                        )
                                                                    }
                                                                    src="/images/playIcon.png"
                                                                />
                                                            </>
                                                        ) : null}
                                                        <div
                                                            onClick={() =>
                                                                grid?.media_column !==
                                                                    'image' &&
                                                                handleItem(grid)
                                                            }
                                                            className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"
                                                        ></div>{' '}
                                                    </div>
                                                </>
                                            ) : (
                                                <>
                                                    <div className="relative !visible wow overflow-hidden">
                                                        <div className="rev-img-wrap relative z-[1]">
                                                            <div className="rev-img">
                                                                <div
                                                                    style={{
                                                                        // Adam image set
                                                                        backgroundImage:
                                                                            grid?.media_column ===
                                                                            'image'
                                                                                ? `url(${
                                                                                      actualWidth >
                                                                                      768
                                                                                          ? grid?.use_post_content ===
                                                                                            '1'
                                                                                              ? posts[0]
                                                                                                    ?.image
                                                                                                    ?.src
                                                                                              : grid
                                                                                                    ?.desktop_image
                                                                                                    ?.src
                                                                                          : grid?.use_post_content ===
                                                                                            '1'
                                                                                          ? posts[0]
                                                                                                ?.image
                                                                                                ?.src
                                                                                          : grid
                                                                                                ?.mobile_image
                                                                                                ?.src
                                                                                  })`
                                                                                : `url(${grid?.posterImage?.src})`
                                                                    }}
                                                                    className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                                />
                                                            </div>
                                                        </div>
                                                        {grid?.media_column !==
                                                        'image' ? (
                                                            <>
                                                                <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                                <div
                                                                    onClick={() =>
                                                                        grid?.media_column !==
                                                                            'image' &&
                                                                        handleItem(
                                                                            grid
                                                                        )
                                                                    }
                                                                    className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                                ></div>
                                                                <img
                                                                    className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                                    id="playVideoButton"
                                                                    onClick={() =>
                                                                        handleItem(
                                                                            grid
                                                                        )
                                                                    }
                                                                    src="/images/playIcon.png"
                                                                />
                                                            </>
                                                        ) : null}
                                                        <div
                                                            onClick={() =>
                                                                grid?.media_column !==
                                                                    'image' &&
                                                                handleItem(grid)
                                                            }
                                                            className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"
                                                        ></div>{' '}
                                                    </div>

                                                    <div
                                                        className={`flex flex-col flex-1 w-full`}
                                                    >
                                                        <div className="flex flex-col lg:self-start bg-white px-6 lg:px-[7.777vw] ">
                                                            <p
                                                                className="mt-6 label--bold lg:mt-0 text-cobalt-100 wow fadeInUp"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]
                                                                                  ?.name
                                                                            : grid?.top_header
                                                                }}
                                                            ></p>
                                                            <h3
                                                                data-wow-delay="0.1s"
                                                                className="text-black-100 mt-[14px] lg:mt-3  wow fadeInUp"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]?.[
                                                                                  'sub-title'
                                                                              ]
                                                                            : grid?.main_header
                                                                }}
                                                            ></h3>
                                                            <div
                                                                className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                                data-wow-delay="0.3s"
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        grid?.use_post_content ===
                                                                        '1'
                                                                            ? posts[0]
                                                                                  ?.content
                                                                            : grid?.content
                                                                }}
                                                            />
                                                            <LineCTA
                                                                title={
                                                                    grid?.use_post_content ===
                                                                    '1'
                                                                        ? posts[0]?.[
                                                                              'cta-text'
                                                                          ]
                                                                        : grid
                                                                              ?.cta
                                                                              ?.title
                                                                }
                                                                href={
                                                                    grid?.use_post_content ===
                                                                    '1'
                                                                        ? posts[0]
                                                                              ?.permalink
                                                                        : grid
                                                                              ?.cta
                                                                              ?.url
                                                                }
                                                                target={
                                                                    grid?.cta
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[22.5px]  lg:mb-0 mb-5'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                            {grid?.cta_2
                                                                ?.title && (
                                                                <LineCTA
                                                                    title={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.title
                                                                    }
                                                                    href={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.url
                                                                    }
                                                                    target={
                                                                        grid
                                                                            ?.cta_2
                                                                            ?.target
                                                                    }
                                                                    marginClass={
                                                                        'mt-[15.5px]  mb-5'
                                                                    }
                                                                    borderClass={
                                                                        'border--increase--cobalt'
                                                                    }
                                                                    textClassName={
                                                                        'text-black-100 group-hover:text-cobalt-100'
                                                                    }
                                                                />
                                                            )}
                                                            {grid?.cta_3
                                                                ?.title && (
                                                                <LineCTA
                                                                    title={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.title
                                                                    }
                                                                    href={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.url
                                                                    }
                                                                    target={
                                                                        grid
                                                                            ?.cta_3
                                                                            ?.target
                                                                    }
                                                                    marginClass={
                                                                        'mt-[15.5px]  mb-5'
                                                                    }
                                                                    borderClass={
                                                                        'border--increase--cobalt'
                                                                    }
                                                                    textClassName={
                                                                        'text-black-100 group-hover:text-cobalt-100'
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )
                                        ) : index % 2 === 0 ? (
                                            <>
                                                <div className="relative !visible wow overflow-hidden">
                                                    <div className="rev-img-wrap relative z-[1]">
                                                        <div className="rev-img">
                                                            <div
                                                                style={{
                                                                    // Adam image set
                                                                    backgroundImage:
                                                                        grid?.media_column ===
                                                                        'image'
                                                                            ? `url(${
                                                                                  actualWidth >
                                                                                  768
                                                                                      ? grid?.use_post_content ===
                                                                                        '1'
                                                                                          ? posts[0]
                                                                                                ?.image
                                                                                                ?.src
                                                                                          : grid
                                                                                                ?.desktop_image
                                                                                                ?.src
                                                                                      : grid?.use_post_content ===
                                                                                        '1'
                                                                                      ? posts[0]
                                                                                            ?.image
                                                                                            ?.src
                                                                                      : grid
                                                                                            ?.mobile_image
                                                                                            ?.src
                                                                              })`
                                                                            : `url(${grid?.posterImage?.src})`
                                                                }}
                                                                className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                            />
                                                        </div>
                                                    </div>
                                                    {grid?.media_column !==
                                                    'image' ? (
                                                        <>
                                                            <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                            <div
                                                                onClick={() =>
                                                                    grid?.media_column !==
                                                                        'image' &&
                                                                    handleItem(
                                                                        grid
                                                                    )
                                                                }
                                                                className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                            ></div>
                                                            <img
                                                                className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                                id="playVideoButton"
                                                                onClick={() =>
                                                                    handleItem(
                                                                        grid
                                                                    )
                                                                }
                                                                src="/images/playIcon.png"
                                                            />
                                                        </>
                                                    ) : null}
                                                    <div
                                                        onClick={() =>
                                                            grid?.media_column !==
                                                                'image' &&
                                                            handleItem(grid)
                                                        }
                                                        className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"
                                                    ></div>{' '}
                                                </div>
                                                <div
                                                    className={`flex flex-col flex-1 w-full`}
                                                >
                                                    <div className="flex flex-col lg:self-start bg-white px-6 lg:px-[7.777vw] ">
                                                        <p className="mt-6 label--bold lg:mt-0 text-cobalt-100 wow fadeInUp">
                                                            {' '}
                                                            {
                                                                grid?.top_header
                                                            }{' '}
                                                        </p>
                                                        <h3
                                                            data-wow-delay="0.1s"
                                                            className="text-black-100 mt-[14px] lg:mt-3  wow fadeInUp"
                                                        >
                                                            {' '}
                                                            {grid?.main_header}
                                                        </h3>
                                                        <div
                                                            data-wow-delay="0.3s"
                                                            className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    grid?.content
                                                            }}
                                                        />
                                                        {grid?.cta?.url &&
                                                        <LineCTA
                                                            title={
                                                                grid?.cta?.title
                                                            }
                                                            href={
                                                                grid?.cta?.url
                                                            }
                                                            target={
                                                                grid?.cta
                                                                    ?.target
                                                            }
                                                            marginClass={
                                                                'mt-[22.5px]  lg:mb-0 mb-5'
                                                            }
                                                            borderClass={
                                                                'border--increase--cobalt'
                                                            }
                                                            textClassName={
                                                                'text-black-100 group-hover:text-cobalt-100'
                                                            }
                                                        />
                                                        }
                                                        {grid?.cta_2?.title && (
                                                            <LineCTA
                                                                title={
                                                                    grid?.cta_2
                                                                        ?.title
                                                                }
                                                                href={
                                                                    grid?.cta_2
                                                                        ?.url
                                                                }
                                                                target={
                                                                    grid?.cta_2
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[22.5px]'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                        )}
                                                        {grid?.cta_3?.title && (
                                                            <LineCTA
                                                                title={
                                                                    grid?.cta_3
                                                                        ?.title
                                                                }
                                                                href={
                                                                    grid?.cta_3
                                                                        ?.url
                                                                }
                                                                target={
                                                                    grid?.cta_3
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[22.5px]'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    className={`flex flex-col flex-1 w-full`}
                                                >
                                                    <div className="flex flex-col lg:self-start bg-white px-6 lg:px-[7.777vw] ">
                                                        <p className="mt-6 label--bold lg:mt-0 text-cobalt-100 wow fadeInUp">
                                                            {' '}
                                                            {
                                                                grid?.top_header
                                                            }{' '}
                                                        </p>
                                                        <h3
                                                            data-wow-delay="0.1s"
                                                            className="text-black-100 mt-[14px] lg:mt-3  wow fadeInUp"
                                                        >
                                                            {' '}
                                                            {grid?.main_header}
                                                        </h3>
                                                        <div
                                                            data-wow-delay="0.3s"
                                                            className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    grid?.content
                                                            }}
                                                        />
                                                        {grid?.cta?.url &&
                                                        <LineCTA
                                                            title={
                                                                grid?.cta?.title
                                                            }
                                                            href={
                                                                grid?.cta?.url
                                                            }
                                                            target={
                                                                grid?.cta
                                                                    ?.target
                                                            }
                                                            marginClass={
                                                                'mt-[22.5px]  lg:mb-0 mb-5'
                                                            }
                                                            borderClass={
                                                                'border--increase--cobalt'
                                                            }
                                                            textClassName={
                                                                'text-black-100 group-hover:text-cobalt-100'
                                                            }
                                                        />
                                                        }
                                                        {grid?.cta_2?.title && (
                                                            <LineCTA
                                                                title={
                                                                    grid?.cta_2
                                                                        ?.title
                                                                }
                                                                href={
                                                                    grid?.cta_2
                                                                        ?.url
                                                                }
                                                                target={
                                                                    grid?.cta_2
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[15.5px]  mb-5'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                        )}
                                                        {grid?.cta_3?.title && (
                                                            <LineCTA
                                                                title={
                                                                    grid?.cta_3
                                                                        ?.title
                                                                }
                                                                href={
                                                                    grid?.cta_3
                                                                        ?.url
                                                                }
                                                                target={
                                                                    grid?.cta_3
                                                                        ?.target
                                                                }
                                                                marginClass={
                                                                    'mt-[15.5px]  mb-5'
                                                                }
                                                                borderClass={
                                                                    'border--increase--cobalt'
                                                                }
                                                                textClassName={
                                                                    'text-black-100 group-hover:text-cobalt-100'
                                                                }
                                                            />
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="relative !visible wow overflow-hidden">
                                                    <div className="rev-img-wrap relative z-[1]">
                                                        <div className="rev-img">
                                                            <div
                                                                style={{
                                                                    // Adam image set
                                                                    backgroundImage:
                                                                        grid?.media_column ===
                                                                        'image'
                                                                            ? `url(${
                                                                                  actualWidth >
                                                                                  768
                                                                                      ? grid?.use_post_content ===
                                                                                        '1'
                                                                                          ? posts[0]
                                                                                                ?.image
                                                                                                ?.src
                                                                                          : grid
                                                                                                ?.desktop_image
                                                                                                ?.src
                                                                                      : grid?.use_post_content ===
                                                                                        '1'
                                                                                      ? posts[0]
                                                                                            ?.image
                                                                                            ?.src
                                                                                      : grid
                                                                                            ?.mobile_image
                                                                                            ?.src
                                                                              })`
                                                                            : `url(${grid?.posterImage?.src})`
                                                                }}
                                                                className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                            />
                                                        </div>
                                                    </div>
                                                    {grid?.media_column !==
                                                    'image' ? (
                                                        <>
                                                            <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                            <div
                                                                onClick={() =>
                                                                    grid?.media_column !==
                                                                        'image' &&
                                                                    handleItem(
                                                                        grid
                                                                    )
                                                                }
                                                                className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                            ></div>
                                                            <img
                                                                className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                                id="playVideoButton"
                                                                onClick={() =>
                                                                    handleItem(
                                                                        grid
                                                                    )
                                                                }
                                                                src="/images/playIcon.png"
                                                            />
                                                        </>
                                                    ) : null}
                                                    <div
                                                        onClick={() =>
                                                            grid?.media_column !==
                                                                'image' &&
                                                            handleItem(grid)
                                                        }
                                                        className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"
                                                    ></div>{' '}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                    {/* Not flipped cols? */}
                                    <div className="grid items-center grid-cols-1 lg:hidden pt-16">
                                        <>
                                            <div className="relative !visible wow overflow-hidden">
                                                <div className="rev-img-wrap relative z-[1]">
                                                    <div className="rev-img">
                                                        <div
                                                            style={{
                                                                // Adam image set
                                                                backgroundImage:
                                                                    grid?.media_column ===
                                                                    'image'
                                                                        ? `url(${
                                                                              actualWidth >
                                                                              768
                                                                                  ? grid?.use_post_content ===
                                                                                    '1'
                                                                                      ? posts[0]
                                                                                            ?.image
                                                                                            ?.src
                                                                                      : grid
                                                                                            ?.desktop_image
                                                                                            ?.src
                                                                                  : grid?.use_post_content ===
                                                                                    '1'
                                                                                  ? posts[0]
                                                                                        ?.image
                                                                                        ?.src
                                                                                  : grid
                                                                                        ?.mobile_image
                                                                                        ?.src
                                                                          })`
                                                                        : `url(${grid?.posterImage?.src})`
                                                            }}
                                                            className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                                        />
                                                    </div>
                                                </div>
                                                {grid?.media_column !==
                                                'image' ? (
                                                    <>
                                                        <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                                        <div
                                                            onClick={() =>
                                                                grid?.media_column !==
                                                                    'image' &&
                                                                handleItem(grid)
                                                            }
                                                            className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20  cursor-pointer"
                                                        ></div>
                                                        <img
                                                            className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 -translate-x-1/2 text-white z-[1]"
                                                            id="playVideoButton"
                                                            onClick={() =>
                                                                handleItem(grid)
                                                            }
                                                            src="/images/playIcon.png"
                                                        />
                                                    </>
                                                ) : null}
                                                <div
                                                    onClick={() =>
                                                        grid?.media_column !==
                                                            'image' &&
                                                        handleItem(grid)
                                                    }
                                                    className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"
                                                ></div>{' '}
                                            </div>
                                            <div
                                                className={`flex flex-col flex-1 w-full`}
                                            >
                                                <div className="flex flex-col lg:self-start bg-white px-6 lg:px-[7.777vw] ">
                                                    <p className="mt-6 label--bold lg:mt-0 text-cobalt-100 wow fadeInUp">
                                                        {' '}
                                                        {grid?.top_header}{' '}
                                                    </p>
                                                    <h3
                                                        data-wow-delay="0.1s"
                                                        className="text-black-100 mt-[14px] lg:mt-3  wow fadeInUp"
                                                    >
                                                        {' '}
                                                        {grid?.main_header}
                                                    </h3>
                                                    <div
                                                        data-wow-delay="0.3s"
                                                        className="mt-4 lead--regular text-black-100 wow fadeInUp"
                                                        dangerouslySetInnerHTML={{
                                                            __html:
                                                                grid?.content
                                                        }}
                                                    />
                                                    {grid?.cta?.title &&
                                                    <LineCTA
                                                        title={grid?.cta?.title}
                                                        href={grid?.cta?.url}
                                                        target={
                                                            grid?.cta?.target
                                                        }
                                                        marginClass={
                                                            'mt-[22.5px]  lg:mb-0 mb-5'
                                                        }
                                                        borderClass={
                                                            'border--increase--cobalt'
                                                        }
                                                        textClassName={
                                                            'text-black-100 group-hover:text-cobalt-100'
                                                        }
                                                    />
                                                    }
                                                    {grid?.cta_2?.title && (
                                                        <LineCTA
                                                            title={
                                                                grid?.cta_2
                                                                    ?.title
                                                            }
                                                            href={
                                                                grid?.cta_2?.url
                                                            }
                                                            target={
                                                                grid?.cta_2
                                                                    ?.target
                                                            }
                                                            marginClass={
                                                                'mt-[15.5px]  mb-5'
                                                            }
                                                            borderClass={
                                                                'border--increase--cobalt'
                                                            }
                                                            textClassName={
                                                                'text-black-100 group-hover:text-cobalt-100'
                                                            }
                                                        />
                                                    )}
                                                    {grid?.cta_3?.title && (
                                                        <LineCTA
                                                            title={
                                                                grid?.cta_3
                                                                    ?.title
                                                            }
                                                            href={
                                                                grid?.cta_3?.url
                                                            }
                                                            target={
                                                                grid?.cta_3
                                                                    ?.target
                                                            }
                                                            marginClass={
                                                                'mt-[15.5px]  mb-5'
                                                            }
                                                            borderClass={
                                                                'border--increase--cobalt'
                                                            }
                                                            textClassName={
                                                                'text-black-100 group-hover:text-cobalt-100'
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    </div>
                                </>
                            );
                        })}
                    </div>
                    // END v2
                )}
            </div>
            <Transition
                show={modalState}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed items-center flex top-0 left-0 z-[999] w-full h-full min-h-screen bg-gray-dark">
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            setModalState(false);
                        }}
                        className="absolute  inline-flex items-center justify-center w-12 h-12 text-white cursor-pointer top-6 right-6 rounded-30   bg-gray  hover:bg-black-100 text-12 font-700 z-[3] transition-all transform  "
                    >
                        <i className="cp-icon-modalClose"></i>
                    </a>
                    <div className="flex-auto px-6 lg:px-40">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            className="aspect-w-16 aspect-h-9"
                            url={
                                activeGrid?.media_column === 'external_video'
                                    ? activeGrid?.desktopVideoUrl ||
                                      activeGrid?.mobileVideoUrl
                                    : activeGrid?.desktopVideo ||
                                      activeGrid?.mobileVideo
                            }
                            controls={true}
                        />
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default TwoColGrid;
