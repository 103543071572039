import React from 'react';

import NextNavLink from '../../util/NextNavLink';

export const LineCTA = ({
    title,
    textClassName,
    marginClass,
    borderClass,
    href = '/',
    noAction,
    onClick = () => {},
    target = ''
}) => {
    return (
        <div
            data-wow-offset="0"
            className={`flex  wow fadeInUp  items-center space-x-4 relative border--main group cursor-pointer xl:pr-[50px] ${marginClass}`}
        >
            {title ? (
                <div
                    className={`${
                        borderClass ? borderClass : 'border--increase'
                    } `}
                ></div>
            ) : null}

            {noAction ? (
                <div
                    className={`pl-12  transition-all duration-300 label--bold ${
                        textClassName
                            ? textClassName
                            : 'group-hover:text-sky-100'
                    }`}
                    onClick={() => onClick()}
                >
                    {title}
                </div>
            ) : title ? (
                <NextNavLink href={href} target={target}>
                    <a
                        aria-label={title}
                        role="button"
                        onClick={() => onClick()}
                        className={`pl-12  transition-all duration-300 label--bold ${
                            textClassName
                                ? textClassName
                                : 'group-hover:text-sky-100'
                        }`}
                    >
                        {title}
                    </a>
                </NextNavLink>
            ) : null}
        </div>
    );
};
