import React, { useState } from 'react';
import NextNavLink from '../../../util/NextNavLink';
import useWindowDimensions from '../../../../util/windowDimensions';
import { useRouter } from 'next/router';

const LevelCoachCards = ({ items, type, levels }) => {
    const [hover, setHover] = useState();
    const { width } = useWindowDimensions();
    const router = useRouter()
    return (
        <>
            <div className="flex flex-col self-center px-[36px] lg:px-0 lg:max-w-[38.88vw] wow fadeInUp">
                <h3 className="text-center text-black-100">
                    {levels?.find((elem) => elem?.slug === type)?.name +
                        ' Coaches'}{' '}
                </h3>
                <div
                    className="mt-4 text-center lead--regular text-black-100"
                    dangerouslySetInnerHTML={{
                        __html: levels?.find((elem) => elem?.slug === type)
                            ?.description
                    }}
                />
            </div>
            <div className="grid grid-cols-1 px-3 pt-12 pb-24 xl:px-0 md:grid-cols-2 lg:grid-cols-3 lg:pt-24 lg:pb-32 wow fadeInUp">
                {items
                    ?.filter?.((item) => item?.actualLevels?.includes(type))
                    ?.map((item, index) => {
                        return (
                            <div
                                key={item?.id}
                                className={`flex flex-col cursor-pointer relative   wow fadeInUp ${
                                    hover === index
                                        ? 'img-hover-zoom img-hover-zoom--quick-zoom'
                                        : ''
                                }  group`}
                                onMouseEnter={() =>
                                    width > 1024 ? setHover(index) : {}
                                }
                                onClick={() => router.push(`/coaches/${item?.slug}`)}
                                onMouseLeave={() => setHover('')}
                                onMouseDown={() =>
                                    width < 1024
                                        ? setHover(hover === index ? '' : index)
                                        : {}
                                }
                            >
                                {hover !== index ? (
                                    <h4 className="absolute z-10 px-8 tracking-tighter text-black-100 top-8 ">
                                        {item?.first_name}
                                    </h4>
                                ) : null}
                                <div
                                    className={`absolute flex flex-col z-10 ${
                                        hover === index
                                            ? ' bg-[rgba(0,0,0,0.6)]'
                                            : 'bg-black-100 opacity-0'
                                    } h-full top-0 left-0 w-full transition-all `}
                                >
                                    <div className="flex flex-col h-full p-8">
                                        <h4 className="tracking-tighter text-white ">
                                            {' '}
                                            {item?.first_name +
                                                ' ' +
                                                item?.last_name}{' '}
                                        </h4>
                                        <div className="flex items-center mt-2">
                                            {item?.locations?.map(
                                                (location, index) => {
                                                    return (
                                                        <div className="flex items-center lead--regular">
                                                            <p className="">
                                                                {' '}
                                                                {
                                                                    location?.name
                                                                }{' '}
                                                            </p>
                                                            {index !==
                                                            item?.locations
                                                                ?.length -
                                                                1 ? (
                                                                <span className="mx-1">
                                                                    /
                                                                </span>
                                                            ) : null}
                                                        </div>
                                                    );
                                                }
                                            )}
                                        </div>
                                        <div className="flex items-end justify-between h-full">
                                            <div className="flex-col hidden lg:flex">
                                                {item?.specialties?.map(
                                                    (speciality, index) => {
                                                        return (
                                                            <div
                                                                className="flex flex-col"
                                                                key={index}
                                                            >
                                                                <p className="label--smallAlt">
                                                                    {' '}
                                                                    {
                                                                        speciality?.name
                                                                    }{' '}
                                                                </p>
                                                                {index !==
                                                                item
                                                                    ?.specialties
                                                                    ?.length -
                                                                    1 ? (
                                                                    <div className="h-[1px] my-2 w-4 bg-white30" />
                                                                ) : null}
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                            <NextNavLink
                                                href={`coaches/${item?.slug}`}
                                                target={item?.cta?.target}
                                            >
                                                <a className="label--bold btn btn--sky  pt-[15px] pb-[13px]  flex items-center ">
                                                    <span>
                                                        Meet {item?.first_name}
                                                    </span>

                                                    <i
                                                        className="cp-icon-arraySmallLeft text-black-100 mb-[2px] text-9 leading-16 ml-3"
                                                        style={{
                                                            transform:
                                                                'rotate(180deg)'
                                                        }}
                                                    ></i>
                                                </a>
                                            </NextNavLink>
                                        </div>
                                    </div>
                                </div>
                                <div className="relative !visible wow overflow-hidden">
                                    <div className="rev-img-wrap relative z-[3]">
                                        <div className="rev-img">
                                            <img
                                                className={` w-full transition-all origin-center bg-center`}
                                                src={item?.image?.src}
                                                alt={item?.image?.alt}
                                            />
                                        </div>
                                    </div>
                                    <div className="absolute top-0 left-0 w-full h-full rev-bg "></div>{' '}
                                </div>
                            </div>
                        );
                    })}
            </div>
        </>
    );
};

export default LevelCoachCards;
