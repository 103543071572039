import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import { LineCTA } from '../CTA/LineCTA';

const Locations = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    return (
        <div
            id={props?.anchor}
            className={`wrapper pt-16 lg:pt-[135px] bg-white  grid gap-y-16  lg:gap-y-24  xl:gap-y-32 ${props?.className}`}
        >
            {props?.locations?.map((location, index) => {
                return index % 2 === 0 ? (
                    <div className="grid lg:grid-cols-2 ">
                        <img
                            className="w-full "
                            src={location?.post?.image?.src}
                            alt={location?.post?.image?.alt}
                        />
                        {/* <div
                            className="w-full min-h-[224px] sm:min-h-[50vw] lg:min-h-[448px] flex bg-no-repeat bg-cover"
                            style={{
                                backgroundImage: `url(${location?.post?.image?.src})`
                            }}
                        ></div> */}
                        <div
                            className={`flex items-center px-6 lg:px-28 pt-6 ${
                                index === props?.locations?.length - 1
                                    ? 'pb-0'
                                    : 'pb-5'
                            } lg:py-5`}
                        >
                            <div className="flex flex-col">
                                <p className="label--bold text-cobalt-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.['sub-title']}{' '}
                                </p>
                                <h3 className="mt-3 text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.name}{' '}
                                </h3>
                                <p className="mt-4 lead--regular text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.address}{' '}
                                </p>
                                <div className=" flex flex-col space-y-3 mt-[26.5px] wow fadeInUp">
                                    {location?.ctas?.map((item, index) => {
                                        return (
                                            <LineCTA
                                                title={item?.link?.title}
                                                href={item?.link?.url}
                                                target={item?.link?.target}
                                                marginClass={''}
                                                borderClass={
                                                    'border--increase--cobalt'
                                                }
                                                textClassName={
                                                    'text-black-100 group-hover:text-cobalt-100'
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                ) : actualWidth > 1023 ? (
                    <div className="grid lg:grid-cols-2 ">
                        <div className="flex items-center px-6 lg:px-28 pt-6 pb-[67.5px] lg:py-5">
                            <div className="flex flex-col">
                                <p className="label--bold text-cobalt-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.['sub-title']}{' '}
                                </p>
                                <h3 className="mt-3 text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.name}{' '}
                                </h3>
                                <p className="mt-4 lead--regular text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.address}{' '}
                                </p>
                                <div className=" flex flex-col space-y-3 mt-[26.5px] wow fadeInUp">
                                    {location?.ctas?.map((item, index) => {
                                        return (
                                            <LineCTA
                                                title={item?.link?.title}
                                                href={item?.link?.url}
                                                target={item?.link?.target}
                                                marginClass={''}
                                                borderClass={
                                                    'border--increase--cobalt'
                                                }
                                                textClassName={
                                                    'text-black-100 group-hover:text-cobalt-100'
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                        <img
                            className="w-full "
                            src={location?.post?.image?.src}
                            alt={location?.post?.image?.alt}
                        />

                        {/* <div
                            className="w-full lg:min-h-[448px] flex bg-no-repeat bg-cover"
                            style={{
                                backgroundImage: `url(${location?.post?.image?.src})`
                            }}
                        ></div> */}
                    </div>
                ) : (
                    <div className="grid lg:grid-cols-2 ">
                        <img
                            className="w-full"
                            src={location?.post?.image?.src}
                            alt={location?.post?.image?.alt}
                        />
                        {/* <div
                    className="w-full min-h-[224px] lg:min-h-[448px] flex bg-no-repeat bg-cover"
                    style={{
                        backgroundImage: `url(${location?.post?.image?.src})`
                    }}
                ></div> */}
                        <div
                            className={`flex items-center px-6 lg:px-28 pt-6  ${
                                index === props?.locations?.length - 1
                                    ? 'pb-0'
                                    : 'pb-5'
                            }   lg:py-5`}
                        >
                            <div className="flex flex-col">
                                <p className="label--bold text-cobalt-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.['sub-title']}{' '}
                                </p>
                                <h3 className="mt-3 text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.name}{' '}
                                </h3>
                                <p className="mt-4 lead--regular text-black-100 wow fadeInUp">
                                    {' '}
                                    {location?.post?.address}{' '}
                                </p>
                                <div className=" flex flex-col space-y-3 mt-[26.5px] wow fadeInUp">
                                    {location?.ctas?.map((item, index) => {
                                        return (
                                            <LineCTA
                                                title={item?.link?.title}
                                                href={item?.link?.url}
                                                target={item?.link?.target}
                                                marginClass={''}
                                                borderClass={
                                                    'border--increase--cobalt'
                                                }
                                                textClassName={
                                                    'text-black-100 group-hover:text-cobalt-100'
                                                }
                                            />
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

export default Locations;
