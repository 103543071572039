import React, { useContext, useState, useEffect } from 'react';
import ThreeColGrid from '../../components/chelsea/CTA/ThreeColGrid';
import { LocationContext } from '../../util/LocationProvider';
import { extractRepeaterField } from '../../util/blocks';

function ACFThreeColGrid({ data, meta, pageProps, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <ThreeColGrid
                className={className}
                anchor={anchor}
                data={data} 
                videos={pageProps?.componentQueries?.videos}
                meta={meta} />
        }
    </>);
}

export default ACFThreeColGrid;