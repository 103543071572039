import React, { useState, useEffect } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';

export const ImageWithCTA = (props) => {
    const { width } = useWindowDimensions();

    const [actualWidth, setActualWidth] = useState();

    useEffect(() => {
        setActualWidth(width);
    }, [width]);

    return (
        <div
            className={`wrapper bg-white flex flex-col items-center pt-[64px] space-y-10 lg:space-y-24  lg:pt-[128px] wow fadeInUp ${props?.className}`}
            id={props?.anchor}
        >
            <img
                className="px-3 lg:px-0"
                src={
                    actualWidth > 1024
                        ? props?.desktop_cta_image?.src
                        : props?.mobile_cta_image?.src
                }
                alt={
                    width > 1024
                        ? props?.desktop_cta_image?.alt
                        : props?.mobile_cta_image?.alt
                }
            />
            <img
                className="px-3 lg:px-[7.777vw] wow fadeInUp "
                src={
                    actualWidth > 1024
                        ? props?.desktop_image?.src
                        : props?.mobile_image?.src
                }
                alt={
                    width > 1024
                        ? props?.desktop_image?.alt
                        : props?.mobile_image?.alt
                }
            />
        </div>
    );
};
