import { useContext, useState, useEffect } from 'react';
import AppPromo from '../../components/chelsea/Promo/AppPromo'
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

const ACFAppPromo = ({data,meta,pageProps, className='',anchor=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const appPromo = pageProps?.defaults?.appPromo?.acf_app_promo;


    let appLinks = extractRepeaterField(data,'app_links');


    appLinks = appLinks.map((repeaterItem) => ({
        ...repeaterItem,
        link_icon: meta?.icons?.[repeaterItem?.icon?.[0]]?.class,
    }));




    return (<>
        {show && 
            <AppPromo 
                className={className}
                anchor={anchor}
                default={data?.use_block_default_data === "1"}
                ctas={data?.use_block_default_data === "1" ? appPromo?.appLinks : appLinks}
                title={data?.use_block_default_data === "1" ? appPromo?.title: data?.title}
                subTitle={data?.use_block_default_data === "1" ? appPromo?.subTitle:data?.sub_title }
                contentLink={data?.use_block_default_data === "1" ? appPromo?.contentLink: data?.content_link}
                desktop_image = {data?.use_block_default_data === "1"? appPromo?.appImageDesktop?.sourceUrl : meta?.images?.app_image_desktop }
                mobile_image = {data?.use_block_default_data === "1"? appPromo?.appImageMobile?.sourceUrl : meta?.images?.app_image_mobile }
                logo = {data?.use_block_default_data === "1"? appPromo?.logo?.sourceUrl : meta?.images?.logo }
                content= {data?.use_block_default_data === "1"? appPromo?.content : data?.content}
            />
        }
    </>);
}

export default ACFAppPromo
