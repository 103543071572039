import  { useContext, useState, useEffect } from 'react';
import TwoColImageCTA from '../../components/chelsea/CTA/TwoColImageCTA';
import { LocationContext } from '../../util/LocationProvider';

const ACFCtaTwoColGrid = ({data, meta, className='',anchor=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    const [filtered, setFiltered] = useState(data);

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
        setFiltered(LocationManager?.applyFilters(data)); // Apply placeholders.
        // console.dir(data,{depth:null});
    }, [locale, setShow, LocationManager, data]);


    return (
        <>{show &&
            <TwoColImageCTA 
             className={className}
             anchor={anchor}
                colorize={data?.colorize}
                image={Object.values(meta?.images)}
                content={filtered?.content}
                content_link={filtered?.content_link}
                cta1={filtered?.ctas_0_cta}
                cta2={filtered?.ctas_1_cta}
                heading={filtered?.header_text}
                subHeading={filtered?.sub_header}
                bigTitle={filtered?.big_title}
            />
        }</>
    );
}

export default ACFCtaTwoColGrid
