import React, { useState } from 'react';
import ReactPlayer from 'react-player';
import { LineCTA } from '../CTA/LineCTA';
import { Transition } from '@headlessui/react';
import NextNavLink from '../../util/NextNavLink';

const FeaturedClass = (props) => {
    const [modalState, setModalState] = useState(false);

    return (
        <>
            <div
                className={`wrapper bg-black-100 ${props?.className}`}
                id={props?.anchor}
            >
                <div className="lg:pt-[128px] lg:pb-[125.5px] pt-[64.5px] pb-[65.5px]">
                    <div className="flex flex-col lg:grid lg:grid-cols-2 ">
                        <div
                            className="flex flex-col w-full  mt-[41.5px] lg:mt-0 mainCardWrapper group cursor-pointer"
                            onClick={() => setModalState(true)}
                        >
                            <div className="relative overflow-hidden !visible wow">
                                <div className="rev-img-wrap relative z-[1]">
                                    <div className="rev-img ">
                                        <div
                                            style={{
                                                backgroundImage: `url(${props?.images?.class_image_desktop})`
                                            }}
                                            className="w-full bg-center bg-no-repeat bg-cover aspect-w-3 aspect-h-2"
                                        />
                                    </div>
                                </div>
                                <div className="absolute top-0 left-0 w-full h-full rev-bg bg-gray-18"></div>
                                <div className="absolute top-0 left-0 w-full  h-full z-[1] transition-all group-hover:bg-black-20 "></div>
                                <img
                                    className="absolute block top-[50%] transform -translate-y-1/2 cursor-pointer  left-1/2 text-white z-[1]"
                                    id="playVideoButton"
                                    onClick={() => setModalState(true)}
                                    src="/images/playIcon.png"
                                />
                            </div>

                            <div className="flex flex-col z-[2] ">
                                <div className="relative flex flex-col justify-end ">
                                    <div className="flex flex-col max-w-[93%] lg:max-w-[83%] w-[93%] lg:w-[83%] self-end bg-black-100 px-6 pt-6 relative z-[1]  mt-[-14%] lg:mt-[-10%]  ">
                                        <p
                                            className="label--bold text-sky-100 wow fadeInUp"
                                            dangerouslySetInnerHTML={{
                                                __html: props?.video_sub_title
                                            }}
                                        ></p>
                                        <h4
                                            className="text-white mt-[14px] mt-4 lg:mt-3 wow fadeInUp "
                                            dangerouslySetInnerHTML={{
                                                __html: props?.video_title
                                            }}
                                        ></h4>

                                        <LineCTA
                                            title={'Watch now'}
                                            noAction
                                            onClick={() => setModalState(true)}
                                            marginClass={
                                                'mt-[18.5px] lg:mt-[22.5px]'
                                            }
                                            borderClass={'border--increase'}
                                            textClassName={
                                                'text-white group-hover:text-sky-100'
                                            }
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div className="flex flex-col pl-[10.55vw]  items-start   pr-[5vw] order-first lg:order-last ">
                            {props?.images?.logo && 
                                <img
                                    width={54}
                                    height={32}
                                    src={props?.images?.logo}
                                    className="wow fadeInUp"
                                />
                            }
                            <p className="mt-5 label--largeBold text-sky-100 lg:mt-8 wow fadeInUp">
                                {' '}
                                {props?.subTitle}
                            </p>
                            <h2 className="mt-4 text-white lg:mt-6 wow fadeInUp">
                                {' '}
                                {props?.title}{' '}
                            </h2>
                            <div
                                className="mt-5 lead--large lg:mt-8 wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: props?.content
                                }}
                            />
                            {props?.cta?.url &&
                                <NextNavLink
                                    href={props?.cta?.url}
                                    target={props?.cta?.target}
                                >
                                    <a className="btn btn--cobalt label--bold w-[200px] justify-self-center self-center lg:self-start lg:label--largeBold my-[15px] pt-[15px] pb-[13px] lg:pt-[21px] lg:pb-[19px] ">
                                        {props?.cta?.title}
                                    </a>
                                </NextNavLink>
                            }       
                            {/* {props?.cta?.url && 
                                <LineCTA
                                    title={props?.cta?.title}
                                    href={props?.cta?.url}
                                    target={props?.cta?.target}
                                    marginClass={'mt-[30.5px] lg:mt-[22.5px]'}
                                    borderClass={'border--increase'}
                                    textClassName={
                                        'text-white group-hover:text-sky-100'
                                    }
                                />
                            } */}
                        </div>
                    </div>
                </div>
            </div>
            <Transition
                show={modalState}
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="fixed items-center flex top-0 left-0 z-[999] w-full h-full min-h-screen bg-gray-dark">
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            setModalState(false);
                        }}
                        className="absolute  inline-flex items-center justify-center w-12 h-12 text-white cursor-pointer top-6 right-6 rounded-30   bg-gray  hover:bg-black-100 text-12 font-700 z-[3] transition-all transform  "
                    >
                        <i className="cp-icon-modalClose"></i>
                    </a>
                    <div className="flex-auto px-6 lg:px-40">
                        <ReactPlayer
                            width="100%"
                            height="100%"
                            className="aspect-w-16 aspect-h-9"
                            url={
                                props?.external_source_video === '1'
                                    ? props?.video_url_desktop
                                    : props?.videos?.video_desktop
                            }
                            controls={true}
                        />
                    </div>
                </div>
            </Transition>
        </>
    );
};

export default FeaturedClass;
