import React, { useContext, useState, useEffect } from 'react';
import ReactPlayer from 'react-player';
import { LocationContext } from '../../util/LocationProvider';

function CoreEmbed({ url, type, anchor='', className='', data=false }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }

    return (<>
        {show &&
            <section className="bg-white wrapper text-black-100">
                <div className="px-6 lg:px-28">
                    <div className={`relative z-[3] bg-white py-2 max-w-2xl ${className}`}>
                        {url && (
                            <ReactPlayer
                                className="aspect-h-9 aspect-w-16 video-player"
                                url={url}
                                controls={true}
                            />
                        )}
                    </div>
                </div>
            </section>
        }
    </>);
}

export default CoreEmbed;
