import React, { useEffect, useRef, useState } from 'react';
import { useRouter } from 'next/router';
import { Transition } from '@headlessui/react';
import NextNavLink from '../../util/NextNavLink';
import MobileFilter from './eventsComponent/MobileFilter';

export const Events = ({
                           events,
                           locations,
                           categories,
                           className,
                           anchor,
                           title,
                           pagination,
                       }) => {
    const [locationsFilter, setLocationsFilter] = useState();
    const [categoriesFilter, setCategoriesFilter] = useState();
    const [activeLocation, setActiveLocation] = useState('all');
    const [activeCategory, setActiveCategory] = useState('all');
    const [filterableEvents, setFilterableEvents] = useState();
    const [finalEventsToRender, setFinalEventsToRender] = useState();
    const [totalPages, setTotalPages] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [currentlyRenderedEvents, setCurrentlyRenderedEvents] = useState();
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const [show, setShow] = useState('');
    const mobileRef = useRef();

    const router = useRouter();

    useEffect(() => {
        const mapping = {
            'Chelsea': 'chelsea',
            'Brooklyn': 'downtown-brooklyn',
            'Prospect-Heights': 'prospect-heights',
            'Stamford': 'stamford-ct', 
        };

        if (router?.query?.facility) {
            let slug = mapping[router.query.facility];
            if (slug) {
                setActiveLocation(slug);
            }
        }
    }, [locationsFilter]); // Watch for locationsFilter to update.

    useEffect(() => {
        let tempLocation = [];
        let finalLocationCount = 0;
        locations?.forEach((location, index) => {
            tempLocation.push(location);
            finalLocationCount = finalLocationCount + location?.count;
        });
        tempLocation = [
            { name: 'All', slug: 'all', count: finalLocationCount },
            ...tempLocation
        ];
        setLocationsFilter(tempLocation);
    }, [locations]);

    useEffect(() => {
        //locations

        let tempCategory = [];

        let items = Object.keys(categories);
        let values = Object.values(categories);

        let newCategories = [];
        items?.map((item, index) => {
            let object = {};
            object.name = values[index];
            object.slug = item;
            newCategories.push(object);
        });
        tempCategory = [{ name: 'All', slug: 'all' }, ...newCategories];
        setCategoriesFilter(tempCategory);
    }, [categories]);


    useEffect(() => {
        let finalEvents = [];
        let currentYear = new Date().getFullYear();
        events?.forEach((event, index) => {
            if(parseInt(event?.['date_time']?.substring(6,10)) >= parseInt(currentYear)) {
                let object = {};
                object = { ...event };
                object.actualCategories = [
                    event?.who_can_participate === 'members_guest'
                        ? 'members_only'
                        : event?.who_can_participate
                ];
                object.actualLocations = event?.locations?.map(
                    (location) => location?.slug
                );

                finalEvents.push(object);
            }
        });
        finalEvents = removePastEvents(finalEvents);
        setFilterableEvents(finalEvents);
    }, [events]);

    useEffect(() => {
        filterEvents();
    }, [activeLocation, activeCategory, filterableEvents]);

    const filterEvents = () => {
        let finalFilteredEvents = filterableEvents;

        if (activeLocation !== 'all' && activeCategory !== 'all') {
            let events = finalFilteredEvents?.filter(
                (events) =>
                    events?.actualLocations?.includes(activeLocation) &&
                    events?.who_can_participate === activeCategory
            );
            setFinalEventsToRender(events);
            setTotalPages(Math.ceil(events?.length / pagination));
        } else if (activeLocation !== 'all' && activeCategory === 'all') {
            let events = finalFilteredEvents?.filter((events) =>
                events?.actualLocations?.includes(activeLocation)
            );
            setFinalEventsToRender(events);
            setTotalPages(Math.ceil(events?.length / pagination));
        } else if (activeLocation === 'all' && activeCategory !== 'all') {
            let events = finalFilteredEvents?.filter((events) =>
                events?.who_can_participate === activeCategory
            );
            setFinalEventsToRender(events);
            setTotalPages(Math.ceil(events?.length / pagination));
        } else {
            setFinalEventsToRender(finalFilteredEvents);
            setTotalPages(Math.ceil(finalFilteredEvents?.length / pagination));
        }
    };

    useEffect(() => {
        let events = finalEventsToRender?.slice?.(0, pagination * currentPage);
        setCurrentlyRenderedEvents(events);
    }, [finalEventsToRender, currentPage]);

    const loadMore = () => {
        setCurrentPage(currentPage + 1);
    };

    const getHumanCategory = (string) => {
        if (string === 'members_only') {
            return 'Members Only';
        } else if (string === 'open_to_public') {
            return 'Open To The Public';
        } else if (string === 'members_guest') {
            return 'Member + Guest';
        } else {
            return string;
        }
    };

    const removePastEvents = (events) => {
        const today = new Date();
        // Filter out events that are in the past
        return events?.filter((event) => {
            // The date_time string is formatted as day/month/year and the date object expects month/day/year
            // So we need to reformat the string to that format
            const [day, month, year] = event.date_time.split('/');
            const reformattedDate = `${month}/${day}/${year}`;
            const eventDate = new Date(reformattedDate);
            return today < eventDate;
        });
    }

    return (
        <div
            id={anchor}
            className={` xl:px-[3.333%] pl-3  bg-white ${className}`}
        >
            <div className="pt-16 pb-16 lg:pt-32 lg:pb-24 flex flex-col ">
                <div className="px-6 lg:px-0 lg:text-center text-black-100 text-center h3" dangerouslySetInnerHTML={{__html:title}} />
                <div className="pt-12 lg:hidden">
                    <div
                        className={`flex mx-auto  items-center justify-center btn ${
                            activeLocation !== "all" || activeCategory !== "all"
                                ? 'btn--gray'
                                : 'btn--lightGray'
                        }    pt-[15px] w-min `}
                        onClick={() => {
                            setShowMobileFilter(true);
                        }}
                    >
                        <i className={`  cp-icon-Filter text-20 mb-1 mr-3 leading-16 transform  font-700`} />
                        <span className="transition-all duration-300 label--bold ">
                        Filter
                    </span>
                    </div>

                    <Transition
                        show={showMobileFilter}
                        ref={mobileRef}
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div
                            className="bg-[#1A1A1A] fixed top-0 left-0 h-screen z-100 w-[100vw]"
                            style={{ zIndex: 100 }}
                        >
                            <div className="flex flex-col">
                                <div className=" lg:hidden flex items-center justify-between w-full  h-[64px] max-h-[64px] border-b border-grid   ">
                                    <div className="flex items-center justify-items-start w-full border-r border-grid   h-full min-w-[264px]">
                                        <h4 className="px-6">Filter</h4>
                                    </div>
                                    <div className="flex w-full items-center justify-center h-full min-w-[96px] max-w-[96px]">
                                        <div
                                            className="flex items-center justify-center w-12 h-12 my-2 bg-gray "
                                            style={{ borderRadius: '50%' }}
                                            onClick={() =>
                                                setShowMobileFilter(false)
                                            }
                                        >
                                            {showMobileFilter ? (
                                                <i className="text-white  cp-icon-modalClose text-14"/>
                                            ) : (
                                                <i className="text-white  cp-icon-Menu text-16"/>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <MobileFilter
                                    closeMobileFilter={() =>
                                        setShowMobileFilter(false)
                                    }
                                    getActiveLocationFilters={(item) =>
                                        setActiveLocation(item)
                                    }
                                    getActiveSpecialityFilters={(items) =>
                                        setActiveCategory(items)
                                    }
                                    currentActiveLocation={activeLocation}
                                    currentActiveSpeciality={activeCategory}
                                    showMobileFilter={showMobileFilter}
                                    locationsFilter={locationsFilter}
                                    specialityFilter={categoriesFilter}
                                />
                            </div>
                        </div>
                    </Transition>
                </div>

                <div className="mt-8 lg:mt-14 pl-6  lg:px-[17.222vw] hidden lg:flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
                    <p className="lead-regular text-black-100 min-w-[72px]">
                        {' '}Location{' '}
                    </p>
                    <div className="block overflow-auto whitespace-nowrap md:whitespace-normal md:flex md:items-center space-x-2 hideScrollBar">
                        {locationsFilter?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        setActiveLocation(item?.slug);
                                        setCurrentPage(1);
                                    }}
                                    className={`btn ${
                                        activeLocation === item?.slug
                                            ? 'bg-gray'
                                            : ' btn--lightGray'
                                    } label--smallBold px-4 pt-[7.5px] pb-[6.5px] inline-block `}
                                    key={index}
                                >
                                    {item?.name}
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="mt-8 lg:mt-6  pl-6  lg:px-[17.222vw] hidden lg:flex flex-col lg:flex-row lg:items-center space-y-4 lg:space-y-0 lg:space-x-4">
                    <p className="lead-regular text-black-100 min-w-[72px]">
                        {' '}Category{' '}
                    </p>
                    <div className="block overflow-auto whitespace-nowrap md:whitespace-normal md:flex md:items-center space-x-2 hideScrollBar">
                        {categoriesFilter?.map((item, index) => {
                            return (
                                <div
                                    onClick={() => {
                                        setActiveCategory(item?.slug);
                                        setCurrentPage(1);
                                    }}
                                    className={`btn label--smallBold ${
                                        activeCategory === item?.slug
                                            ? 'bg-gray'
                                            : ' btn--lightGray'
                                    } px-4 pt-[7.5px] pb-[6.5px] inline-block`}
                                    key={`${item?.slug}-${index}`}
                                >
                                    {item?.name}
                                </div>
                            );
                        })}
                    </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 pr-9 pl-6 lg:px-6 gap-x-12 mt-12 lg:mt-[64px]">

                    {/* If there are no events for the month, display a message indicating that */}
                    {currentlyRenderedEvents <= 0 ? (
                        <div className="m-auto text-center text-gray-dark w-full col-span-full">
                            There are no events currently scheduled that match the selected filters.
                        </div>
                    ) : null }

                    {currentlyRenderedEvents?.map((item, index) => {
                        return (
                            <div
                                className="flex flex-col pb-[66px] lg:pb-24"
                                key={`${item?.id}-${index}`}
                            >
                                <div className="   bg-[#f5f5f5] w-full">
                                    <img
                                        className="w-full"
                                        src={item?.image?.src}
                                    />
                                </div>
                                <p className="text-black-60 label--bold mt-6">
                                    {' '}
                                    {getHumanCategory(
                                        item?.who_can_participate
                                    )}{' '}
                                </p>
                                <div className="mt-3 text-black-100 h4" dangerouslySetInnerHTML={{__html:item?.name}}/>
                                <p className="text-cobalt-100 lead--regular mt-4 lg:mt-4">
                                    {' '}
                                    {item?.date_display}{' '}
                                </p>
                                <p className="text-black-100 lead--regular mt-1 lg:mt-1">
                                    {' '}
                                    {item?.address}{' '}
                                </p>
                                {item?.content || item?.additional_notes ? (
                                    <Transition
                                        show={show === index}
                                        enter="transition duration-1000 ease-out"
                                        enterFrom=" scale-95 opacity-0"
                                        enterTo=" scale-100 opacity-100"
                                        leave="transition duration-75 ease-out"
                                        leaveFrom=" scale-100 opacity-1000"
                                        leaveTo=" scale-95 opacity-0"
                                    >
                                        <div
                                            className="text-black-100 text-opacity-70 mt-5"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.content
                                            }}
                                        />
                                        {item?.additional_notes ? (
                                            <hr className="max-w-[48px] text-black-30 my-5" />
                                        ) : null}
                                        <div
                                            className="text-black-100 text-opacity-70 mt-5"
                                            dangerouslySetInnerHTML={{
                                                __html: item?.additional_notes
                                            }}
                                        />
                                    </Transition>
                                ) : null}

                                <div
                                    className={`flex items-center mt-7 lg:mt-7 space-x-3 ${
                                        item?.registration_open ? 'sm:space-x-6' : 'sm:space-x-3'
                                    }`}
                                >
                                    {item?.registration_open ? (
                                        <NextNavLink
                                            href={item?.cta?.url}
                                            target={item?.cta?.target}
                                        >
                                            <a className="label--bold  btn btn--cobalt text-white max-h-[48px] max-w-[120px] pt-[15px] pb-[13px]  lg:max-w-[120px] w-full h-full">
                                                {item?.cta?.title}
                                            </a>
                                        </NextNavLink>
                                    ) : (
                                        <div className="flex items-center ">
                                            <p className="text-black-60 lead--regular">
                                                {' '}
                                                {
                                                    item?.registration_unavailable_text
                                                }
                                            </p>
                                            <p className="text-black-60 lead--regular ml-3 mb-1">
                                                {' '}
                                                .{' '}
                                            </p>
                                        </div>
                                    )}

                                    <div
                                        className={`  btn btn--white flex items-center justify-between max-w-[147px] pt-[15px]    w-full`}
                                        onClick={() =>
                                            setShow(show === index ? '' : index)
                                        }
                                    >
                                        <span className="lead--regular text-16 transition-all duration-300 ">
                                            {show === index ? 'Less Info' : 'More Info'}
                                        </span>
                                        <i
                                            className={`  cp-icon-arraySmallLeft text-9 leading-16 ${
                                                show === index ? ' rotate-90 ' : ' -rotate-90'
                                            } transform  font-700`}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {currentPage < totalPages ? (
                    <div
                        className="btn btn--black flex items-center justify-center self-center lg:mt-8 cursor-pointer  pt-[15px]    max-w-[150px] "
                        onClick={() => loadMore()}
                    >
                        <button className="label--bold focus:outline-none">
                            Load More
                        </button>
                        <img
                            className="ml-3 mb-[2px]"
                            src="/logos/loadMore.svg"
                            width={10}
                        />
                    </div>
                ) : null}
            </div>
        </div>
    );
};
