import React from 'react';
import NextNavLink from '../../util/NextNavLink';

const FooterCTA = (props) => {
    return (
        <div
            className={`wrapper w-full ${props?.className}`}
            id={props?.anchor}
            style={{
                backgroundImage: `url(${
                    props?.image?.[0]?.src || props?.image
                })`,
                backgroundRepeat:"no-repeat",
                backgroundPosition:"center",
                backgroundSize:"cover"
            }}
        >
            <div className="grid lg:grid-cols-2 h-full items-center py-16 lg:pt-[122px] lg:pb-[134px]">
                <h2 className="text-center text wow fadeInLeft ">
                    {' '}
                    {props?.heading}{' '}
                </h2>
                <div className="flex flex-col items-center px-3 mt-16 space-y-4 lg:space-y-0 lg:px-0 wow lg:flex-row  lg:mt-0  fadeInRight ">
                {props?.ctas?.map((item, index) => {
                        return (
                            <div className="lg:w-[50%] xl:w-[33.333%] min-w-[288px] lg:min-w-0 lg:px-[1.7857%]">
                            <NextNavLink
                                href={item?.cta?.url}
                                target={item?.cta?.target}
                            >
                                <a
                                    className={`label--bold btn ${
                                        item?.color === 'black'
                                            ? 'btn--black'
                                            : item?.color === 'dark_blue'
                                            ? 'btn--cobalt'
                                            : 'btn--sky'
                                    }  pt-[15px] pb-[13px] w-full h-full`}
                                >
                                    {item?.cta?.title}
                                </a>
                            </NextNavLink>
                            </div>
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FooterCTA;
