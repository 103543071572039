import  { useContext, useState, useEffect } from 'react';
import WaterHazardReservation from '../../components/chelsea/CTA/WaterHazardReservation';
import { LocationContext } from '../../util/LocationProvider';

const ACFWaterHazardReservation = ({ data, meta, className='', anchor='' }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <WaterHazardReservation
                className={className}
                anchor={anchor}
                data={data}
                meta={meta} />
        }
    </>);
};

export default ACFWaterHazardReservation;
