import React, { useState, useContext, useEffect } from 'react';
import SportCoachCards from './coachesComponent/SportCoachCards';
import { LocationContext } from '../../../util/LocationProvider';

export default function CoachesSports(props) { 
    let LocationManager = useContext(LocationContext);
    let locale = LocationManager.getLocation();
    const locales = {
        'chelsea': 'Chelsea Piers, NY',
        'stamford': 'Stamford, CT',
        'brooklyn': 'Brooklyn, NY',
    };

    const coaches = props?.coaches ? props?.coaches : [];

    const [hiddenLoc, setHiddenLoc] = useState(true);
    const [hiddenSport, setHiddenSport] = useState(true);

    const [filterLocation, setFilterLocation] = useState(locale);
    const [filterSport, setFilterSport] = useState([]);

    const [optionsLocation, setOptionsLocation] = useState(LocationManager?.labels);
    const [optionsSport, setOptionsSport] = useState([]);

    useEffect(() => {
        let sports = [];
        coaches.forEach((coach) => { // Loop through coaches for unique sports. 
            const _coach = coach?.node?.acf_coaches;
            const _locale = locales[locale];
            
            let localCoach = false;
            // Check if coach is in this locale.
            if (_coach?.locale == _locale || _coach?.otherLocales?.includes(_locale)) {
                localCoach = true;
            }
            // Coach not for this location.
            if (!localCoach) return;

            // Yes? Make sure their sports are represented in the dropdown.
            _coach?.sports.forEach((sport) => {
                if (!sports.includes(sport?.title)) {
                    sports.push(sport?.title);
                }
            });
        });

        setOptionsSport(sports.sort());
    }, []);
    

    const coachesForSport = (sport) => { // Get coaches for a sport.
        let sportCoaches = [];

        coaches.forEach((coach) => { 
            const _coach = coach?.node?.acf_coaches;
            const _locale = locales[locale];
            
            let localCoach = false;
            // Check if coach is in this locale.
            if (_coach?.locale == _locale || _coach?.otherLocales?.includes(_locale)) {
                localCoach = true;
            }
            // Coach not for this location.
            if (!localCoach) return;
            
            // if (!coach?.node?.featuredImage) return; // Skip coaches that won't display because of a blank image.
            
            _coach?.sports.forEach((_sport) => { 
                if (_sport?.title == sport) { 
                    sportCoaches.push(coach?.node);
                    return;
                }
            });
        });
        
        return sportCoaches.sort((a,b) => (a?.acf_coaches?.firstName>b?.acf_coaches?.firstName) ? 1 : -1);
    };

    const toggleSport = (sport, e) => {
        let sportFilter = [...filterSport];
        if (!sportFilter.includes(sport)) {
            sportFilter.push(sport);
            e.currentTarget.classList.add('active');
        } else {
            sportFilter = sportFilter.filter(d => d !== sport);
            e.currentTarget.classList.remove('active');
        }
        setFilterSport(sportFilter);
    };

    const isFilterActive = (filter, value) => {
        let filterCheck = false;

        if (Array.isArray(filter)) {
            filterCheck = (filter.includes(value) ? 'active' : '');
        } else {
            filterCheck = (filter==value ? 'active': '');
        }
        return filterCheck;
    };
    
    return (<>
        <div component="CoachesSport" className={`xl:px-[3.333%] bg-white`}>

            <div className="flex-col pt-16 flex wow fadeInUp  to-animate"  animated="true">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">

                    <div className="flex flex-col lg:w-full xl:px-[1.3393%] px-3  relative">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenSport(false)}>
                            <span className="transition-all duration-100 label--bold ">Sport</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16  -rotate-90 transform   font-700"></i>
                        </div>
                        {!hiddenSport ? 
                            <div 
                                onMouseLeave={() => setHiddenSport(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-[11] transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i onClick={() => setHiddenSport(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {optionsSport.map((sport, index) => { 
                                        if (sport == 'N/A') return (<></>); // Skip N/A

                                        return (<>
                                            <div 
                                                key={`coaches-sport-sport-${index}`}
                                                onClick={(e) => toggleSport(sport, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterSport, sport)}>
                                                <div className="check w-4 mb-[2px]  h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{sport}</p>
                                            </div>
                                        </>);
                                    })}
                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>

            <div className="flex flex-col pt-8 lg:pt-16">
                {optionsSport?.map((sport, index) => {
                    let _coaches = coachesForSport(sport);

                    // If sports filter is used, check if included.
                    return (<>
                    { (!filterSport?.length || filterSport.includes(sport))  &&
                        <SportCoachCards 
                            key={`sport-coach-card-${index}`}
                            location={filterLocation}
                            sport={sport}
                            coaches={_coaches}
                        />
                    }
                        
                    </>);
                })}
            </div>
        </div>
    </>);
};

