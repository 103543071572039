import  { useContext, useState, useEffect } from 'react';
import { TwoColContactForm } from '../../components/chelsea/TwoColContactForm';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

function ACFTwoColContactForm({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    // const {
    //     top_heading,
    //     main_heading,
    //     description,
    //     description_link,
    //     form_embed,
    //     notes
    // } = data;

    const top_heading = data?.top_heading;
    const main_heading = data?.main_heading;
    const description = data?.description;
    const description_link = data?.description_link;
    const form_embed = data?.form_embed;
    const notes = data?.notes;

    return (<>
        {show &&
            <TwoColContactForm
                className={className}
                anchor={anchor}
                top_heading={top_heading}
                main_heading={main_heading}
                description={description}
                description_link={description_link}
                form_embed={form_embed}
                notes={notes} />
        }
    </>);
}

export default ACFTwoColContactForm;
