import React from 'react';

/**
 * @param {*} props {
 *  eyebrow
 *  title 
 *  subTitle
 *  blurb
 *  link {
 *   title 
 *   target 
 *  }
 *  image {
 *   src 
 *   srcSet
 * }
 */
const FullWidthImageCTA = (props) => {
    return (<>
        <div 
            component="FullWidthImageCTA"
            className="wrapper bg-white text-white">
            <div 
                className="bg-cover px-9 lg:px-0 lg:py-0 py-14  w-full grid  lg:grid-cols-2 min-h-[560px]" 
                style={{
                    backgroundImage: `url(${props?.image?.src})`,
                    backgroundPosition: 'center',
                }}>
                <div className="flex items-center lg:pl-[7.222vw] lg:pr-[3.75vw] wow fadeInUp to-animate" animated="true">
                    {props?.title &&
                        <h1 className=" text-start">{props?.title}</h1>
                    }
                </div>
                <div className="flex items-center justify-center">
                    <div className="flex flex-col items-start  lg:px-28  lg:py-24">
                        {props?.eyebrow && 
                            <p className="mt-12 label--bold lg:mt-0 wow fadeInUp  to-animate"animated="true">{props?.eyebrow}</p>
                        }
                        {props?.subTitle &&
                            <h3 className="mt-3 wow fadeInUp to-animate" animated="true">{props?.subTitle}</h3>
                        }
                        {props?.blurb &&
                            <div 
                                dangerouslySetInnerHTML={{ __html: props?.blurb }}
                                className="mt-4 lead--regular wow fadeInUp  to-animate" 
                                animated="true">
                            </div>
                        }
                        {props?.link &&
                            <a href={props?.link?.target} className="label--bold btn btn--black  pt-[15px] pb-[13px]  w-auto mt-7 lg:mt-8  h-full" target="">{props?.link?.title}</a>
                        }
                    </div>
                </div>
            </div>
        </div>
    </>);
};

export default FullWidthImageCTA;