import React, { useContext, useState, useEffect } from 'react';
import { LocationContext } from '../../util/LocationProvider';

import { Image } from '../../util/image';

function CoreImage({
    width,
    height,
    className='',
    url,
    anchor='',
    caption,
    alt,
    data=false,
}) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(true);
    const [locale, setLocale] = useState(LocationManager?.getLocation());
    if (data && process?.env?.NEXT_PUBLIC_USE_LOCATIONS=='1') {
        useEffect(() => {
            setShow(!LocationManager?.hideBlock(data));
        }, [
            locale, 
            LocationManager?.eventsLocation, 
            LocationManager?.sportsLocation,
            data,
        ]);
    }
    
    if (!width || !height) {
        return (<>
            {show &&
                <div className={`bg-white wrapper  ${className}`}>
                    <div className={`px-6 lg:px-28`}>
                        <Image src={url} className={`  inline-block`} id={anchor} />
                        {caption && (
                            <div
                                className="text-12"
                                dangerouslySetInnerHTML={{ __html: caption }}
                            />
                        )}
                    </div>
                </div>
            }
        </>);
    }

    return (<>
        {show && 
            <div className={`bg-white wrapper  ${className}`}>
                <div className={`px-6 lg:px-28`}>
                    <Image
                        src={url}
                        width={width}
                        height={height}
                        id={anchor}
                        className={`  inline-block`}
                    />
                    {caption && (
                        <div
                            className="text-12"
                            dangerouslySetInnerHTML={{ __html: caption }}
                        />
                    )}
                </div>
            </div>
        }
    </>);
}

export default CoreImage;
