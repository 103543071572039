import  { useContext, useState, useEffect } from 'react';
import Coaches from '../../components/chelsea/List/Coaches'
import { LocationContext } from '../../util/LocationProvider'

const ACFCoachesListing = ({data,meta,className,anchor}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let coaches = meta?.coaches.sort((a,b) => (a.first_name>b.first_name) ? 1 : -1);

    return (<>
        {show &&
            <Coaches 
               title={data?.title}
               showPagination={data?.hide_pagination === '0'}
               showFilters={data?.hide_filters === '0'}
               pagination={parseInt(data?.post_per_pagination)}
               className={className}
               anchor={anchor}
               specialities={meta?.coachSpecialties}
               levels={meta?.coachLevels}
               locations={meta?.coachLocations}
               coaches={coaches}
            />
        }
    </>);
}

export default ACFCoachesListing
