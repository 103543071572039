import { Transition } from '@headlessui/react';
import React, { useEffect, useRef, useState } from 'react';
import MobileFilter from './classesComponent/MobileFilter';
import { useRouter } from 'next/router';
import moment from 'moment/moment';

export const ClassSchedule = ({
                                  className,
                                  anchor,
                                  classTypes,
                                  manualClassTypes,
                                  classTimes,
                                  coaches,
                                  locations,
                                  classes,
                                  title
                              }) => {

    const days = 7;
    const getTimezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;
    const dateStamp = new Date().setHours(0, 0, 0, 0) - getTimezoneOffset;
    const endTime = dateStamp + 30 * 24 * 60 * 60 * 1000;
    const [classesWithOutDateFilter, setClassesWithoutDateFilter] = useState();
    const [classesWithTimeStamp, setClassesWithTimeStamp] = useState();
    const [filteredClasses, setFilteredClasses] = useState();
    const [finalClassesToRender, setFinalClassesToRender] = useState();
    const [currentWeekTimeStampRange, setCurrentWeekTimeStampRange] = useState({
        today: dateStamp,
        lastDay: dateStamp + days * 24 * 60 * 60 * 1000
    });
    const [show, setShow] = useState();
    const [activeSlugFromHeader, setActiveSlugFromHeader] = useState();
    const [activeLocation, setActiveLocation] = useState([]);
    const [activeCoach, setActiveCoach] = useState([]);
    const [activeClassTime, setActiveClassTime] = useState([]);
    const [activeClassType, setActiveClassType] = useState([]);
    const [locationsFilter, setLocationsFilter] = useState();
    const [coachFilter, setCoachFilter] = useState();
    const [classTimeFilter, setClassTimeFilter] = useState();
    const [classTypeFilter, setClassTypeFilter] = useState();
    const [expandedDate, setExpandedDate] = useState(0);
    const [expandedClass, setExpandedClass] = useState();
    const [showMobileFilter, setShowMobileFilter] = useState(false);
    const [modalState, setModalState] = useState(false);
    const ref = useRef();
    const ref1 = useRef();
    const ref2 = useRef();
    const ref3 = useRef();
    const dayRef = useRef();
    const mobileRef = useRef();
    const router = useRouter();

    const getDate = (string) => {
        let split = string?.split('-');
        let newDate = new Date(split[0], split[1] - 1, split[2]);
        return newDate?.getTime() - getTimezoneOffset;
    };
    useEffect(() => {
        let tempLocation = [];
        let finalLocationCount = 0;
        Object.values(classTimes)?.forEach((location, index) => {
            tempLocation.push(location);
            finalLocationCount = finalLocationCount + location?.count;
        });
        tempLocation = [...tempLocation];
        setClassTimeFilter(tempLocation);
    }, [classTimes]);

    useEffect(() => {
        let { filter } = router?.query;
        let newFilters = filter?.split(',')
        if (newFilters) {
            setActiveSlugFromHeader(newFilters);
            document.getElementById('classes').scrollIntoView({
                behavior: 'smooth'
            });
        }
    }, [router && router?.query]);

    useEffect(() => {
        if (activeSlugFromHeader) {
            let array = activeSlugFromHeader?.map((item) => {
                return { slug: item,
                    name: classTypes.find(
                        (ele) => ele.slug === item
                    )?.name }
            })
            setActiveClassType(array);
        }
    }, [activeSlugFromHeader]);

    useEffect(() => {
        let tempLocation = [];
        let finalLocationCount = 0;
        // classTypes?.forEach((location, index) => {
        //     tempLocation.push(location);
        //     finalLocationCount = finalLocationCount + location?.count;  
        // });    //the original code
        manualClassTypes?.forEach((classType,index) => {
            tempLocation.push(classType?.activity_group_type?.[0])
        })
        tempLocation = [...tempLocation];
        setClassTypeFilter(tempLocation);
    }, [classTypes]);

    useEffect(() => {
        let tempLocation = [];
        let finalLocationCount = 0;

        locations?.forEach((location, index) => {
            tempLocation.push(location);
            finalLocationCount = finalLocationCount + location?.count;
        });
        tempLocation = [...tempLocation];
        setLocationsFilter(tempLocation);
    }, [locations]);

    useEffect(() => {
        //locations
        let tempCategory = [];
        let finalCategoryCount = 0;
        coaches?.forEach((category, index) => {
            tempCategory.push(category);
            finalCategoryCount = finalCategoryCount + category?.count;
        });
        tempCategory = [...tempCategory];
        setCoachFilter(tempCategory);
    }, [coaches]);

    useEffect(() => {
        let items = [];
        classes?.map((itemClass, index) => {
            let object = {};
            object = { ...itemClass };
            object.actualActivity = itemClass?.classActivityTypes
                ?.concat?.(itemClass?.classTypes)
                ?.map((activity) => activity?.slug);
            object.actualLocation = [itemClass?.locations?.slug];
            object.actualCoach = [itemClass?.coaches?.slug];
            object.actualTimeOfDay = itemClass?.classTimeOfDays?.map(
                (time) => time?.slug
            );
            object.timeStamp = getDate(itemClass?.date);
            object.startTime = getTimeToSort(itemClass?.start_time);

            items.push(object);
        });

        items.sort((a, b) => a.startTime - b.startTime);
        setClassesWithoutDateFilter(items);
    }, [classes]);

    const getTimeToSort = (startTime) => {
        var timeString = startTime;
        var H = +timeString?.substring(0, 2);
        var H1 =
            +timeString?.substring(3, 5) === 0
                ? '00'
                : +timeString?.substring(3, 5);
        return parseInt(H.toString() + H1.toString());
    };

    const filterClasses = () => {
        let items = classesWithOutDateFilter;

        let checkLocations = activeLocation?.length > 0;
        let checkCoach = activeCoach?.length > 0;
        let checkClassType = activeClassType?.length > 0;
        let checkClassTime = activeClassTime?.length > 0;
        var areLocations = false;
        var areCoaches = false;
        var areClassTypes = false;
        var areClassTime = false;
        var array = [];
        if (checkLocations) {
            array = items?.filter((item, index) =>
                activeLocation?.some(
                    (location) => location?.slug === item?.actualLocation?.[0]
                )
            );
            areLocations = true;
        }
        if (checkCoach) {
            let elements =
                areLocations || areClassTime || areClassTypes ? array : items;
            array = elements?.filter((item, index) =>
                activeCoach?.some((coach) =>
                    item?.actualCoach?.includes(coach?.slug)
                )
            );
            areCoaches = true;
        }
        if (checkClassType) {
            let elements =
                areCoaches || areClassTime || areLocations ? array : items;
            array = elements?.filter((item, index) =>
                activeClassType?.some((classType) =>
                    item?.actualActivity?.includes(classType?.slug)
                )
            );
            areClassTypes = true;
        }
        if (checkClassTime) {
            let elements =
                areClassTypes || areLocations || areClassTime ? array : items;
            array = elements?.filter((item, index) =>
                activeClassTime?.some((classTime) =>
                    item?.actualTimeOfDay?.includes(classTime?.term?.slug)
                )
            );
            areClassTime = true;
        }
        if (
            !checkCoach &&
            !checkLocations &&
            !checkClassType &&
            !checkClassTime
        ) {
            array = items;
        }

        setFilteredClasses(array);
    };

    useEffect(() => {
        filterClasses();
    }, [
        classesWithOutDateFilter,
        activeLocation,
        activeCoach,
        activeClassTime,
        activeClassType
    ]);

    useEffect(() => {
        let classes = [];
        let timeStamps = [];
        filteredClasses?.map((classItem, index) => {
            if (!timeStamps?.includes(classItem.timeStamp)) {
                let object = {};
                object.timeStamp = classItem.timeStamp;
                object.classes = [classItem];
                classes.push(object);
                timeStamps.push(classItem.timeStamp);
            } else {
                let index = classes?.findIndex(
                    (item) => item.timeStamp === classItem.timeStamp
                );
                classes[index].classes = [
                    ...classes?.[index]?.classes,
                    classItem
                ];
            }
        });

        classes.sort((a, b) => a.timeStamp - b.timeStamp);

        setClassesWithTimeStamp(classes);
    }, [filteredClasses]);

    const removeFilter = (slug) => {
        if (activeLocation?.findIndex((elem) => elem?.slug === slug) > -1) {
            setActiveLocation([
                ...activeLocation?.filter((i) => i.slug !== slug)
            ]);
        } else if (activeCoach?.findIndex((elem) => elem?.slug === slug) > -1) {
            setActiveCoach([...activeCoach?.filter((i) => i.slug !== slug)]);
        } else if (
            activeClassTime?.findIndex((elem) => elem?.term?.slug === slug) > -1
        ) {
            setActiveClassTime([
                ...activeClassTime?.filter((i) => i?.term?.slug !== slug)
            ]);
        } else if (
            activeClassType?.findIndex((elem) => elem?.slug === slug) > -1
        ) {
            setActiveClassType([
                ...activeClassType?.filter((i) => i.slug !== slug)
            ]);
        }
    };

    const getNextWeek = () => {
        let today = currentWeekTimeStampRange?.today;
        var date = new Date(today + getTimezoneOffset + getTimezoneOffset);
        const totalDays = 7;
        const daysToMultiply = totalDays - date.getDay();

        let newRange = {
            today: today + daysToMultiply * 24 * 60 * 60 * 1000,
            lastDay: today + (6 + daysToMultiply) * 24 * 60 * 60 * 1000
        };
        setExpandedClass('');
        setCurrentWeekTimeStampRange(newRange);
    };

    const getPreviousWeek = () => {
        let today = currentWeekTimeStampRange?.today;
        if (today - dateStamp < 604800000) {
            let newRange = {
                today: dateStamp,
                lastDay: dateStamp + days * 24 * 60 * 60 * 1000
            };
            setCurrentWeekTimeStampRange(newRange);
        } else {
            let newRange = {
                today:
                    currentWeekTimeStampRange.today - 7 * 24 * 60 * 60 * 1000,
                lastDay: currentWeekTimeStampRange.today - 24 * 60 * 60 * 1000
            };
            setCurrentWeekTimeStampRange(newRange);
        }
        setExpandedClass('');
    };

    useEffect(() => {
        let items = classesWithTimeStamp?.filter(
            (item) =>
                item.timeStamp >= currentWeekTimeStampRange.today &&
                item.timeStamp <= currentWeekTimeStampRange.lastDay
        );
        setFinalClassesToRender(items);
    }, [currentWeekTimeStampRange, classesWithTimeStamp]);

    const cleanAllFilters = () => {
        setActiveClassType([]);
        setActiveCoach([]);
        setActiveLocation([]);
        setActiveClassTime([]);
    };

    const timeStampToDayMonth = (time, type) => {
        var days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
        var longDay = [
            'Sunday',
            'Monday',
            'Tuesday',
            'Wednesday',
            'Thursday',
            'Friday',
            'Saturday'
        ];
        const m_names = [
            'Jan',
            'Feb',
            'Mar',
            'Apr',
            'May',
            'Jun',
            'Jul',
            'Aug',
            'Sep',
            'Oct',
            'Nov',
            'Dec'
        ];

        var date = new Date(time + getTimezoneOffset + getTimezoneOffset);
        var dayName =
            type === 'short' ? days[date.getDay()] : longDay[date.getDay()];
        let month = m_names[date.getMonth()]; // 4 (note zero index: Jan = 0, Dec = 11)
        let day = date.getDate(); // 9
        return dayName + ' ' + month + ' ' + day;
    };

    const displayWeekRange = () => {
        let leftItem =
            dateStamp === currentWeekTimeStampRange?.today
                ? 'Today'
                : timeStampToDayMonth(
                currentWeekTimeStampRange?.today,
                'short'
                );
        let rightItem = timeStampToDayMonth(
            currentWeekTimeStampRange?.lastDay,
            'short'
        );

        return (
            <p className="body--regular h-8 lg:h-12 flex items-center justify-center ">
                {leftItem} &ndash; {rightItem}
            </p>
        );
    };

    const getTimeString = (startTime, endTime) => {
        var timeString = startTime;

        var H = +timeString.substring(0, 2);
        var h = H % 12 || 12;
        var ampm = H < 12 || H === 24 ? ' am ' : ' pm ';
        timeString = h + timeString.substring(2, 5) + ampm;

        let timeDifferenceHours = endTime?.substring(0, 2) - startTime?.substring(0, 2);
        let hoursTimeDiffInMinutes = 60 * timeDifferenceHours;
        let timeDifferenceInMins = endTime?.substring(3, 5) - startTime?.substring(3, 5);

        let finalDifference = hoursTimeDiffInMinutes + timeDifferenceInMins;
        return (
            <p>
                {timeString} &#x0002F;{' '}
                {finalDifference ? finalDifference + ' min' : null}
            </p>
        );
    };

    // const filterPast = (item) => { 
    //     let today = moment().format('YYYYMMDD');
    //     let classDate = moment(item?.timeStamp).format('YYYYMMDD');

    //     if (today != classDate) return false; // Compare only classes for today.

    //     let now = parseInt( moment().format('HHmm') );
    //     let classEnd = parseInt( (item?.end_time?.replace(':', '') || 0) );
    //     return (now > classEnd);
    // };

    const filterCancelled = (item) => { 
        return (item?.status != "publish");
    };

    return (
        <div
            className={`xl:px-[3.333%] wow fadeInUp bg-white ${className}`}
            id={anchor}
        >

            <h3 className="px-6 pt-12 text-center lg:px-0 lg:pt-16 text-black-100">
                {' '}{title}{' '}
            </h3>
            <div className="flex-col hidden pt-16 lg:flex wow fadeInUp ">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">
                    <div
                        className={`flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative`}
                    >
                        <div
                            onClick={() =>
                                setShow(show === 'locations' ? '' : 'locations')
                            }
                            className={`flex items-center  btn ${
                                show === 'locations' ||
                                activeLocation?.some((classType) =>
                                    locationsFilter
                                        ?.map?.((item) => item?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray' : 'btn--lightGray'
                            } pt-[15px]  justify-between`}
                        >
                            <span className="label--bold ">Locations</span>

                            {activeLocation?.some((classType) =>
                                locationsFilter
                                    ?.map?.((item) => item?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`} />
                            ) : (
                                <i
                                    className={`  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16 ${
                                        show === 'locations'
                                            ? ' rotate-90 '
                                            : ' -rotate-90'
                                    } transform  font-700`}
                                />
                            )}
                        </div>

                        <Transition
                            show={show === 'locations'}
                            ref={ref}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16`}
                                />
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {locationsFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (
                                                        activeLocation?.findIndex(
                                                            (elem) => elem?.slug === item?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveLocation([
                                                            ...activeLocation?.filter(
                                                                (i) => i.slug !== item.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveLocation([...activeLocation, item]);
                                                    }
                                                }}
                                                className={`flex items-center space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                        activeLocation?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                            ? 'bg-white'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <p className="label--bold">
                                                    {item?.name}{' '}
                                                </p>
                                                {/* <span className="ml-3 opacity-70">
                                                    {' '}
                                                    {item?.count}
                                                </span> */}
                                            </div>
                                        );
                                    })}
                                    {/* Hardcoded Connecticut Location */}
                                    {/* <div
                                        onClick={() => {setModalState(true);}}
                                        className={`flex items-center space-x-3`}
                                    >
                                        <div
                                            className={`w-4 mb-[2px]  h-4  border-2 border-white `}
                                            style={{
                                                borderRadius: '50%'
                                            }}
                                        >
                                            {' '}
                                        </div>
                                        <p className="label--bold">
                                            Stamford, CT
                                        </p>

                                    </div> */}
                                </div>
                            </div>
                        </Transition>
                    </div>

                    <div
                        className={`flex flex-col lg:w-full xl:px-[1.3393%] px-3  relative`}
                        onClick={() =>
                            setShow(show === 'classType' ? '' : 'classType')
                        }
                    >
                        <div
                            className={`flex items-center  btn ${
                                show === 'classType' ||
                                activeClassType?.some((classType) =>
                                    classTypeFilter
                                        ?.map?.((item) => item?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray'
                                    : 'btn--lightGray'
                            } pt-[15px]  justify-between `}
                        >
                            <span className="transition-all duration-100 label--bold ">
                                Class Types
                            </span>
                            {activeClassType?.some((classType) =>
                                classTypeFilter
                                    ?.map?.((item) => item?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i className={`cp-icon-iconPlus text-[8px] mr-4  text-white  font-700`} />
                            ) : (
                                <i
                                    className={`  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center leading-16 ${
                                        show === 'classType'
                                            ? ' rotate-90  '
                                            : ' -rotate-90'
                                    } transform   font-700`}
                                />
                            )}
                        </div>

                        <Transition
                            show={show === 'classType'}
                            ref={ref3}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 lg:min-w-[648px] z-[4] absolute transform bg-gray  xl:left-1/2 xl:-translate-x-1/2 px-6">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16`}
                                />
                                <div className="grid grid-cols-3 gap-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {classTypeFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        activeClassType?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveClassType([
                                                            ...activeClassType?.filter(
                                                                (i) =>
                                                                    i.slug !==
                                                                    item.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveClassType([
                                                            ...activeClassType,
                                                            item
                                                        ]);
                                                    }
                                                }}
                                                className={`flex items-start col-span-1 space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px] min-w-[16px]  h-4  border-2 border-white ${
                                                        activeClassType?.findIndex(
                                                            (elem) =>
                                                                elem.slug ===
                                                                item?.slug
                                                        ) > -1
                                                            ? 'bg-white'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <div
                                                    className="label--bold"
                                                    dangerouslySetInnerHTML={{
                                                        __html: item?.name
                                                    }}
                                                />

                                                {/* <span className="ml-3 opacity-70">
                                                    {' '}
                                                    {item?.count}
                                                </span> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Transition>
                    </div>


                    <div
                        className={`flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative`}
                        onClick={() =>
                            setShow(show === 'instructors' ? '' : 'instructors')
                        }
                    >
                        <div
                            className={`flex items-center  btn ${
                                show === 'instructors' ||
                                activeCoach?.some((classType) =>
                                    coachFilter
                                        ?.map?.((item) => item?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray'
                                    : 'btn--lightGray'
                            } pt-[15px]  justify-between `}
                        >
                            <span className="transition-all duration-100 label--bold ">
                                instructors
                            </span>
                            {activeCoach?.some((classType) =>
                                coachFilter
                                    ?.map?.((item) => item?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i className={`cp-icon-iconPlus text-[8px] mr-4   text-white  font-700`} />
                            ) : (
                                <i
                                    className={`  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16 ${
                                        show === 'instructors'
                                            ? ' rotate-90 '
                                            : ' -rotate-90'
                                    } transform  font-700`}
                                />
                            )}
                        </div>

                        <Transition
                            show={show === 'instructors'}
                            ref={ref1}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 lg:min-w-[648px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16`}
                                />
                                <div className="grid grid-cols-3 gap-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {coachFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={(e) => {
                                                    e.stopPropagation();
                                                    if (
                                                        activeCoach?.findIndex(
                                                            (elem) =>
                                                                elem?.slug ===
                                                                item?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveCoach([
                                                            ...activeCoach?.filter(
                                                                (i) =>
                                                                    i.slug !==
                                                                    item.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveCoach([
                                                            ...activeCoach,
                                                            item
                                                        ]);
                                                    }
                                                }}
                                                className={`flex items-center space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                        activeCoach?.findIndex(
                                                            (elem) =>
                                                                elem.slug ===
                                                                item?.slug
                                                        ) > -1
                                                            ? 'bg-white'
                                                            : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <p className="label--bold">
                                                    {item?.name}{' '}
                                                </p>
                                                {/* <span className="ml-3 opacity-70">
                                                    {' '}
                                                    {item?.count}
                                                </span> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Transition>
                    </div>
                    <div
                        className={`flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative`}
                        onClick={() =>
                            setShow(show === 'classTime' ? '' : 'classTime')
                        }
                    >
                        <div
                            className={`flex items-center  btn ${
                                show === 'classTime' ||
                                activeClassTime?.some((classType) =>
                                    classTimeFilter
                                        ?.map?.((item) => item?.term?.slug)
                                        ?.includes(classType?.slug)
                                )
                                    ? 'btn--gray'
                                    : 'btn--lightGray'
                            } pt-[15px]  justify-between `}
                        >
                            <span className="transition-all duration-100 label--bold ">
                                Time of Day
                            </span>
                            {activeClassTime?.some((classType) =>
                                classTimeFilter
                                    ?.map?.((item) => item?.term?.slug)
                                    ?.includes(classType?.slug)
                            ) ? (
                                <i className={`cp-icon-iconPlus text-[8px] mr-4   text-white  font-700`} />
                            ) : (
                                <i
                                    className={`  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16 ${
                                        show === 'classTime' ? ' rotate-90 ' : ' -rotate-90'
                                    } transform  font-700`}
                                />
                            )}
                        </div>

                        <Transition
                            show={show === 'classTime'}
                            ref={ref2}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6">
                                <i
                                    onClick={() => setShow('')}
                                    className={` cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16`}
                                />
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">
                                    {classTimeFilter?.map((item, index) => {
                                        return (
                                            <div
                                                onClick={() => {
                                                    if (
                                                        activeClassTime?.findIndex(
                                                            (elem) =>
                                                                elem?.term
                                                                    ?.slug ===
                                                                item?.term?.slug
                                                        ) > -1
                                                    ) {
                                                        setActiveClassTime([
                                                            ...activeClassTime?.filter(
                                                                (i) => i?.term?.slug !== item?.term?.slug
                                                            )
                                                        ]);
                                                    } else {
                                                        setActiveClassTime([...activeClassTime, item]);
                                                    }
                                                }}
                                                className={`flex items-center space-x-3`}
                                                key={index}
                                            >
                                                <div
                                                    className={`w-4 mb-[2px]  h-4  border-2 border-white ${
                                                        activeClassTime?.findIndex(
                                                            (elem) => elem?.term?.slug === item?.term?.slug
                                                        ) > -1 ? 'bg-white' : ''
                                                    }`}
                                                    style={{
                                                        borderRadius: '50%'
                                                    }}
                                                >
                                                    {' '}
                                                </div>
                                                <p className="label--bold">
                                                    {item?.term?.name} &#x0002F;{' '}
                                                    {item?.start_time}&ndash;
                                                    {item?.end_time}
                                                </p>
                                                {/* <span className="ml-3 opacity-70">
                                                    {' '}
                                                    {item?.count}
                                                </span> */}
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        </Transition>
                    </div>
                </div>
                {activeLocation
                    ?.concat?.(activeCoach)
                    ?.concat(activeClassTime)
                    ?.concat(activeClassType)?.length > 0 ? (
                    <div className=" whitespace-normal px-[18.66666666666667%] mt-10 flex flex-wrap items-center ">
                        {activeLocation
                            ?.concat?.(activeCoach)
                            ?.concat(activeClassTime)
                            ?.concat(activeClassType)
                            ?.map((item, index) => {
                                return (
                                    <div
                                        className={`btn btn--lightGray ml-2 mt-2 label--smallBold px-4 pr-[11.5px] pt-[7.5px] pb-[6.5px] flex items-center group cursor-default`}
                                        key={index}

                                    >
                                        <div
                                            className=""
                                            dangerouslySetInnerHTML={{
                                                __html: item?.term?.name
                                                    ? item?.term?.name +
                                                    ' / ' +
                                                    item?.start_time +
                                                    '–' +
                                                    item?.end_time : item?.name
                                            }}
                                        />
                                        <i className={`cursor-pointer ml-3 cp-icon-modalClose text-10 leading-14 group-hover:bg-hoverButtonDarkGray`}
                                           onClick={() =>
                                               removeFilter(
                                                   item?.term?.slug
                                                       ? item?.term?.slug
                                                       : item.slug
                                               )
                                           }
                                        />
                                    </div>
                                );
                            })}
                        <div
                            className={`ml-6 text-black-100 text-opacity-70 lead--regular mt-3 cursor-pointer`}
                            key={'clear'}
                            onClick={() => cleanAllFilters()}
                        >
                            <span className="">Clear All </span>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="pt-6 lg:hidden ">
                <div
                    className={`flex mx-auto  items-center justify-center btn ${
                        activeLocation?.concat?.(activeCoach)?.length > 0
                            ? 'btn--gray'
                            : 'btn--lightGray'
                    }    pt-[15px] w-min `}
                    onClick={() => {
                        setShowMobileFilter(true);
                    }}
                >
                    <i className={`  cp-icon-Filter text-20 mb-1 mr-3 leading-16 transform font-700`}/>
                    <span className="transition-all duration-100 label--bold ">
                        Filter
                    </span>
                    {activeLocation
                        ?.concat?.(activeCoach)
                        ?.concat?.(activeClassTime)
                        ?.concat?.(activeClassType)?.length > 0 ? (
                        <span className="ml-3 transition-all duration-100 label--bold text-opacity-60 ">
                            {activeLocation
                                ?.concat?.(activeCoach)
                                ?.concat?.(activeClassTime)
                                ?.concat?.(activeClassType)?.length}
                        </span>
                    ) : null}
                </div>
                <Transition
                    show={showMobileFilter}
                    ref={mobileRef}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div
                        className="bg-[#1A1A1A] fixed top-0 h-screen max-h-[100vh]  z-100 w-[100vw]"
                        style={{ zIndex: 100 }}
                    >
                        <div className="flex flex-col ">
                            <div className=" xl:hidden flex items-center justify-between  w-full  h-[64px] max-h-[64px] border-b border-grid   ">
                                <div className="flex items-center justify-items-start w-full border-r border-grid   h-full min-w-[264px]">
                                    <h4 className="px-6">Filter</h4>
                                </div>
                                <div className="flex w-full items-center justify-center h-full min-w-[96px] max-w-[96px]">
                                    <div
                                        className="flex items-center justify-center w-12 h-12 my-2 bg-gray "
                                        style={{ borderRadius: '50%' }}
                                        onClick={() =>
                                            setShowMobileFilter(false)
                                        }
                                    >
                                        {showMobileFilter ? (
                                            <i className="text-white cp-icon-modalClose text-14"/>
                                        ) : (
                                            <i className="text-white cp-icon-Menu text-16"/>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <MobileFilter
                                closeMobileFilter={() =>
                                    setShowMobileFilter(false)
                                }
                                getActiveLocationFilters={(items) =>
                                    setActiveLocation(items)
                                }
                                getActiveCoachFilters={(items) =>
                                    setActiveCoach(items)
                                }
                                getActiveClassTimeFilters={(items) =>
                                    setActiveClassTime(items)
                                }
                                getActiveClassTypeFilters={(items) =>
                                    setActiveClassType(items)
                                }
                                currentActiveLocation={activeLocation}
                                currentActiveCoach={activeCoach}
                                currentActiveClassTypes={activeClassType}
                                currentActiveClassTimes={activeClassTime}
                                showMobileFilter={showMobileFilter}
                                locationsFilter={locationsFilter}
                                classTypeFilter={classTypeFilter}
                                classTimeFilter={classTimeFilter}
                                coachFilter={coachFilter}
                            />
                        </div>
                    </div>
                </Transition>
            </div>

            <div className=" text-center align-middle mt-6 lg:mt-[76px]  relative z-[3]   text-black-100 ">
                {currentWeekTimeStampRange?.today > dateStamp ? (
                    <div
                        className="flex items-center justify-center w-8 h-8 cursor-pointer lg:w-12 lg:transform lg:-translate-x-1/2 lg:h-12 bg-buttonGray top-0 absolute left-[36px] lg:left-[33.332%]"
                        onClick={() => getPreviousWeek()}
                        style={{ borderRadius: '50%' }}
                    >
                        <i className="text-10 font-700  lg:text-12 cp-icon-arraySmallLeft "/>
                    </div>
                ) : (
                    <div className="w-8 h-8 lg:w-12 lg:h-12 top-0 absolute left-[36px] lg:left-[33.332%]" />
                )}
                {displayWeekRange()}
                {endTime < currentWeekTimeStampRange?.lastDay ? (
                    <div className="w-8 h-8 lg:w-12 lg:h-12" />
                ) : (
                    <div
                        className="flex items-center justify-center w-8 h-8 cursor-pointer lg:transform lg:translate-x-1/2 lg:w-12 lg:h-12 bg-buttonGray top-0 absolute right-[36px] lg:right-[33.332%]"
                        onClick={() => getNextWeek()}
                        style={{ borderRadius: '50%' }}
                    >
                        <i className="transform rotate-180 text-10 font-700 lg:text-12  cp-icon-arraySmallLeft "/>
                    </div>
                )}
            </div>

            <div className="flex flex-col pt-8 pb-16 lg:pt-16 lg:pb-24 " id={"classes"}>
                {finalClassesToRender?.map((finalClass, index) => {
                    
                    return (
                        <>
                            <div
                                data-wow-delay={`${index / 8}s`}
                                className={`flex flex-col px-[36px] lg:px-28 cursor-pointer`}
                                onClick={() => {
                                    setExpandedDate(
                                        expandedDate === index ? '' : index
                                    );
                                    setExpandedClass('');
                                }}
                                key={index}
                            >
                                <div
                                    className={`flex items-center  justify-between px-6 py-[10px] lg:p-6 border-t border-black-10 relative z-[3]  ${
                                        expandedDate === index
                                            ? 'bg-black-100 text-white'
                                            : 'bg-[#F7F7F7] text-black-100'
                                    } `}
                                >
                                    <h5 className={``}>
                                        {timeStampToDayMonth(
                                            finalClass?.timeStamp,
                                            'long'
                                        )}
                                    </h5>
                                    <i
                                        className={`  cp-icon-arraySmallLeft text-9 leading-16 ${
                                            expandedDate === index ? ' rotate-90 ' : ' -rotate-90'
                                        } transform  font-700`}
                                    />
                                </div>
                            </div>

                            <Transition
                                show={expandedDate === index}
                                ref={dayRef}
                                leave="transition ease-in duration-100"
                                leaveFrom="opacity-100"
                                leaveTo="opacity-0"
                                className=""
                            >
                                {finalClass?.classes?.map(
                                    (item, classIndex) => {
                                        if (filterCancelled(item)) { 
                                            return (<></>);
                                        }

                                        return (
                                            <div
                                                className={`mx-[36px] lg:mx-28 relative z-[3] bg-white  ${
                                                    finalClass?.classes?.length - 1 === classIndex ? '' : 'border-b border-black-10'
                                                } `}
                                                key={classIndex}
                                            >
                                                <div
                                                    className={`flex flex-col  lg:py-0 ${
                                                        expandedClass === classIndex ? 'border-t pt-8 border-black-100' : 'py-8'
                                                    }`}
                                                >
                                                    <div
                                                        className="text-black-100 flex space-y-3 lg:space-y-0 flex-col lg:flex-row lg:items-center lg:justify-between min-h-[144px] cursor-pointer"
                                                        onClick={() =>
                                                            setExpandedClass(
                                                                expandedClass === classIndex ? '' : classIndex
                                                            )
                                                        }
                                                    >
                                                        <div
                                                            className={`lead--extraLarge w-full px-6 ${
                                                                expandedClass === classIndex ? 'font-700' : ''
                                                            }`}
                                                            dangerouslySetInnerHTML={{
                                                                __html: item?.name
                                                            }}
                                                        />
                                                        <div className="w-full px-6 mt-2 lead--regular">
                                                            {getTimeString(item?.start_time, item?.end_time)}
                                                        </div>
                                                        <div className="flex items-center w-full px-6 lead--regular ">
                                                            
                                                            {item?.coaches?.instructor_icon ? (
                                                                <img
                                                                    // src={'/images/coachTempBackground.png'}
                                                                    //src={'https://media.chelseapiers.com/images/fitness/staff/' + item?.coaches?.id + '_' + item?.coaches?.first_name + '_' + item?.coaches?.last_name + '.jpg'}
                                                                    src={item?.coaches?.instructor_icon}
                                                                    className="w-12 h-12"
                                                                    style={{
                                                                        borderRadius: '50%'
                                                                    }}
                                                                />
                                                                ) : (
                                                                    <img
                                                                        src={'/images/coachTempBackground.png'}
                                                                        //src={'https://media.chelseapiers.com/images/fitness/staff/' + item?.coaches?.id + '_' + item?.coaches?.first_name + '_' + item?.coaches?.last_name + '.jpg'}
                                                                        //src={item?.coaches?.instructor_icon}
                                                                        className="w-12 h-12"
                                                                        style={{
                                                                            borderRadius: '50%'
                                                                        }}
                                                                    />
                                                            )}
                                                            <div className="ml-4 mt-2 capitalize">
                                                                {item?.coach_tba ? (
                                                                    <p>
                                                                        To Be Announced
                                                                    </p>
                                                                ) : (
                                                                    // <NextNavLink
                                                                    //     href={`/coaches/${item?.coaches?.slug}`}
                                                                    // >
                                                                    <div
                                                                        onClick={(e) =>
                                                                            e.stopPropagation()
                                                                        }
                                                                    >
                                                                        {item?.coaches?.first_name + 
                                                                        ' ' +
                                                                        item?.coaches?.last_name}
                                                                    </div>
                                                                    // </NextNavLink>
                                                                )}
                                                            </div>
                                                            {item?.substitute_coach ? (
                                                                <div className="mt-2 ml-2 label--smallBold text-black-50">
                                                                    SUB
                                                                </div>
                                                            ) : null}
                                                        </div>
                                                        <div className="flex items-center justify-between w-full px-6">
                                                            <div
                                                                className="mt-2 lead--regular"
                                                                dangerouslySetInnerHTML={{
                                                                    __html: item?.locations?.name
                                                                }}
                                                            />

                                                            <i
                                                                className={`  cp-icon-arraySmallLeft text-9 leading-16 ${
                                                                    expandedClass === classIndex ? ' rotate-90 ' : ' -rotate-90'
                                                                } transform  font-700`}
                                                            />
                                                        </div>
                                                    </div>
                                                    <Transition
                                                        show={expandedClass === classIndex}
                                                        ref={dayRef}
                                                        leave="transition ease-in duration-100"
                                                        leaveFrom="opacity-100"
                                                        leaveTo="opacity-0"
                                                    >
                                                        <div className="bg-[#F7F7F7] mt-8 lg:mt-0 p-6 grid lg:grid-cols-2">
                                                            <div className="pb-3 border-b lg:border-r lead--extraLarge border-black-10 lg:border-b-0 text-black-100 text-opacity-70">
                                                                What to Expect
                                                            </div>
                                                            <div className="mt-3 lead--small lg:mt-0 lg:px-6 text-black-100 text-opacity-70">
                                                                {item?.description}
                                                            </div>
                                                        </div>
                                                    </Transition>
                                                </div>
                                            </div>
                                        );
                                    }
                                )}
                            </Transition>
                        </>
                    );
                })}
            </div>

            {/* Connecticut Modal */}
            <Transition
                show={modalState}
                className="fixed top-0 left-0 w-full h-screen z-[9999] bg-gray-dark bg-opacity-60"
                enter="duration-200 ease-out"
                enterFrom="opacity-0"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
            >
                <div className="bg-white text-black-100 md:max-w-2xl max-w-[95vw] h-ato max-h-[95vh] overflow-auto fixed top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 p-10 w-full">
                    <h5 className="mb-4 md:mb-6">Stamford, CT Class Schedule</h5>
                    <a
                        href=""
                        onClick={(e) => {
                            e.preventDefault();
                            setModalState(false);
                        }}
                        className="absolute inline-flex items-center justify-center w-12 h-12 cursor-pointer top-6 right-6 btn--lightGray rounded-30 text-12 font-700 z-[3] transition-all transform  "
                    >
                        <i className="cp-icon-modalClose"/>
                    </a>
                    <div className="mb-4 italic lead opacity-70 md:mb-6">
                        <p>Clicking yes will navigate you to the Stamford, CT class schedule in a new tab / window. Clicking no will leave you on your current tab.</p>
                    </div>
                    <div className="flex space-x-5">
                        <a href="https://www.chelseapiersct.com/fitness/classes/schedule/" className="btn btn--lightGray min-w-[100px]" target="_blank">
                            Yes
                        </a>
                        <a
                            href=""
                            onClick={(e) => {
                                e.preventDefault();
                                setModalState(false);
                            }}
                            className="btn btn--lightGray min-w-[100px]"
                        >
                            No
                        </a>
                    </div>
                </div>
            </Transition>

        </div>
    );
};
