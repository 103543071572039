import React, { useEffect, useRef } from 'react';
import useWindowDimensions from '../../../util/windowDimensions';
import NextNavLink from '../../util/NextNavLink';

export const HeroBanner = (props) => {
    const videoRef = useRef(null);

    const {
        subHeading,
        topHeading,
        left_cta,
        right_cta,
        heading,
        image,
        video,
        className,
        anchor
    } = props;

    const { width, height } = useWindowDimensions();

    useEffect(() => {
        if (!videoRef.current) return;

        let player = videoRef.current;

        player.controls = false;
        player.playsinline = true;
        player.defaultMuted = true;
        player.muted = true;
        player.setAttribute('muted', '');
        player.autoplay = true;
        player.src = video?.video_desktop;

        setTimeout(() => {
            const videoPromise = player.play();
        }, 0);

    }, [video?.video_desktop]);

    return (
        <div
            component="HeroBanner"
            className={`md:h-[80.5vh] min-h-[576px] xl:h-[50vw]  4xl:h-[82vh] relative ${className} hero-banner`}
            id={anchor}
        >
            <>
                <div
                    className="absolute h-full w-full  z-[2] flex justify-center items-center "
                    style={{ background: 'rgba(0,0,0,0.6)' }}
                >
                    <div className="wrapper">
                        <div className="flex flex-col py-[71px] xl:py-[78px] items-center  md:space-y-8 ">
                            <p className="hidden md:block label--largeBold text-white  text-center max-w-[230px] wow fadeInUp">
                                {' '}
                                {topHeading}{' '}
                            </p>
                            <div className="hidden md:block w-[1px] ml-[1px] h-[56px] bg-white"></div>
                            <h1
                                data-wow-delay="0.3s"
                                className="font-500 max-w-[90vw] xl:max-w-full text-center px-6 sm:px-0 ss03 wow fadeInUp"
                            >
                                {' '}
                                {heading}
                            </h1>

                            <div
                                data-wow-delay="0.6s"
                                className="lead--large text-white  text-center max-w-[560px]  px-6 md:px-0 mt-6  md:max-w-full wow fadeInUp"
                                dangerouslySetInnerHTML={{
                                    __html: subHeading
                                }}
                            />

                            <div className="w-[1px] hidden md:block h-[56px] ml-[1px] bg-white"></div>
                            <div
                                data-wow-delay="0.9s"
                                className="flex flex-col items-center lg:max-w-[50vw] xl:max-w-[31.111vw] justify-center w-full px-3 mt-10 space-y-4  sm:flex-row  sm:space-y-0 wow fadeInUp"
                            >
                                {left_cta?.url &&
                                <NextNavLink
                                    href={left_cta?.url}
                                    target={left_cta?.target}
                                >
                                    <a className="label--bold btn btn--cobalt pt-[15px] sm:mr-3 pb-[13px] w-full h-full">
                                        {left_cta?.title}
                                    </a>
                                </NextNavLink>
                                }
                                {right_cta?.url &&
                                <NextNavLink
                                    href={right_cta?.url}
                                    target={right_cta?.target}
                                >
                                    <a className="label--bold btn btn--sky pt-[15px] sm:ml-3 pb-[13px] w-full h-full">
                                        {right_cta?.title}
                                    </a>
                                </NextNavLink>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {video.video_desktop &&
                    <>
                        <video
                            ref={videoRef}
                            className="absolute object-cover w-full h-full"
                            autoPlay={true}
                            muted={true}
                            defaultMuted={true}
                            controls={false}
                            playsInline={true}
                            loop={true}
                            poster={image?.[0]?.medium_url}
                        >
                            <source src={video?.video_desktop} type="video/mp4" />
                        </video>
                    </>
                }
            </>
        </div>
    );
};
