import React, { useState } from 'react';
import NextNavLink from '../../../util/NextNavLink';
import { useRouter } from 'next/router';
import useWindowDimensions from '../../../../util/windowDimensions';

export default function SportCoachCards(props) {
    const coaches = props?.coaches;
    const sport = props?.sport;

    const { width } = useWindowDimensions();
    const router = useRouter()

    const [hover, setHover] = useState('');


    const getImage = (image) => {
        if (image?.sourceUrl) {
            return image;
        } else {
            return {
                sourceUrl: 'https://chelsea-piers-data-api.chelseapiers.com/wp-content/uploads/2023/07/CPF-Trainers-Camera-Shy.jpg',
                altText: 'Coach image',
            };
        }
    };

    const slug = (coach) => {
        let st = (coach?.acf_coaches?.firstName + '-' + coach?.acf_coaches?.lastName).toLowerCase()
        st = st.replace(/[\u00C0-\u00C5]/ig,'a');
        st = st.replace(/[\u00C8-\u00CB]/ig,'e');
        st = st.replace(/[\u00CC-\u00CF]/ig,'i');
        st = st.replace(/[\u00D2-\u00D6]/ig,'o');
        st = st.replace(/[\u00D9-\u00DC]/ig,'u');
        st = st.replace(/[\u00D1]/ig,'n');
        st = st.replace(/[^a-z0-9\- ]+/gi,'');
        st = st.trim().replace(/ /g,'-');
        st = st.replace(/[\-]{2}/g,'');
        return (st.replace(/[^a-z\- ]*/gi,''));
    };

    return (<>
        <div className="flex flex-col self-center px-[36px] lg:px-0 lg:max-w-[38.88vw] wow fadeInUp">
            <h3 className="text-center text-black-100">{sport}</h3>
        </div>
        <div className="grid grid-cols-1 px-3 pt-12 pb-24 xl:px-0 md:grid-cols-2 lg:grid-cols-3 lg:pt-24 lg:pb-32 wow fadeInUp">
        {coaches.map((coach) => { 
            const id = coach?.id;

            let image = getImage(coach?.featuredImage?.node);

            return (<>
                <div
                    key={id}
                    className={`flex flex-col cursor-pointer relative   wow fadeInUp ${hover==id ? 'img-hover-zoom img-hover-zoom--quick-zoom' : '' }  group`}
                    onMouseEnter={() => width>1024 ? setHover(id) : {}}
                    onClick={() => router.push(`/coach/${slug(coach)}`)}
                    onMouseLeave={() => setHover('')}
                    onMouseDown={() => width<1024 ? setHover(hover==id ? '' : id) : {}}
                >
                    {hover!=id && 
                        <h4 className="absolute z-10 px-8 tracking-tighter text-black-100 top-8 ">{coach?.acf_coaches?.firstName}</h4>
                    }

                    <div className={`absolute flex flex-col z-10 ${hover==id ? ' bg-[rgba(0,0,0,0.6)]' : 'bg-black-100 opacity-0'} h-full top-0 left-0 w-full transition-all `}>
                        <div className="flex flex-col h-full p-8">
                            <h4 className="tracking-tighter text-white ">{` ${coach?.acf_coaches?.firstName} ${coach?.acf_coaches?.lastName} `}</h4>

                            <div className="flex items-center mt-2">
                                <div className="flex items-center lead--regular">
                                    <p className="">{coach?.acf_coaches?.locale}</p>
                                </div>
                            </div>

                            <div className="flex items-end justify-between h-full">
                                <div className="flex-col hidden lg:flex">

                                    {coach?.acf_coaches?.sports?.map((sport, index) => {
                                        return (<>
                                            {sport?.title && 
                                                <div className="flex flex-col" key={'sport-${index}'}>
                                                    <p className="label--smallAlt">{sport?.title}</p>
                                                    <div className="h-[1px] my-2 w-4 bg-white30"></div>
                                                </div>
                                            }
                                        </>);
                                    })}
                                </div>
                                <NextNavLink href={`/coach/${slug(coach)}`}>
                                        <a className="label--bold btn btn--sky  pt-[15px] pb-[13px]  flex items-center ">
                                            <span>Meet {coach?.acf_coaches?.firstName}</span>
                                            <i className="cp-icon-arraySmallLeft text-black-100 mb-[2px] text-9 leading-16 ml-3" style={{transform: 'rotate(180deg)'}}></i>
                                        </a>
                                    </NextNavLink>
                            </div>

                        </div>
                    </div>
                    <div className="relative !visible wow overflow-hidden">
                        <div className="rev-img-wrap relative z-[3]">
                            <div className="rev-img">
                                <img
                                    className="w-full transition-all origin-center bg-center"
                                    src={image?.sourceUrl}
                                    srcSet={image?.srcSet}
                                    alt={image?.altText}
                                />
                            </div>
                        </div>
                        <div className="absolute top-0 left-0 w-full h-full rev-bg "></div>{' '}
                    </div>
                </div>

            </>);
        })}
        </div>
    </>);
}