import  { useState, useEffect, useContext } from 'react';
import { LocationHighlight } from '../../components/chelsea/Locations/LocationHighlight';
import { LocationContext } from '../../util/LocationProvider';

export const ACFLocationHighlight = ({
    data,
    meta,
    anchor = 'fef',
    className = ''
}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const [currentDay, setCurrentDay] = useState();

    const extractDay = () => {
        let date = new Date();
        let year = date.getFullYear();
        let finalDay = {};
        let month = (1 + date.getMonth()).toString();
        month = month.length > 1 ? month : '0' + month;

        let days = [
            'sunday',
            'monday',
            'tuesday',
            'wednesday',
            'thursday',
            'friday',
            'saturday'
        ];
        let d = new Date();
        let dayName = days[d.getDay()];

        let day = date.getDate().toString();
        day = day.length > 1 ? day : '0' + day;

        let output = day + '/' + month + '/' + year;

        meta?.schedules?.[0]?.['special days']?.map((item, index) => {
            if (output === item?.date_trigger) {
                finalDay = { ...item };
            } else {
                finalDay = { ...meta?.schedules?.[0]?.[dayName] };
            }
        });
        setCurrentDay(finalDay);
    };

    useEffect(() => {
        extractDay();
    }, []);

    return (<>
        {show &&
            <LocationHighlight
                currentDay={currentDay}
                cta={data?.link}
                content={data?.content}
                title={data?.title}
                anchor={anchor}
                className={className} />
        }
    </>);
};
