import  { useContext, useState, useEffect } from 'react';
import FooterCTA from '../../components/chelsea/CTA/FooterCTA'
import { extractRepeaterField } from '../../util/blocks'
import { LocationContext } from '../../util/LocationProvider'

export const ACFFooterCTA = ({data,meta,pageProps, anchor='',className=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const ctas = extractRepeaterField(data,'ctas');

    return (<>
        {show &&
            <FooterCTA 
                className={className}
                anchor={anchor}
                heading={data?.use_defaults === "1"? pageProps?.defaults?.footerCtaBlock?.acf_footer_cta?.headerText:  data?.header_text}
                ctas={data?.use_defaults === "1"? pageProps?.defaults?.footerCtaBlock?.acf_footer_cta?.ctas: ctas}
                image={data?.use_defaults === "1"? pageProps?.defaults?.footerCtaBlock?.acf_footer_cta?.desktopImage?.sourceUrl: Object.values(meta?.images)}
            />
        }
    </>);
}
