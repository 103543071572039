import React, { useContext, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import InfoCards from '../ClassCards/InfoCards';

import { LocationContext } from '../../../util/LocationProvider';

const BirthdayPartiesSearch = (props) => {
    let LocationManager = useContext(LocationContext);
    let locale = LocationManager.getLocation();

    const router = useRouter();

    let parties = props?.parties;

    const [hiddenAge, setHiddenAge] = useState(true);
    const [filterAge, setFilterAge] = useState([]);
    const [optionsAge, setOptionsAge] = useState([]);

    const [filteredCards, setFilteredCards] = useState([]);

    useEffect(() => { 
        filterCards(); 
    }, [filterAge]);

    const filterCards = () => {
        let cards = [];
        let ages = [];
        let show = false;

        for (let i=0; i<parties?.length; i++) {
            let party = parties[i]?.node?.acf_birthday_parties;

            // Filter out cards not associated with the current set locale.
            show = false;
            party?.locale?.forEach((cardLocale) => {
                if (locale == cardLocale) {
                    show = true;
                }
            });
            if (!show) continue;

            // Collect ages in dataset to set default filter options before filters are applied.
            for (let j=parseInt(party?.minAge); j<=parseInt(party?.maxAge); j++) {
                if (!ages.includes(j)) {
                    ages.push(j);
                }
            }

            if (filterAge?.length) { // If ages filter is active, match age against filters.
                show = false;
                for (let j=parseInt(party?.minAge); j<=parseInt(party?.maxAge); j++) {
                    if (filterAge.includes(j)) {
                        show = true;
                    }
                }
                if (!show) continue;
            }

            cards.push(party);
        }

        setFilteredCards(cards);
        setOptionsAge(ages.sort((a,b) => {
            if (a<b) return -1;
            if (a>b) return  1;
            return 0;
        }));
    };
    
    const csvArray = (str) => {
        if (str.includes(',')) {
            return str.split(',');
        } else {
            return [str];
        }
    }

    useEffect(() => { 
        // Age is set, and the value exists as a filter option.
        if (router?.query?.age) {
            let ages = csvArray(router?.query?.age);
            let agesFilters = [...filterAge];
            ages.forEach((age) => { 
                if (age != '18+') {
                    age = parseInt(age);
                }
                // Is this a valid option? Not already set?
                if (optionsAge.includes(age) && !filterAge.includes(age)) { 
                    agesFilters.push(age); 
                }
            });
            setFilterAge(agesFilters);
        }
    }, [router.query, csvArray]);

    const toggleAge = (age, e) => {
        let ages = [...filterAge];
        if (!ages.includes(age)) {
            ages.push(age);
            e.currentTarget.classList.add('active');
        } else {
            ages = ages.filter(a => a !== age);
            e.currentTarget.classList.remove('active');
        }
        setFilterAge(ages);
    };

    const isFilterActive = (filter, value) => {
        let filterCheck = false;

        if (Array.isArray(filter)) {
            filterCheck = (filter.includes(value) ? 'active' : '');
        } else {
            filterCheck = (filter==value ? 'active': '');
        }
        return filterCheck;
    };

    return (<>
        <div component="ClassesDate" className="xl:px-[3.333%] wow fadeInUp bg-white  to-animate" id="" animated="true" >
            <div className="flex-col hidden pt-16 lg:flex wow fadeInUp  to-animate"  animated="true">
                <div className="flex flex-col justify-center items-center lg:px-[7%]   xl:px-[16.666%] space-y-4 lg:flex-row lg:space-y-0">

                    {/* AGE Dropdown */}
                    <div className="flex flex-col lg:w-full xl:px-[1.3393%] px-3 relative max-w-[20%] w-full m-auto">
                        <div className="flex items-center  btn btn--lightGray pt-[15px]  justify-between " onClick={() => setHiddenAge(false)}>
                            <span className="transition-all duration-100 label--bold ">Age</span><i className="  cp-icon-arraySmallLeft text-9 w-[9px] h-[9px] flex items-center justify-center  leading-16  -rotate-90 transform  font-700"></i>
                        </div>
                        {!hiddenAge ? 
                            <div 
                                onMouseLeave={() => setHiddenAge(true)}
                                className="flex flex-col mt-2 w-[324px] absolute z-[4] transform bg-gray  left-1/2 -translate-x-1/2 px-6"
                            >
                                <i onClick={() => setHiddenAge(true)} className=" cursor-pointer cp-icon-modalClose text-12 mt-5 self-end leading-16"></i>
                                <div className="flex flex-col space-y-[18px] mt-[9px] mb-[23px] cursor-pointer">

                                    {optionsAge.map((age) => {
                                        return (
                                            <div 
                                                key={age}
                                                onClick={(e) => toggleAge(age, e)}
                                                className={'schedule-check flex items-center space-x-3 ' + isFilterActive(filterAge, age)}
                                            >
                                                <div className="check w-4 mb-[2px] h-4  border-2 border-white "> </div>
                                                <p className="label--bold">{age}</p>
                                            </div>
                                        );
                                    })}

                                </div>
                            </div>
                        : null}
                    </div>
                </div>
            </div>

            <InfoCards cards={filteredCards} />
        </div>
    </>);
}
export default BirthdayPartiesSearch;