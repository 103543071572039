import  { useContext, useState, useEffect } from 'react';
import FeaturingPost from '../../components/chelsea/FeaturingPost';
import { LocationContext } from '../../util/LocationProvider';

function ACFFeaturingPost({ data, meta, className = '', anchor = '' }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <FeaturingPost
                className={className}
                anchor={anchor}
                image={meta?.posts?.[0]?.image?.src}
                subHeading={data?.[`sub-title`]}
                content={data?.content}
                cta={data?.cta}
                heading={data?.title}
                postTitle={meta?.posts?.[0]?.name}
            />
        }
    </>);
}

export default ACFFeaturingPost;
