import React from 'react';
import NextNavLink from '../../util/NextNavLink';

const CenterPageCTA = ({ cta, className, anchor,color }) => {

    const getBackgroundColor = () => {
        if (color === 'light_blue') {
            return 'btn--sky';
        } else if (color === 'dark_blue') {
            return 'btn--cobalt';
        } else {
            return 'btn--black';
        }
    };

    const getTextColor = () => {
        if (color === 'light_blue') {
            return 'text-black-100';
        } else if (color === 'dark_blue') {
            return 'text-white';
        } else {
            return 'text-white';
        }
    };
    
    return (
        <div
            className={`wrapper bg-white flex items-center pb-[128px] justify-center ${className}`}
        >
            <NextNavLink href={cta?.url} target={cta?.target}>
                <div className={`label--bold btn ${getBackgroundColor()} flex items-center space-x-3 justify-center lg:min-w-[200px]  pt-[15px] pb-[13px]`} >
                    <a className={getTextColor()}> {cta?.title} </a>
                    <i
                        className={`mb-[2px]  cp-icon-arrowBigLeft text-12 leading-16  transform rotate-180 font-700`}
                    ></i>
                </div>
            </NextNavLink>
        </div>
    );
};

export default CenterPageCTA;
