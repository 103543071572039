import React, { useState } from 'react';
import SwiperCore, { Pagination, Navigation, Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { LineCTA } from '../CTA/LineCTA';

SwiperCore.use([Pagination, Navigation, Autoplay]);

const PostCarousel = ({ items, top_heading, anchor, className }) => {
    const [hover, setHover] = useState();

    return (
        <div
            className={` overflow-hidden bg-cover bg-white ${className}`}
            id={anchor}
        >
            <div className="pt-[64px] lg:pt-[128px] pb-[65.5px] lg:pb-[129.5px]">
                <div className="items-end mb-6 lg:mb-8 md:flex">
                    <h2 className="flex-1 text-center wow fadeInUp text-black-100">
                        {top_heading}
                    </h2>
                </div>
                <div className="block w-[1px] mx-auto mb-8 lg:mb-12  h-[64px] bg-cobalt-100"></div>
                <div className="relative swiper-auto-width wrapperCarousal">
                    <div className="full-screen-right">
                        <Swiper
                            spaceBetween={48}
                            slidesPerView="auto"
                            navigation={{
                                prevEl: '.insight-prev',
                                nextEl: '.insight-next'
                            }}
                            pagination={false}
                        >
                            {items &&
                                items?.map((item, i) => {
                                    return (
                                        <SwiperSlide key={i}>
                                            <div
                                                className="relative flex group mainCardWrapper flex-col w-[230px] md:w-[288px] text-black-100"
                                                // onMouseEnter={() => setHover(i)}
                                                // onMouseLeave={() => setHover()}
                                            >
                                                <div
                                                    className={` aspect-w-1	aspect-h-1  max-w-[288px]  transition-all  duration-300`}
                                                    style={{
                                                        backgroundImage:
                                                            hover === i
                                                                ? `url(${item?.post?.image?.src})`
                                                                : `url(${item?.post?.image?.src}), linear-gradient(0deg, rgba(0, 0, 0, 0.4) , rgba(0, 0, 0, 0.4) )`,
                                                        backgroundBlendMode:
                                                            'multiply',
                                                        backgroundSize: 'cover'
                                                    }}
                                                >
                                                    <div className="flex items-center justify-center h-full transition-all duration-300 ">
                                                        <img
                                                            className=""
                                                            alt={item?.desktop_icon?.alt}
                                                            src={item?.desktop_icon?.src}
                                                        />
                                                    </div>
                                                </div>
                                                <h4 
                                                    className="text-black-100 z-[1] mt-6 transition-all duration-300 wow fadeInUp"
                                                    dangerouslySetInnerHTML={{__html: ` ${item?.post?.name} `}}
                                                >
                                                </h4>
                                                <div
                                                    className="pt-4 lead--regular lg:pt-5 wow fadeInUp"
                                                    dangerouslySetInnerHTML={{__html: item?.description}} 
                                                />
                                                <LineCTA
                                                    title={item?.cta?.title}
                                                    href={item?.cta?.url}
                                                    target={item?.cta?.target}
                                                    marginClass={'mt-[22.5px]'}
                                                    borderClass={
                                                        'border--increase--cobalt'
                                                    }
                                                    textClassName={
                                                        'text-black-100 group-hover:text-cobalt-100'
                                                    }
                                                />
                                            </div>
                                        </SwiperSlide>
                                    );
                                })}
                        </Swiper>
                    </div>

                    <div
                        aria-label="previous button"
                        role="button"
                        className="cursor-pointer absolute left-0 h-[64px] top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden lg:flex  items-center justify-center insight-prev"
                        style={{ borderRadius: '50%' }}
                    >
                        <i className=" cp-icon-arraySmallLeft text-black-100 text-15 "></i>
                    </div>

                    <div 
                        aria-label="next button"
                        role="button"
                        className="cursor-pointer absolute right-0 h-[64px]  top-[116px] w-[64px] mx-[1.6665%] z-[22] bg-buttonGray  hidden lg:flex  items-center justify-center insight-next"
                        style={{ borderRadius: '50%' }}
                    >
                        <i className=" transform rotate-180 cp-icon-arraySmallLeft text-black-100 text-15"></i>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PostCarousel;
