import  { useContext, useState, useEffect } from 'react';
import Schedule from '../../components/chelsea/Schedule/Schedule'
import { LocationContext } from '../../util/LocationProvider'

const ACFSchedule = ({data,meta,className='',anchor=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <Schedule 
                regularHours={data?.hide_regular_hours === "0"} 
                openButtonText={data?.open_accordion_text} 
                title={data?.title} 
                schedules={meta?.schedules} 
                closeButtonText={data?.close_accordion_text_copy} 
                className={className} 
                anchor={anchor} />
        }
    </>);
}

export default ACFSchedule
