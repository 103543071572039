import  { useContext, useState, useEffect } from 'react';
import { Events } from '../../components/chelsea/List/Events';
import { LocationContext } from '../../util/LocationProvider';

export const ACFEventsListing = ({ meta, data, className, anchor }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    return (<>
        {show &&
            <Events
                title={data?.title}
                showPagination={data?.hide_pagination === '0'}
                showFilters={data?.hide_filters === '0'}
                pagination={parseInt(data?.post_per_pagination)}
                className={className}
                anchor={anchor}
                categories={meta?.eventCategories}
                locations={meta?.eventLocations}
                events={meta?.events}
            />
        }
    </>);
};
