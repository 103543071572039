import  { useContext, useState, useEffect } from 'react';
import TwoColGrid from '../../components/chelsea/CTA/TwoColGrid';
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

const ACFTwoColGrid = ({ data, meta, className, anchor }) => { 
let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    //const [filtered, setFiltered] = useState(data);

    const [grids, setGrids] = useState(() => {
        let grids = [];

        let _grids = extractRepeaterField(data, 'grid');

        grids = _grids.map(function(grid) {
            if (grid?.use_post_content == '1') { // Use related post?
                let post = {};
                meta?.posts?.forEach((_post) => {
                    if (_post?.id == grid?.related_post) {
                        post = _post;
                    }
                });
                if (post?.image?.src) { // If the post has an image.
                    return { // Pull image off related post.
                        ...grid,
                        media_column:    'image',
                        desktop_image:   post?.image?.src || {},
                        mobile_image:    post?.image?.medium_url || {},
                        posterImage:     post?.image?.src || {},
                        mobileVideo:     false,
                        desktopVideo:    false,
                        desktopVideoUrl: false,
                        mobileVideoUrl:  false
                    }
                }
            }
            // Use image from block fields.
            return {
                ...grid,
                desktop_image:   meta?.images[grid.desktop_image] || {},
                mobile_image:    meta?.images[grid.mobile_image] || {},
                posterImage:     meta?.images[grid.poster_image] || {},
                mobileVideo:     meta?.video?.[grid?.mobile_video],
                desktopVideo:    meta?.video?.[grid?.desktop_video],
                desktopVideoUrl: grid?.desktop_video_url,
                mobileVideoUrl:  grid?.mobile_video_url
            };
        });
        return grids;
    });

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [
        locale, 
        LocationManager, 
        data,
    ]);
    
    return (<>
        {show &&
            <TwoColGrid
                className={className}
                anchor={anchor}
                grids={grids}
                style={data?.style_options_block_style}
                flip={data?.style_options_flip_col === '1'}
                posts={meta?.posts}
            />
        }
    </>);
};

export default ACFTwoColGrid;
