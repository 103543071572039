import  { useContext, useState, useEffect } from 'react';
import { Separator } from '../../components/chelsea/Separator';
import { LocationContext } from '../../util/LocationProvider';

function ACFSeparator({ data, className, anchor }) {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    const { size, color } = data;

    return (<>
        {show &&
            <Separator
                className={className}
                anchor={anchor}
                size={size}
                color={color}
            />
        }
    </>);
}

export default ACFSeparator;
