import  { useContext, useState, useEffect } from 'react';
import { Devices } from '../../components/chelsea/Misc/Devices'
import { extractRepeaterField } from '../../util/blocks';
import { LocationContext } from '../../util/LocationProvider';

const ACFDevices = ({data,meta, className='',anchor=''}) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [locale, setShow, LocationManager, data]);

    let grids = extractRepeaterField(data, 'devices' );

    grids = grids.map((repeaterItem) => ({
        ...repeaterItem,
        image: meta?.images[repeaterItem?.device] || {},
    }));

    return (<>
        {show &&
            <Devices 
                className={className} 
                anchor={anchor} 
                devices={grids} />  
        }
    </>);
}

export default ACFDevices
