import  { useContext, useState, useEffect } from 'react';
import OfferNoticeBar from '../../components/chelsea/CTA/OfferNoticeBar';
import { LocationContext } from '../../util/LocationProvider';

const ACFOfferNoticeBar = ({ data, meta, pageProps, className='', anchor='' }) => {
    let LocationManager = useContext(LocationContext);
    const [show, setShow] = useState(false);
    const [locale, setLocale] = useState(LocationManager?.getLocation());

    useEffect(() => {
        setShow(!LocationManager?.hideBlock(data));
    }, [
        locale, 
        setShow, 
        LocationManager,
        LocationManager.sportsLocation,
        LocationManager.eventsLocation,
        data
    ]);

    return (<>
        {show && 
            <OfferNoticeBar 
                data={data} 
                meta={meta} 
                offers={pageProps?.componentQueries?.offers}
            />
        }
    </>);
}
export default ACFOfferNoticeBar;